import { authService, setupAuth, Tokens } from '@waitroom/auth';
import { logger } from '@waitroom/logger';
import { STORAGE_AUTH_TOKENS } from '@waitroom/models';
import { parseJson } from '@waitroom/utils';
import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const StorageSync = (): ReactElement | null => {
  const dispatch = useDispatch();

  useEffect(() => {
    const syncInternal = (ev: StorageEvent) => {
      if (ev.key === STORAGE_AUTH_TOKENS) {
        if (ev.newValue) {
          logger.logWith(1, 'StorageSync AUTH_TOKENS', ev);
          const parsed = parseJson<Tokens>(ev.newValue);
          const tkn = parsed?.accessToken;
          const isValid = !!tkn && authService.isTokenValid(tkn);
          if (isValid) dispatch(setupAuth(parsed));
        }
        return;
      }
    };

    window.addEventListener('storage', syncInternal);
    return () => {
      window.removeEventListener('storage', syncInternal);
    };
  }, [dispatch]);

  return null;
};
export default StorageSync;
