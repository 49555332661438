import { fetchEventSource as fEV } from '@microsoft/fetch-event-source';
import { FetchEventSource } from '@waitroom/common-api';

export const fetchEventSource: FetchEventSource = async (input, opts) => {
  const onMessage = opts.onmessage;
  return new Promise(async (resolve, reject) => {
    await fEV(input, {
      openWhenHidden: true,
      ...opts,
      onmessage: onMessage
        ? (ev) => {
            try {
              onMessage(ev);
            } catch (err) {
              reject(err);
            }
          }
        : undefined,
      onerror: (er) => {
        opts.onerror?.(er);
        reject(er);
        return 1;
      },
      onclose: () => {
        opts.onclose?.();
        resolve();
      },
    });
  });
};
