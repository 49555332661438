import { changeUser, disableSDK, enableSDK, initialize } from '@braze/web-sdk';
import { AuthState } from '@waitroom/auth';
import { envs } from '../envs';

const domain = envs.VITE_BRAZE_DOMAIN;
const key = envs.VITE_BRAZE_API_KEY;
const enabled = !!domain && !!key;

export const brazeService = {
  enabled,
  trackUser: (userId: string): void => {
    if (!enabled || !userId) return;
    changeUser(userId);
  },
  disable: (): void => disableSDK(),
  init: (): void => {
    if (!enabled) return;
    enableSDK();
    initialize(key, {
      baseUrl: domain,
    });
  },
};

export async function connectUserToBraze(authState: AuthState) {
  const { accessToken, userId } = authState;
  if (!brazeService.enabled || !accessToken || !userId) return;
  brazeService.init();
  brazeService.trackUser(userId);
}
