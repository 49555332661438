import { Icon, IconProps } from '@chakra-ui/react';
import { keysOf } from '@waitroom/utils';
import * as React from 'react';
import { ResponsiveBreakpoints } from '../../../../config/theme/breakpoints';
import { logo, logoIconAnimated } from './icons';

export type Width<T = number> = T | ResponsiveBreakpoints<T>;

type CalcSizeResponse = { w: Width<string>; h: Width<string> };
const calcSize = (
  width: Width,
  baseWidth: number,
  baseHeight: number,
): CalcSizeResponse => {
  if (typeof width === 'number') {
    return { w: `${width}px`, h: `${baseHeight / (baseWidth / width)}px` };
  }
  return keysOf(width).reduce<{
    w: ResponsiveBreakpoints<string>;
    h: ResponsiveBreakpoints<string>;
  }>(
    (ac, key) => {
      ac.w[key] = `${width[key]}px`;
      ac.h[key] = `${baseHeight / (baseWidth / (width[key] || 0))}px`;
      return ac;
    },
    { w: {}, h: {} },
  );
};

export interface LogoIconProps extends IconProps {
  size?: Width;
  color?: string;
  animate?: boolean;
}
export const LogoIcon = ({
  size = 64,
  color,
  animate,
  ...rest
}: LogoIconProps): React.ReactElement | null => (
  <Icon
    viewBox={animate ? '0 0 385 385' : '0 0 250 250'}
    {...calcSize(size, 250, 250)}
    {...rest}
  >
    {animate ? logoIconAnimated() : logo()}
  </Icon>
);

export interface LogoProps extends IconProps {
  size?: Width;
  color?: string;
  textColor?: string;
  withText?: boolean;
}

export const Logo = ({
  size = 150,
  color,
  textColor,
  withText = true,
  ...rest
}: LogoProps): React.ReactElement | null => (
  <Icon viewBox="0 0 844 238" {...calcSize(size, 844, 238)} {...rest}>
    {logo(withText, textColor)}
  </Icon>
);
