const n = {
  enabled: !0,
  config: {
    /** Lower value = higher level */
    logLevel: 5,
    /** Filter logs by service */
    services: void 0
  },
  log: function(...o) {
    this.enabled && this.config.logLevel === 5 && console.log(...o);
  },
  logWith: function(o, ...e) {
    this.enabled && this.config.logLevel >= (o ?? 5) && console.log(...e);
  },
  logType: function(o, ...e) {
    this.enabled && console[o](...e);
  },
  service: function(o, e, ...l) {
    const i = this.config.services;
    (!i || i.includes(o)) && this.logWith(e, `[${o}]:`, ...l);
  }
};
export {
  n as logger
};
