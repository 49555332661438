import Fingerprint2 from 'fingerprintjs2';
import { storageService } from '../services/storage';

export const FINGERPRINT_KEY = 'fingerprint';

export const getFingerPrint = ({ options = {} } = {}): Promise<string> =>
  new Promise<string>((resolve) => {
    const stored = storageService.get(FINGERPRINT_KEY);
    if (stored) {
      return resolve(stored);
    }
    Fingerprint2.get(options, (components) => {
      const values = components.map((component) => component.value);
      const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
      storageService.set(FINGERPRINT_KEY, fingerprint);
      resolve(fingerprint);
    });
  });
