const o = (f, s, c) => {
  const e = f;
  e.use = {};
  for (const t of Object.keys(e.getState()))
    e.use[t] = () => e((k) => k[t]);
  if (s)
    for (const t of Object.keys(s))
      e.use[t] = () => e(s[t]);
  if (c)
    for (const t of Object.keys(c))
      e[t] = c[t];
  return e;
};
export {
  o as createSelectors
};
