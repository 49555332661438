import { EventType } from '@waitroom/analytics';
import { Metric } from 'web-vitals';
import { analyticsService } from './modules/analytics/services';

type ReportHandler = (report: Metric) => void;
export const sendToAnalytics: ReportHandler = ({ id, name, value, delta }) => {
  analyticsService.track(
    EventType.WebVitals,
    {
      action: name,
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      label: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate
      delta,
    },
    {
      'Mixpanel-Dev': false,
      'Mixpanel-Prod': false,
    },
  );
};

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ onCLS, onINP, onLCP, onFCP, onTTFB }) => {
      onCLS(onPerfEntry);
      onINP(onPerfEntry);
      onLCP(onPerfEntry);
      onFCP(onPerfEntry);
      onTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
