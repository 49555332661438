import f from "dayjs";
import { VideoFileExtension as S, SocialKeys as $ } from "@waitroom/models";
const R = (t) => t != null, g = (t) => typeof t == "function", F = (t, ...n) => n.some((e) => typeof t === e), j = (t, n) => n in t, x = (t, n) => t instanceof n, N = (...t) => (n) => t.reduceRight((e, r) => r(e), n), Y = (...t) => (n) => t.reduce((e, r) => r(e), n), L = (t) => (...n) => Promise.resolve(t(...n)), b = (t) => (n) => {
  try {
    return t();
  } catch (e) {
    return n ? n(e) : e;
  }
}, v = (t) => async (n) => {
  try {
    return await t();
  } catch (e) {
    return n ? n(e) : e;
  }
}, U = async (t, n) => {
  var o;
  const e = {
    delay: 300,
    retries: 2,
    ...n
  };
  let r;
  for (let s = 1; s <= e.retries; s++)
    try {
      return await t();
    } catch (i) {
      if (r = i, !(((o = e.filterError) == null ? void 0 : o.call(e, i)) ?? !0)) throw i;
      s < e.retries && await new Promise(
        (c) => setTimeout(c, (e.delay ?? 300) * s)
      );
    }
  throw r;
}, P = (t, n) => {
  let e = Date.now();
  const r = async (o = 1) => {
    var c, d, h;
    const s = {
      initialDelay: 300,
      maxDelay: 3e3,
      retries: 3,
      resetTime: 1e4,
      // default to 10 seconds to reset backoff
      ...n
    }, i = Date.now();
    i - e > s.resetTime && (o = 1), e = i;
    try {
      return await t();
    } catch (u) {
      if ((c = s.onError) == null || c.call(s, u), ((d = s.filterError) == null ? void 0 : d.call(s, u)) === !1 || s.retries !== -1 && o >= s.retries) throw u;
      return (h = s.onRetry) == null || h.call(s, o), new Promise(
        (w) => setTimeout(
          () => w(r(o + 1)),
          Math.min(s.initialDelay * 2 ** (o - 1), s.maxDelay)
        )
      );
    }
  };
  return r();
}, C = (t) => (n) => (...e) => t(e) ? n(e) : void 0, _ = () => typeof window < "u", q = (t, n) => {
  const e = {};
  if (!t) return e;
  for (let r = 0; r < t.length; r++) {
    const o = t[r];
    n ? n(e, o) : e[String(o)] = o;
  }
  return e;
}, z = (t, n) => t ? (Array.isArray(n) ? n : [n]).every((r) => t.includes(r)) : !1, H = (t, n) => t ? (Array.isArray(n) ? n : [n]).some((r) => t.includes(r)) : !1, I = (t, n, e = ", ") => {
  if (!t) return "";
  const r = g(n) ? n : (o) => String(o[n] ?? "");
  return t.map(r).filter(Boolean).join(e);
}, J = (t, n, e) => {
  if (!t) return "";
  const r = g(n) ? n : (c) => String(c[n] ?? ""), o = t.map(r).filter(Boolean);
  if (o.length <= 1) return o.join(", ");
  const s = [...o], i = s.pop();
  return `${s.join(", ")} ${e} ${i}`;
};
function V(t, n) {
  return [
    ...t.filter((e) => n.indexOf(e) === -1),
    ...n.filter((e) => t.indexOf(e) === -1)
  ];
}
function Z(t) {
  const n = Math.floor(Math.random() * t.length);
  return t[n];
}
function W(t, n) {
  const e = {}, r = [];
  for (const o of t) {
    const s = o[n];
    e[s] || (e[s] = !0, r.push(o));
  }
  return r;
}
const a = [
  "B",
  "KB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB"
], l = 1024, G = (t, n = 2) => {
  if (t === 0) return "0 B";
  const e = n < 0 ? 0 : n, r = Math.floor(Math.log(t) / Math.log(l));
  return `${parseFloat((t / l ** r).toFixed(e))} ${a[r]}`;
}, Q = (t, n = "B", e = "MB", r = 2) => {
  if (!t || n === e) return t;
  const o = r < 0 ? 0 : r, s = a.indexOf(e), i = a.indexOf(n);
  if (s < 0 || i < 0) return t;
  const c = s - i;
  return parseFloat((t / l ** c).toFixed(o));
}, m = /:00/, k = 864e5, K = 36e5, X = 6e4, tt = (t, n, {
  separator: e = " — ",
  date: r = "dddd, MMM D, YYYY",
  time: o = "h:mm A"
} = {}) => {
  const s = f(t).format(`${r}${e}${o}`).replace(m, ""), i = f(n).format(o).replace(m, "");
  return `${s} - ${i}`;
}, p = (t) => {
  const e = ~~((t < 0 ? 0 : t) / 1e3), r = ~~(e / 60), o = ~~(r / 60);
  return [e % 60, r % 60, o % 24, ~~(o / 24)];
}, y = ["s", "m", "h", "d"], M = (t, n = y) => {
  let e = "";
  for (let r = t.length - 1; r >= 0; r--) {
    const o = t[r], s = n[r];
    o > 0 && s && (e += `${o}${n[r]} `);
  }
  return e.trimEnd();
}, nt = (t, n = y) => {
  const e = p(t);
  return M(e, n);
}, et = (t) => typeof t == "number" ? t * 1e3 : t, rt = (t, n) => {
  const e = f(t), r = [`FREQ=${n}`];
  return n === "weekly" ? r.push(`BYDAY=${e.format("dd")}`) : n === "monthly" ? r.push(`BYMONTHDAY=${e.format("D")}`) : n === "yearly" && r.push(`BYMONTH=${e.format("M")};BYMONTHDAY=${e.format("D")}`), r.join(";").toUpperCase();
}, ot = (t, n = ":") => {
  const [e, r, o, s] = p(t), i = [];
  let c = o;
  return s > 0 && (c += s * 24), c > 0 ? (i.push(String(c)), i.push(String(r).padStart(2, "0"))) : i.push(String(r)), i.push(String(e).padStart(2, "0")), i.join(n);
}, st = (t, n, e, r) => {
  n.forEach((o) => {
    t.addEventListener(o, e, r);
  });
}, it = (t, n, e, r) => {
  n.forEach((o) => {
    t.removeEventListener(o, e, r);
  });
}, ct = (t = "") => new DOMParser().parseFromString(t, "text/html").documentElement.textContent || t, ut = (t, n, e, r = {}) => t.reduce((o, s) => {
  const i = e[s] ?? n[s];
  return o[s] = s in r || i === void 0 ? i : String(i), o;
}, {});
function ft(t) {
  try {
    return JSON.parse(t), !0;
  } catch {
    return !1;
  }
}
function at(t, n) {
  if (!t) return n;
  try {
    return JSON.parse(t);
  } catch {
    return n;
  }
}
const E = typeof navigator < "u" && "locks" in navigator, lt = (t, n, e = {}) => E ? (...r) => navigator.locks.request(t, e, async () => await n(...r)) : n, dt = (t = 0, n = Number.MAX_SAFE_INTEGER) => Math.floor(Math.random() * (n - t + 1)) + t, ht = (t, n, e = "+ ") => t > n ? `${e}${n}` : `${t}`, mt = (t) => t < 1e3 ? `${t}` : t < 1e6 ? `${Number((t / 1e3).toFixed(1))}k` : `${Number((t / 1e6).toFixed(1))}m`, A = (t) => t != null && (typeof t == "string" || typeof t == "number"), gt = (t) => t !== null && typeof t == "object" && !Array.isArray(t), B = (t) => Object.keys(t);
function pt(t, ...n) {
  return n.length ? n.reduce((r, o) => {
    const { [o]: s, ...i } = r;
    return i;
  }, t) : t;
}
function yt(t, n, e) {
  if (!t) return e;
  const o = (typeof n == "string" ? n.split(".") : n).reduce(
    (s, i) => s && s[i] !== void 0 ? s[i] : void 0,
    t
  );
  return o !== void 0 ? o : e;
}
function O(t, n, e) {
  return B({ ...t, ...n }).reduce((r, o) => ((e ? e(t[o]) === e(n[o]) : t[o] === n[o]) || (r[o] = n[o]), r), {});
}
const wt = (t, n) => Object.entries(O(t, n)).length === 0, St = (t, n) => {
  if (!t) return;
  const e = {};
  for (let r = 0; r < t.length; r++) {
    const o = t[r], s = typeof n == "function" ? n(o) : o[n], i = A(s) ? s : String(s);
    e[i] = (e[i] || []).concat(o);
  }
  return e;
}, $t = (t, n = S.HLS) => {
  for (let e = t.length - 1; e > -1; e -= 1)
    if (t[e].endsWith(n))
      return t[e];
  return t[0];
}, Mt = async (t) => {
  var r;
  const n = (r = t == null ? void 0 : t.getReader) == null ? void 0 : r.call(t);
  if (!n) return;
  const e = await n.read();
  return new TextDecoder().decode(e.value);
}, Et = (t, n, e = "...") => !t || t.length <= n ? t : `${t.substring(0, n)}${e}`, At = (t) => !!t && t.trim() !== "", Bt = (t) => !!t && String(t).toLowerCase() !== "false" && t !== "0", Ot = ({
  text: t,
  /** Lenght of the step for reducing the size */
  lengthStep: n,
  /** Size reduction for each step  */
  sizeStep: e = 0.1,
  /** Minimum size */
  min: r = 0.5
}) => {
  const o = (t || "").length, s = 1 - Math.floor(o / n) * e;
  return s < r ? r : s;
}, Dt = `  
`, Tt = (t, n = "-") => t.join(n), Rt = (t = "") => t === "" ? "" : ` ${t}`, Ft = ({
  params: t,
  relative: n = !1
} = {}) => {
  const e = new URL(window.location.href);
  if (t)
    for (const r of Object.keys(t))
      e.searchParams.set(r, String(t[r]));
  return n ? `${e.pathname}${e.search}` : e.href;
}, jt = ({
  relative: t = !1,
  queryString: n = !0
} = {}) => {
  const e = new URL(window.location.href);
  return n || (e.search = ""), t ? `${e.pathname}${e.search}` : e.href;
}, xt = (t) => t ? /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
  t
) : !1, Nt = (t) => !t || t.indexOf("://") === -1 && t.indexOf("//") !== 0, Yt = (t, n) => {
  const e = new URL(t);
  for (const r of Object.keys(n))
    e.searchParams.set(r, String(n[r]));
  return e.href;
};
function Lt(t) {
  return t && t.replace(/(wss?:\/\/|wss?:)/g, "").replace("/socket", "").replace("/websocket", "");
}
const bt = (t) => t ? t == null ? void 0 : t.reduce((n, e) => (e.platform === "email" || (n[e.platform] = e.url), n), {}) : void 0, vt = (t) => t ? $.reduce((n, e) => {
  const r = t[e];
  return !r || e === "email" || n.push({
    platform: e,
    url: r
  }), n;
}, []) : void 0;
export {
  k as MS_DAY,
  K as MS_HOUR,
  X as MS_MINUTE,
  Yt as addQueryParams,
  wt as areDeepEqual,
  q as arrayToMap,
  st as bindEvents,
  Ft as buildCurrentUrl,
  Tt as buildKey,
  Ot as calcResponsiveSize,
  N as compose,
  z as containsAll,
  H as containsAny,
  Q as convertBytes,
  ct as decodeHtmlEntities,
  O as diff,
  V as difference,
  y as durationSuffixDefaults,
  nt as durationToFormat,
  bt as flattenSocials,
  G as formatBytes,
  tt as fromTo,
  yt as get,
  jt as getCurrentUrl,
  Lt as getDomainKeyFromUrl,
  ot as getFormattedLength,
  Z as getRandomItem,
  dt as getRandomNumber,
  $t as getRecordingURL,
  St as groupBy,
  C as ifThen,
  R as isDefined,
  g as isFunction,
  x as isInstanceOf,
  ft as isJsonString,
  At as isNotEmpty,
  gt as isObject,
  Nt as isRelativeUrl,
  j as isType,
  F as isTypeof,
  A as isValidKey,
  xt as isValidUrl,
  I as joinOn,
  J as joinWithAnd,
  B as keysOf,
  ut as mergeValues,
  l as multiplier,
  E as navigatorLockSupported,
  Dt as newline,
  pt as omit,
  at as parseJson,
  p as parseMilliseconds,
  Mt as parseStream,
  M as parsedDurationToFormat,
  Y as pipe,
  L as promisify,
  U as retry,
  P as retryWithBackoff,
  mt as shortenNumber,
  a as sizes,
  Bt as toBoolean,
  et as toEpoch,
  ht as toLimit,
  rt as toRRule,
  Et as truncate,
  b as tryCatch,
  v as tryCatchAsync,
  it as unbindEvents,
  vt as unflattenSocials,
  W as uniqBy,
  _ as windowDefined,
  lt as withLock,
  Rt as withSpace
};
