var N = Object.defineProperty;
var B = (e, t, n) => t in e ? N(e, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[t] = n;
var S = (e, t, n) => B(e, typeof t != "symbol" ? t + "" : t, n);
import { errorService as J } from "@waitroom/error-service";
import { LOGGER_SERVICE_BRAID as f } from "@waitroom/models";
import { parseJson as T, retryWithBackoff as L, parseStream as H } from "@waitroom/utils";
let s;
const se = (e) => {
  s = e.logger;
}, F = (e) => e ? `?${Object.keys(e).map((t) => `${t}=${encodeURIComponent(e[t])}`).join("&")}` : "";
class G {
  constructor() {
    S(this, "handlerRef", { id: -1 });
  }
  get handler() {
    return this.handlerRef.id;
  }
  set handler(t) {
    this.handlerRef.id = t;
  }
  clear() {
    clearTimeout(this.handlerRef.id);
  }
}
function K(e, t, n = new G()) {
  let i = !1;
  const r = () => {
    n.handler = setTimeout(() => {
      e(() => {
        i = !0, n.clear();
      }), i || r();
    }, t);
  };
  return r(), n;
}
var q = window.fetch, z = window.AbortController, I = window.Headers;
async function X(e, t = {}) {
  if (t = { ...t }, t.headers ? t.headers = new I(t.headers) : t.headers = new I(), t.version && console.assert(
    Array.isArray(t.version),
    "fetch(): `version` must be an array"
  ), t.parents && console.assert(
    Array.isArray(t.parents),
    "fetch(): `parents` must be an array"
  ), t.version && t.headers.set(
    "version",
    t.version.map(JSON.stringify).join(", ")
  ), t.parents && t.headers.set(
    "parents",
    t.parents.map(JSON.stringify).join(", ")
  ), t.subscribe && t.headers.set("subscribe", "true"), t.peer && t.headers.set("peer", t.peer), t.headers.set("X-Keep-Alive", "true"), t.cache = "no-cache", t.patches)
    if (console.assert(!t.body, "Cannot send both patches and body"), console.assert(
      typeof t.patches == "object",
      "Patches must be object or array"
    ), Array.isArray(t.patches) || (t.patches = [t.patches]), t.patches.length === 1) {
      let c = t.patches[0];
      t.headers.set("Content-Range", `${c.unit} ${c.range}`), t.headers.set(
        "Content-Length",
        `${new TextEncoder().encode(c.content).length}`
      ), t.body = c.content;
    } else
      t.headers.set("Patches", t.patches.length), t.body = t.patches.map((c) => {
        var b = `content-length: ${new TextEncoder().encode(c.content).length}`, y = `content-range: ${c.unit} ${c.range}`;
        return `${b}\r
${y}\r
\r
${c.content}\r
`;
      }).join(`\r
`);
  var n = t.signal, i = new z();
  t.signal = i.signal, n && n.addEventListener("abort", () => i.abort());
  var r = await q(e, t);
  r.subscribe = d, r.subscription = { [Symbol.asyncIterator]: w };
  const a = r.headers.get("X-Keep-Alive");
  function d(c, b) {
    if (!r.ok) throw new Error("Request returned not ok status:", r.status);
    if (r.bodyUsed)
      throw new Error("This response's body has already been read", r);
    Q(
      r.body,
      // Each time something happens, we'll either get a new
      // version back, or an error.
      (y, p) => {
        if (!p)
          c(y);
        else {
          const _ = i.signal.aborted;
          if (i.abort(), b) b(p, _);
          else throw "Unhandled network error in subscription";
        }
      },
      a
    );
  }
  function w() {
    var c = !1, b = [], y = null, p = null;
    return {
      async next() {
        if (b.length > 0) return { done: !1, value: b.shift() };
        var _ = new Promise((o, E) => {
          y = o, p = E;
        });
        c || (c = !0, d(
          (o) => y(o),
          (o) => p(o)
        ));
        var x = await _;
        return y = (o) => b.push(o), p = (o) => {
          throw o;
        }, { done: !1, value: x };
      }
    };
  }
  return r;
}
async function Q(e, t, n) {
  const i = e.getReader(), r = V(t);
  let a;
  if (n) {
    const d = (parseInt(n) + 15) * 1e3;
    a = K(() => r.checkTimeout(d), 1e4), r.keepAlive = !0;
  }
  for (; ; ) {
    let d, w;
    try {
      const c = await i.read();
      d = c.done, w = c.value;
    } catch (c) {
      a && a.clear(), r.timedOut || t(null, c);
      return;
    }
    if (d) {
      console.debug("Connection closed."), a && a.clear(), r.timedOut || t(null, "Connection closed");
      return;
    }
    r.read(w);
  }
}
const V = (e) => ({
  // A parser keeps some parse state
  state: { input: [] },
  // And reports back new versions as soon as they are ready
  cb: e,
  // Timestamp of last input
  last: Date.now(),
  timedOut: !1,
  keepAlive: !1,
  // You give it new input information as soon as you get it, and it will
  // report back with new versions as soon as it finds them.
  read(t) {
    const n = t;
    if (this.last = Date.now(), !(this.keepAlive && n.length > 12 && n[0] === 88 && n[1] == 45 && n[2] === 75 && n[3] === 101 && n[4] === 101 && n[5] === 112 && n[6] === 45 && n[7] === 65 && n[8] === 108 && n[9] === 105 && n[10] === 118 && n[11] === 101))
      for (this.state.input.push(...t); this.state.input.length; ) {
        try {
          this.state = M(this.state);
        } catch (i) {
          this.cb(null, i);
          return;
        }
        if (this.state.result === "success")
          this.cb({
            version: this.state.version,
            parents: this.state.parents,
            body: this.state.body,
            patches: this.state.patches,
            // Output extra_headers if there are some
            extra_headers: j(this.state.headers)
          }), this.state = { input: this.state.input };
        else if (this.state.result === "error") {
          this.cb(null, this.state.message);
          return;
        }
        if (this.state.result == "waiting") break;
      }
  },
  checkTimeout(t) {
    const n = Date.now() - this.last;
    s == null || s.service(f, 5, `Interval diff: ${n}`), n > t && (s == null || s.service(f, 3, "Connection timed out"), this.timedOut || e(null, "Connection timed out"), this.timedOut = !0);
  }
});
function M(e) {
  if (!e.headers) {
    const t = U(e.input);
    if (t.result === "error") return t;
    if (t.result === "waiting")
      return e.result = "waiting", e;
    e.headers = t.headers, e.version = e.headers.version, e.parents = e.headers.parents, e.input = t.input;
  }
  return W(e);
}
function U(e) {
  const t = Y(e);
  if (!t) return { result: "waiting" };
  const n = t.header_string, i = n.length, r = {}, a = /(:?[\w-_]+):\s?(.*)\r?\n?/gy;
  let d, w = !1;
  for (; d = a.exec(n); )
    r[d[1].toLowerCase()] = d[2], a.lastIndex === i && (w = !0);
  return w ? ("version" in r && (r.version = JSON.parse("[" + r.version + "]")), "parents" in r && (r.parents = JSON.parse("[" + r.parents + "]")), "patches" in r && (r.patches = JSON.parse(r.patches)), e = t.remaining_bytes, { result: "success", headers: r, input: e }) : {
    result: "error",
    message: 'Parse error in headers: "' + JSON.stringify(n.substr(a.lastIndex)) + '"',
    headers_so_far: r,
    last_index: a.lastIndex,
    headers_length: i
  };
}
function D(e) {
  const t = e.match(/(\S+)( (.*))?/);
  return t && { unit: t[1], range: t[3] || "" };
}
function W(e) {
  const t = parseInt(e.headers["content-length"]);
  if (isNaN(t)) {
    if (e.headers.patches != null) {
      e.patches = e.patches || [];
      let n = e.patches[e.patches.length - 1];
      for (; !(e.patches.length === e.headers.patches && (e.patches.length === 0 || "content" in n)); ) {
        if ((!n || "content" in n) && (n = {}, e.patches.push(n)), !("headers" in n)) {
          const i = U(e.input);
          if (i.result === "error") return i;
          if (i.result === "waiting")
            return e.result = "waiting", e;
          n.headers = i.headers, e.input = i.input;
        }
        {
          if (!("content-length" in n.headers))
            return {
              result: "error",
              message: "no content-length in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          if (!("content-range" in n.headers))
            return {
              result: "error",
              message: "no content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          const i = parseInt(n.headers["content-length"]);
          if (e.input.length < i)
            return e.result = "waiting", e;
          const r = D(n.headers["content-range"]);
          if (!r)
            return {
              result: "error",
              message: "cannot parse content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          n.unit = r.unit, n.range = r.range, n.content = new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, i))
          ), n.extra_headers = j(n.headers), delete n.headers, e.input = e.input.slice(i);
        }
      }
      return e.result = "success", e;
    }
  } else {
    if (t > e.input.length)
      return e.result = "waiting", e;
    if (e.result = "success", e.headers["content-range"]) {
      const n = D(e.headers["content-range"]);
      if (!n)
        return {
          result: "error",
          message: "cannot parse content-range",
          range: e.headers["content-range"]
        };
      e.patches = [
        {
          unit: n.unit,
          range: n.range,
          content: new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, t))
          )
          // Question: Perhaps we should include headers here, like we do for
          // the Patches: N headers below?
          // headers: state.headers
        }
      ];
    } else
      e.body = new TextDecoder("utf-8").decode(
        new Uint8Array(e.input.slice(0, t))
      );
    return e.input = e.input.slice(t), e;
  }
  return {
    result: "error",
    message: "cannot parse body without content-length or patches header"
  };
}
function j(e) {
  const t = Object.assign({}, e), n = [
    "version",
    "parents",
    "patches",
    "content-length",
    "content-range"
  ];
  for (let i = 0; i < n.length; i++)
    delete t[n[i]];
  if (Object.keys(t).length !== 0)
    return t;
}
function Y(e) {
  let t = 0;
  for (; e[t] === 13 || e[t] === 10; )
    t++;
  if (t === e.length)
    return null;
  let n = t, i = 0;
  for (; n < e.length; ) {
    if (e[n] === 10 && e[n + 1] === 10) {
      i = 2;
      break;
    }
    if (e[n] === 10 && e[n + 1] === 13 && e[n + 2] === 10) {
      i = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 10) {
      i = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 13 && e[n + 3] === 10) {
      i = 4;
      break;
    }
    n++;
  }
  if (n === e.length)
    return null;
  const r = e.slice(t, n), a = new TextDecoder("utf-8").decode(
    new Uint8Array(r)
  );
  return {
    remaining_bytes: e.slice(n + i),
    header_string: a
  };
}
const Z = X, ie = {
  _connections: {},
  config: { interceptors: {} },
  close: function(e) {
    const t = this._connections[e];
    t && (s == null || s.service(f, 3, "Closing connection", e), t.abort.abort(`Close called for ${e}`), delete this._connections[e]);
  },
  fetch: async function(e) {
    var k;
    const { onError: t } = this.config.interceptors, {
      key: n,
      method: i = "GET",
      shouldReconnect: r = !0,
      subscribe: a = !0,
      headers: d = {},
      onFetched: w,
      onPatch: c,
      onError: b,
      params: y,
      signal: p,
      skipRetryErrorCodes: _ = [401, 404],
      ...x
    } = e, o = n || x.url || "";
    if (s == null || s.service(f, 5, "Fetch", o), this._connections[o]) {
      const h = `Connection already exists ${o}`;
      if (s == null || s.service(f, 3, h), !r) throw new Error(h);
      this.close(o);
    }
    const E = F(y), P = `${x.baseURL}${x.url}${E}`, A = new AbortController();
    if (p) {
      if (p.aborted) return;
      p.addEventListener("abort", () => {
        s == null || s.service(
          f,
          3,
          "Abort connection",
          o
        ), A.abort(`Abort called for ${o}`), delete this._connections[o];
      });
    }
    try {
      return await new Promise(
        async (h, m) => {
          await L(() => new Promise(async (u, $) => {
            s == null || s.service(
              f,
              5,
              "Opening connection",
              o
            );
            const R = Z(P, {
              method: i,
              headers: d,
              subscribe: a,
              signal: A.signal,
              ...x
            });
            this._connections[o] = {
              abort: A,
              connection: R
            };
            let v;
            try {
              v = await R;
            } catch (l) {
              return $(l);
            }
            if (v.status > 399)
              return m({
                // @ts-expect-error types are wrong
                code: v.code || v.status,
                data: v == null ? void 0 : v.data,
                success: !1,
                error: v
              });
            v.subscribe(
              (l) => {
                if (l.body) {
                  const g = T(
                    l.body
                  );
                  return s == null || s.service(
                    f,
                    5,
                    "Body",
                    o,
                    l.body
                  ), w && w(g), g ? h(g) : m(l);
                }
                if (l.patches && c) {
                  s == null || s.service(
                    f,
                    5,
                    "Patch",
                    o,
                    l.patches
                  );
                  const g = l.patches.map(
                    (C) => ({
                      op: C.unit,
                      path: C.range,
                      value: C.content ? T(C.content) : void 0
                    })
                  );
                  c(g);
                }
              },
              (l, g) => {
                if (s == null || s.service(f, 1, "Braid error", {
                  error: l,
                  wasAborted: g
                }), g) return h(void 0);
                $(l);
              }
            );
          }), {
            // infinite retries
            retries: -1,
            // skip retry
            filterError: _ ? (u) => typeof u != "object" || !(_.includes(u == null ? void 0 : u.status) || _.includes(u == null ? void 0 : u.code)) : void 0,
            onRetry: (u) => {
              s == null || s.service(f, 5, "Retry", o, {
                attempt: u
              });
            }
          });
        }
      );
    } catch (h) {
      if (typeof h == "object" && h) {
        const O = (k = h.error) == null ? void 0 : k.body;
        if (O) {
          const u = await H(O);
          if (u) {
            const $ = T(u);
            h.error = $;
          }
        }
      }
      if (s == null || s.service(f, 1, "Error", o, h), J.report(h), b && await b(e, h), A.abort(`Error abort ${o}`), !t) throw h;
      return t(e, h);
    }
  }
};
export {
  ie as braidService,
  F as buildQueryString,
  se as initConfig,
  s as logger,
  K as setIntervalWithTimeout
};
