/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentType,
  lazy,
  PropsWithoutRef,
  ReactElement,
  ReactNode,
  Suspense,
} from 'react';
import { unregister } from '../services/serviceWorker';

interface Opts<T> {
  importFn: T;
  fallback: ReactNode;
}

const componentLoader = <
  T extends () => Promise<any>,
  U extends PropsWithoutRef<any>,
>(
  lazyComponent: T,
): Promise<{ default: ComponentType<U> }> =>
  new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        unregister();
        reject(error);
        /* setTimeout(() => {
          window.location.href = '/';
        }, 250); */
      });
  });

export const lazyLoad = <
  T extends () => Promise<any>,
  U extends PropsWithoutRef<any>,
>({
  importFn,
  fallback,
}: Opts<T>) => {
  const LazyComponent = lazy(() => componentLoader<T, U>(importFn));
  return (props: U): ReactElement | null => (
    <Suspense fallback={fallback || null}>
      <LazyComponent {...(props as any)} />
    </Suspense>
  );
};
