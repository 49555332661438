// This optional code is used to register a service worker.
import { envs } from '../envs';
import refreshNotification from './refresh';

type SwCallback = (registration: ServiceWorkerRegistration) => void;

export const onLoadError = (err: Error) => {
  if (!err.message.startsWith('Failed to fetch dynamically imported module')) {
    return;
  }
  refreshNotification.show(() => {
    deleteCache();
    unregister();
    setTimeout(() => {
      window.location.reload();
    }, 80);
  });
};

const onSuccess: SwCallback | undefined = undefined;
const onUpdate: SwCallback | undefined = (registration) => {
  refreshNotification.show(() => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    } else {
      deleteCache();
      setTimeout(() => {
        window.location.reload();
      }, 80);
    }
  });
};

const swEnabled = 'serviceWorker' in navigator;
const hostname = window.location.hostname;
const isLocalhost = Boolean(
  hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export function register(): void {
  if (envs.NODE_ENV === 'production' && swEnabled) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(envs.PUBLIC_URL || '', window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    let refreshing = false;
    // The event listener that is fired when the service worker updates
    navigator.serviceWorker.addEventListener('controllerchange', function () {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });

    window.addEventListener('load', () => {
      const swUrl = `/service-worker.js`;
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl);
        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            `This web app is being served cache-first by a service worker.`,
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl);
      }
    });
  }
}

function registerValidSW(swUrl: string): void {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) return;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                `New content is available and will be used when all tabs for this page are closed.`,
              );

              // Execute callback
              if (onUpdate) {
                console.log('Service worker updating');
                onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (onSuccess) onSuccess(registration);
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string): void {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.',
      );
    });
}

export function unregister(): void {
  if (!swEnabled) return;
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister();
    })
    .catch((error) => {
      console.error(error.message);
    });
}

// should be only used when errors occur and a force clear is needed
export async function deleteCache(
  shouldDelete?: (name: string) => boolean,
): Promise<void> {
  if (!swEnabled) return Promise.resolve();
  const cacheNames = await caches.keys();
  for (const cacheName of cacheNames) {
    if (shouldDelete && !shouldDelete(cacheName)) return;
    caches.delete(cacheName);
  }
}
