class S extends Error {
  constructor(e, o = "", n) {
    const c = o + (n ? ` |> ${n.message}` : "");
    super(c), this.code = e, this.message = o, this.error = n, this.message = c, this.name = this.constructor.name, typeof Error.captureStackTrace == "function" ? Error.captureStackTrace(this, this.constructor) : (this.cause = n, this.stack = new Error(o).stack);
  }
}
var O = /* @__PURE__ */ ((t) => (t.GOOGLE = "google", t.FACEBOOK = "facebook", t.TWITTER = "twitter", t.APPLE = "apple", t.REGISTER = "register", t.LOGIN = "login", t))(O || {}), i = /* @__PURE__ */ ((t) => (t.Section = "section", t.Actions = "actions", t.Divider = "divider", t.Input = "input", t.Header = "header", t))(i || {}), _ = /* @__PURE__ */ ((t) => (t.Button = "button", t.Checkboxes = "checkboxes", t.EmailTextInput = "email_text_input", t.NumberInput = "number_input", t.PlainTextInput = "plain_text_input", t.RadioButtons = "radio_buttons", t.StaticSelect = "static_select", t.ExternalSelect = "external_select", t.MultiStaticSelect = "multi_static_select", t.MultiExternalSelect = "multi_external_select", t))(_ || {});
const M = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  BlockType: i,
  ElementType: _
}, Symbol.toStringTag, { value: "Module" }));
var r = /* @__PURE__ */ ((t) => (t.FACEBOOK = "facebook", t.INSTAGRAM = "instagram", t.LINKEDIN = "linkedin", t.REDDIT = "reddit", t.RESTREAM = "restream", t.YOUTUBE = "youtube", t.TIKTOK = "tiktok", t.TWITTER = "twitter", t.TWITCH = "twitch", t.WHATSAPP = "whatsapp", t))(r || {});
const E = {
  twitter: {
    name: "X",
    color: "#000",
    secondaryColor: "#FFF"
  },
  facebook: {
    name: "Facebook",
    color: "#1877F2",
    secondaryColor: "#FFF"
  },
  instagram: {
    name: "Instagram",
    color: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
    secondaryColor: "#FFF"
  },
  linkedin: {
    name: "LinkedIn",
    color: "#0A66C2",
    secondaryColor: "#FFF"
  },
  youtube: {
    name: "YouTube",
    color: "#FF0000",
    secondaryColor: "#FFF"
  },
  tiktok: {
    name: "TikTok",
    color: "#FFFFFF",
    secondaryColor: "#000"
  },
  reddit: {
    name: "Reddit",
    color: "#FF5700",
    secondaryColor: "#FFF"
  },
  restream: {
    name: "Restream",
    color: "#000000",
    secondaryColor: "#FFF"
  },
  twitch: {
    name: "Twitch",
    color: "#6441A4",
    secondaryColor: "#FFF"
  },
  whatsapp: {
    name: "WhatsApp",
    color: "linear-gradient(36deg, rgba(45,182,64,1) 0%, rgba(88,207,99,1) 100%)",
    secondaryColor: "#FFF"
  }
}, b = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null
}, Symbol.toStringTag, { value: "Module" }));
var s = /* @__PURE__ */ ((t) => (t.ParticipantOnly = "participant-only", t.TeamVisible = "team-visible", t))(s || {});
const p = 1001, P = 1002, f = 1010, Y = 1011, K = 1012, H = 2e3, W = 2034, k = 2076, x = 2088, V = 2084, y = 2086, j = 2133, B = 2134, $ = 2139, z = 2107;
var R = /* @__PURE__ */ ((t) => (t.NotStarted = "Not Started", t.InProgress = "In Progress", t.Completed = "Completed", t.WaitingOnSomeoneElse = "Waiting on someone else", t.Deferred = "Deferred", t))(R || {}), I = /* @__PURE__ */ ((t) => (t.Call = "Call", t.Meeting = "Meeting", t.Other = "Other", t.Email = "Email", t))(I || {}), A = /* @__PURE__ */ ((t) => (t.Lead = "lead", t.Account = "account", t.Opportunity = "opportunity", t))(A || {}), N = /* @__PURE__ */ ((t) => (t.Contact = "contact", t.Company = "company", t.Deal = "deal", t))(N || {}), l = /* @__PURE__ */ ((t) => (t.DealToCompany = "deal_to_company", t))(l || {});
const Z = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null
}, Symbol.toStringTag, { value: "Module" }));
var T = /* @__PURE__ */ ((t) => (t.one = "one", t.group = "group", t))(T || {}), D = /* @__PURE__ */ ((t) => (t[t.portrait = 0] = "portrait", t[t.landscape = 1] = "landscape", t))(D || {}), C = /* @__PURE__ */ ((t) => (t.HLS = ".m3u8", t.MPD = ".mpd", t))(C || {}), d = /* @__PURE__ */ ((t) => (t.DISABLED = "DISABLED", t.PENDING = "PENDING", t.READY = "READY", t))(d || {}), m = /* @__PURE__ */ ((t) => (t[t.NONE = 0] = "NONE", t[t.DAILY = 1] = "DAILY", t[t.WEEKLY = 2] = "WEEKLY", t[t.MONTHLY = 3] = "MONTHLY", t[t.YEARLY = 4] = "YEARLY", t))(m || {});
class a {
  constructor(e, o, n) {
    this.success = e, this.error = o, this.value = n, Object.freeze(this);
  }
  getValue() {
    if (!this.success)
      throw new Error("Cant retrieve the value from a failed result.");
    return this.value;
  }
  getError() {
    if (this.success)
      throw new Error("Cant retrieve the error from a successful result.");
    return this.error;
  }
  static ok(e) {
    return new a(!0, void 0, e);
  }
  static fail(e, o = "") {
    return new a(!1, new S(e, o));
  }
  static error(e) {
    return new a(!1, e);
  }
}
var u = /* @__PURE__ */ ((t) => (t.ADMIN = "admin", t.GUEST = "guest", t.GUEST_USER = "guest_user", t.HOST = "host", t.PRODUCER = "producer", t.SESSION_OWNER = "session_owner", t.VIEWER = "viewer", t))(u || {});
const X = "Authentication", Q = "Braid", q = "Meeting Memory", J = "Chat";
var L = /* @__PURE__ */ ((t) => (t.PartyMode = "party-mode", t.ListeningMode = "listening-mode", t))(L || {});
const tt = [
  "everyone",
  "owner",
  "owner_same_domain"
];
var F = /* @__PURE__ */ ((t) => (t.CANCELLED = "cancelled", t.SCHEDULED = "scheduled", t.IN_SESSION = "active", t.ENDED = "ended", t.ARCHIVED = "archived", t))(F || {}), h = /* @__PURE__ */ ((t) => (t.IN_REVIEW = "inReview", t.GRANTED = "granted", t.IGNORED = "ignored", t.DENIED = "denied", t))(h || {}), v = /* @__PURE__ */ ((t) => (t.Google = "google", t))(v || {});
const G = {
  [r.TWITTER]: E.twitter,
  [r.LINKEDIN]: E.linkedin,
  [r.INSTAGRAM]: E.instagram,
  [r.FACEBOOK]: E.facebook,
  [r.YOUTUBE]: E.youtube,
  [r.TIKTOK]: E.tiktok,
  [r.REDDIT]: E.reddit,
  email: { name: "email", color: "#DDDDDD", secondaryColor: "#1E2431" },
  website: { name: "website", color: "#DDDDDD", secondaryColor: "#1E2431" }
}, rt = Object.keys(G), et = {
  [r.FACEBOOK]: ({ url: t = "" } = {}) => `//www.facebook.com/sharer.php?u=${encodeURIComponent(t)}`,
  [r.LINKEDIN]: ({ url: t = "" } = {}) => `//www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(t)}`,
  [r.WHATSAPP]: ({ text: t = "" } = {}) => `//wa.me/?text=${encodeURIComponent(t)}`,
  [r.TWITTER]: ({ text: t = "" } = {}) => `//twitter.com/intent/tweet?text=${encodeURIComponent(
    t
  )}&hashtags=waitroom`
};
var w = /* @__PURE__ */ ((t) => (t[t.NONE = 0] = "NONE", t[t.DISABLED = 1] = "DISABLED", t[t.ENABLED = 2] = "ENABLED", t))(w || {});
const ot = "AUTH_TOKENS", Et = "BROWSER_SUPPORT", nt = "DEVICE_SETTINGS", at = "DEVICES", ct = "g", it = "LAST_USED_INTEGRATION", _t = "NOTIFICATIONS", St = "PLAYER", Ot = "STREAM_SETTINGS", st = "TEST_CAM_MIC", Rt = "USER_SETTINGS";
var U = /* @__PURE__ */ ((t) => (t.Owner = "owner", t.Member = "member", t))(U || {}), g = /* @__PURE__ */ ((t) => (t[t.MALE = 0] = "MALE", t[t.FEMALE = 1] = "FEMALE", t[t.OTHER = 2] = "OTHER", t))(g || {});
export {
  S as AppError,
  O as AuthType,
  M as BlockKit,
  r as Brand,
  E as Brands,
  b as CMS,
  s as DataVisibility,
  f as ERROR_AVATAR_UPLOAD_ERROR,
  P as ERROR_GENERAL,
  V as ERROR_SESSION_ACCESS_ALREADY_APPROVED,
  x as ERROR_SESSION_ACCESS_ALREADY_REQUESTED,
  Y as ERROR_SESSION_ACCESS_DENIED,
  y as ERROR_SESSION_ACCESS_DOESNT_EXIST,
  $ as ERROR_SESSION_ACCESS_INACTIVE,
  k as ERROR_SESSION_ACCESS_UNAUTHORIZED,
  B as ERROR_SESSION_ACCESS_UNAUTHORIZED_LOCKED,
  j as ERROR_SESSION_ACCESS_UNAUTHORIZED_UNLOCKED,
  K as ERROR_SESSION_IS_FULL,
  W as ERROR_SESSION_NOT_FOUND,
  H as ERROR_SESSION_UNAUTHENTICATED,
  z as ERROR_TRIAL_ALREADY_ACTIVE,
  p as ERROR_UNKNOWN,
  g as Gender,
  l as HubspotAssociationType,
  N as HubspotRecordType,
  q as LOGGER_MEETING_MEMORY,
  X as LOGGER_SERVICE_AUTH,
  Q as LOGGER_SERVICE_BRAID,
  J as LOGGER_SERVICE_CHAT,
  Z as MeetingMemory,
  L as MeetingType,
  T as MessageBroadcastType,
  D as Orientation,
  d as RecordingStatus,
  m as Recurring,
  a as Result,
  u as Role,
  ot as STORAGE_AUTH_TOKENS,
  Et as STORAGE_BROWSER_SUPPORT,
  at as STORAGE_DEVICES,
  nt as STORAGE_DEVICE_SETTINGS,
  ct as STORAGE_GENERAL,
  it as STORAGE_LAST_USED_INTEGRATION,
  _t as STORAGE_NOTIFICATIONS,
  St as STORAGE_PLAYER,
  Ot as STORAGE_STREAM_SETTINGS,
  st as STORAGE_TEST_CAM_MIC,
  Rt as STORAGE_USER_SETTINGS,
  A as SalesforceRecordType,
  R as SalesforceTaskStatus,
  I as SalesforceTaskType,
  h as SessionAccessRuleStatus,
  v as SessionCalendarType,
  F as SessionStatus,
  et as Share,
  rt as SocialKeys,
  G as Socials,
  w as Status,
  U as TeamMemberRole,
  C as VideoFileExtension,
  tt as summaryEmailRecipients
};
