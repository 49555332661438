import { SessionAccessRuleStatus as fe } from "@waitroom/models";
import { buildSuccessRequestResponse as j, aiApiService as Oe, integrationsApiService as _e, meetingMemoryApiService as re, sessionAccessApiService as oe, sessionApiService as z, userApiService as Me, authApiService as Ye } from "@waitroom/common-api";
import { errorService as he } from "@waitroom/error-service";
import { applyPatch as Fe, deepClone as L } from "fast-json-patch";
import { useInfiniteQuery as Ne, useQuery as He, useQueryClient as Ke, QueryObserver as Ce } from "@tanstack/react-query";
import { useEffect as k, useRef as be, useState as pe } from "react";
var Pe = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, we = { exports: {} };
(function(e, t) {
  (function(n, s) {
    e.exports = s();
  })(Pe, function() {
    var n = 1e3, s = 6e4, r = 36e5, o = "millisecond", i = "second", E = "minute", y = "hour", m = "day", C = "week", A = "month", K = "quarter", Y = "year", F = "date", le = "Invalid Date", ve = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, Te = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, $e = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(d) {
      var c = ["th", "st", "nd", "rd"], u = d % 100;
      return "[" + d + (c[(u - 20) % 10] || c[u] || c[0]) + "]";
    } }, ue = function(d, c, u) {
      var f = String(d);
      return !f || f.length >= c ? d : "" + Array(c + 1 - f.length).join(u) + d;
    }, Ie = { s: ue, z: function(d) {
      var c = -d.utcOffset(), u = Math.abs(c), f = Math.floor(u / 60), a = u % 60;
      return (c <= 0 ? "+" : "-") + ue(f, 2, "0") + ":" + ue(a, 2, "0");
    }, m: function d(c, u) {
      if (c.date() < u.date()) return -d(u, c);
      var f = 12 * (u.year() - c.year()) + (u.month() - c.month()), a = c.clone().add(f, A), _ = u - a < 0, S = c.clone().add(f + (_ ? -1 : 1), A);
      return +(-(f + (u - a) / (_ ? a - S : S - a)) || 0);
    }, a: function(d) {
      return d < 0 ? Math.ceil(d) || 0 : Math.floor(d);
    }, p: function(d) {
      return { M: A, y: Y, w: C, d: m, D: F, h: y, m: E, s: i, ms: o, Q: K }[d] || String(d || "").toLowerCase().replace(/s$/, "");
    }, u: function(d) {
      return d === void 0;
    } }, U = "en", b = {};
    b[U] = $e;
    var me = "$isDayjsObject", ae = function(d) {
      return d instanceof X || !(!d || !d[me]);
    }, Z = function d(c, u, f) {
      var a;
      if (!c) return U;
      if (typeof c == "string") {
        var _ = c.toLowerCase();
        b[_] && (a = _), u && (b[_] = u, a = _);
        var S = c.split("-");
        if (!a && S.length > 1) return d(S[0]);
      } else {
        var p = c.name;
        b[p] = c, a = p;
      }
      return !f && a && (U = a), a || !f && U;
    }, M = function(d, c) {
      if (ae(d)) return d.clone();
      var u = typeof c == "object" ? c : {};
      return u.date = d, u.args = arguments, new X(u);
    }, g = Ie;
    g.l = Z, g.i = ae, g.w = function(d, c) {
      return M(d, { locale: c.$L, utc: c.$u, x: c.$x, $offset: c.$offset });
    };
    var X = function() {
      function d(u) {
        this.$L = Z(u.locale, null, !0), this.parse(u), this.$x = this.$x || u.x || {}, this[me] = !0;
      }
      var c = d.prototype;
      return c.parse = function(u) {
        this.$d = function(f) {
          var a = f.date, _ = f.utc;
          if (a === null) return /* @__PURE__ */ new Date(NaN);
          if (g.u(a)) return /* @__PURE__ */ new Date();
          if (a instanceof Date) return new Date(a);
          if (typeof a == "string" && !/Z$/i.test(a)) {
            var S = a.match(ve);
            if (S) {
              var p = S[2] - 1 || 0, O = (S[7] || "0").substring(0, 3);
              return _ ? new Date(Date.UTC(S[1], p, S[3] || 1, S[4] || 0, S[5] || 0, S[6] || 0, O)) : new Date(S[1], p, S[3] || 1, S[4] || 0, S[5] || 0, S[6] || 0, O);
            }
          }
          return new Date(a);
        }(u), this.init();
      }, c.init = function() {
        var u = this.$d;
        this.$y = u.getFullYear(), this.$M = u.getMonth(), this.$D = u.getDate(), this.$W = u.getDay(), this.$H = u.getHours(), this.$m = u.getMinutes(), this.$s = u.getSeconds(), this.$ms = u.getMilliseconds();
      }, c.$utils = function() {
        return g;
      }, c.isValid = function() {
        return this.$d.toString() !== le;
      }, c.isSame = function(u, f) {
        var a = M(u);
        return this.startOf(f) <= a && a <= this.endOf(f);
      }, c.isAfter = function(u, f) {
        return M(u) < this.startOf(f);
      }, c.isBefore = function(u, f) {
        return this.endOf(f) < M(u);
      }, c.$g = function(u, f, a) {
        return g.u(u) ? this[f] : this.set(a, u);
      }, c.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, c.valueOf = function() {
        return this.$d.getTime();
      }, c.startOf = function(u, f) {
        var a = this, _ = !!g.u(f) || f, S = g.p(u), p = function(w, I) {
          var H = g.w(a.$u ? Date.UTC(a.$y, I, w) : new Date(a.$y, I, w), a);
          return _ ? H : H.endOf(m);
        }, O = function(w, I) {
          return g.w(a.toDate()[w].apply(a.toDate("s"), (_ ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(I)), a);
        }, R = this.$W, D = this.$M, h = this.$D, G = "set" + (this.$u ? "UTC" : "");
        switch (S) {
          case Y:
            return _ ? p(1, 0) : p(31, 11);
          case A:
            return _ ? p(1, D) : p(0, D + 1);
          case C:
            var P = this.$locale().weekStart || 0, x = (R < P ? R + 7 : R) - P;
            return p(_ ? h - x : h + (6 - x), D);
          case m:
          case F:
            return O(G + "Hours", 0);
          case y:
            return O(G + "Minutes", 1);
          case E:
            return O(G + "Seconds", 2);
          case i:
            return O(G + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, c.endOf = function(u) {
        return this.startOf(u, !1);
      }, c.$set = function(u, f) {
        var a, _ = g.p(u), S = "set" + (this.$u ? "UTC" : ""), p = (a = {}, a[m] = S + "Date", a[F] = S + "Date", a[A] = S + "Month", a[Y] = S + "FullYear", a[y] = S + "Hours", a[E] = S + "Minutes", a[i] = S + "Seconds", a[o] = S + "Milliseconds", a)[_], O = _ === m ? this.$D + (f - this.$W) : f;
        if (_ === A || _ === Y) {
          var R = this.clone().set(F, 1);
          R.$d[p](O), R.init(), this.$d = R.set(F, Math.min(this.$D, R.daysInMonth())).$d;
        } else p && this.$d[p](O);
        return this.init(), this;
      }, c.set = function(u, f) {
        return this.clone().$set(u, f);
      }, c.get = function(u) {
        return this[g.p(u)]();
      }, c.add = function(u, f) {
        var a, _ = this;
        u = Number(u);
        var S = g.p(f), p = function(D) {
          var h = M(_);
          return g.w(h.date(h.date() + Math.round(D * u)), _);
        };
        if (S === A) return this.set(A, this.$M + u);
        if (S === Y) return this.set(Y, this.$y + u);
        if (S === m) return p(1);
        if (S === C) return p(7);
        var O = (a = {}, a[E] = s, a[y] = r, a[i] = n, a)[S] || 1, R = this.$d.getTime() + u * O;
        return g.w(R, this);
      }, c.subtract = function(u, f) {
        return this.add(-1 * u, f);
      }, c.format = function(u) {
        var f = this, a = this.$locale();
        if (!this.isValid()) return a.invalidDate || le;
        var _ = u || "YYYY-MM-DDTHH:mm:ssZ", S = g.z(this), p = this.$H, O = this.$m, R = this.$M, D = a.weekdays, h = a.months, G = a.meridiem, P = function(I, H, W, ee) {
          return I && (I[H] || I(f, _)) || W[H].slice(0, ee);
        }, x = function(I) {
          return g.s(p % 12 || 12, I, "0");
        }, w = G || function(I, H, W) {
          var ee = I < 12 ? "AM" : "PM";
          return W ? ee.toLowerCase() : ee;
        };
        return _.replace(Te, function(I, H) {
          return H || function(W) {
            switch (W) {
              case "YY":
                return String(f.$y).slice(-2);
              case "YYYY":
                return g.s(f.$y, 4, "0");
              case "M":
                return R + 1;
              case "MM":
                return g.s(R + 1, 2, "0");
              case "MMM":
                return P(a.monthsShort, R, h, 3);
              case "MMMM":
                return P(h, R);
              case "D":
                return f.$D;
              case "DD":
                return g.s(f.$D, 2, "0");
              case "d":
                return String(f.$W);
              case "dd":
                return P(a.weekdaysMin, f.$W, D, 2);
              case "ddd":
                return P(a.weekdaysShort, f.$W, D, 3);
              case "dddd":
                return D[f.$W];
              case "H":
                return String(p);
              case "HH":
                return g.s(p, 2, "0");
              case "h":
                return x(1);
              case "hh":
                return x(2);
              case "a":
                return w(p, O, !0);
              case "A":
                return w(p, O, !1);
              case "m":
                return String(O);
              case "mm":
                return g.s(O, 2, "0");
              case "s":
                return String(f.$s);
              case "ss":
                return g.s(f.$s, 2, "0");
              case "SSS":
                return g.s(f.$ms, 3, "0");
              case "Z":
                return S;
            }
            return null;
          }(I) || S.replace(":", "");
        });
      }, c.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, c.diff = function(u, f, a) {
        var _, S = this, p = g.p(f), O = M(u), R = (O.utcOffset() - this.utcOffset()) * s, D = this - O, h = function() {
          return g.m(S, O);
        };
        switch (p) {
          case Y:
            _ = h() / 12;
            break;
          case A:
            _ = h();
            break;
          case K:
            _ = h() / 3;
            break;
          case C:
            _ = (D - R) / 6048e5;
            break;
          case m:
            _ = (D - R) / 864e5;
            break;
          case y:
            _ = D / r;
            break;
          case E:
            _ = D / s;
            break;
          case i:
            _ = D / n;
            break;
          default:
            _ = D;
        }
        return a ? _ : g.a(_);
      }, c.daysInMonth = function() {
        return this.endOf(A).$D;
      }, c.$locale = function() {
        return b[this.$L];
      }, c.locale = function(u, f) {
        if (!u) return this.$L;
        var a = this.clone(), _ = Z(u, f, !0);
        return _ && (a.$L = _), a;
      }, c.clone = function() {
        return g.w(this.$d, this);
      }, c.toDate = function() {
        return new Date(this.valueOf());
      }, c.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, c.toISOString = function() {
        return this.$d.toISOString();
      }, c.toString = function() {
        return this.$d.toUTCString();
      }, d;
    }(), ge = X.prototype;
    return M.prototype = ge, [["$ms", o], ["$s", i], ["$m", E], ["$H", y], ["$W", m], ["$M", A], ["$y", Y], ["$D", F]].forEach(function(d) {
      ge[d[1]] = function(c) {
        return this.$g(c, d[0], d[1]);
      };
    }), M.extend = function(d, c) {
      return d.$i || (d(c, X, M), d.$i = !0), M;
    }, M.locale = Z, M.isDayjs = ae, M.unix = function(d) {
      return M(1e3 * d);
    }, M.en = b[U], M.Ls = b, M.p = {}, M;
  });
})(we);
const Re = (e) => typeof e == "function", V = ({
  client: e,
  key: t,
  fn: n,
  skipEmpty: s
}) => {
  const r = e.getQueryData(t);
  s && r === void 0 || e.setQueryData(t, n(r));
}, te = (e) => V({
  ...e,
  fn: (t) => ({
    code: 200,
    success: !0,
    ...t,
    data: Re(e.dataOrFn) ? e.dataOrFn(t == null ? void 0 : t.data) : e.dataOrFn
  })
}), qe = {
  update: te,
  addToArray: (e) => te({
    ...e,
    dataOrFn: (t) => [
      ...Array.isArray(e.data) ? e.data : [e.data],
      ...t || []
    ]
  }),
  updateInArray: ({
    data: e,
    keyId: t,
    ...n
  }) => te({
    ...n,
    dataOrFn: (s) => s == null ? void 0 : s.map((r) => r[t] === e[t] ? e : r)
  }),
  removeFromArray: ({
    id: e,
    keyId: t,
    ...n
  }) => te({
    ...n,
    dataOrFn: (s) => s == null ? void 0 : s.filter((r) => r[t] !== e)
  }),
  addToPages: (e) => V({
    ...e,
    fn: (t) => {
      var s, r, o;
      const n = {
        pageParams: [...(t == null ? void 0 : t.pageParams) || []],
        pages: [...(t == null ? void 0 : t.pages) || []]
      };
      return n.pages[0] = {
        ...n.pages[0],
        data: {
          code: 200,
          success: !0,
          ...(s = n.pages[0]) == null ? void 0 : s.data,
          data: e.updateFn((o = (r = n.pages[0]) == null ? void 0 : r.data) == null ? void 0 : o.data)
        }
      }, n;
    }
  }),
  updateInPages: (e) => V({
    ...e,
    fn: (t) => {
      var s, r;
      if (!t || !((s = t.pages) != null && s.length)) return t;
      const n = {
        pageParams: [...t.pageParams],
        pages: [...t.pages]
      };
      for (let o = 0; o < n.pages.length; o++) {
        const i = (r = n.pages[o]) == null ? void 0 : r.data, E = i == null ? void 0 : i.data;
        if (!E) continue;
        const y = e.updateFn(E);
        if (i !== y && (n.pages[o] = {
          ...n.pages[o],
          data: {
            ...i,
            data: y
          }
        }, e.stopOnChange))
          break;
      }
      return n;
    }
  })
}, Be = (e) => V({
  ...e,
  fn: (t) => {
    var n;
    return {
      code: 200,
      success: !0,
      ...t,
      data: {
        code: 200,
        success: !0,
        ...t == null ? void 0 : t.data,
        data: Re(e.dataOrFn) ? e.dataOrFn((n = t == null ? void 0 : t.data) == null ? void 0 : n.data) : e.dataOrFn
      }
    };
  }
}), Ge = {
  update: Be
}, $ = {
  update: V,
  request: qe,
  apiRequest: Ge
}, lt = "calendarEvent", mt = "cmsPage", gt = "cmsPages", Ct = "currentUser", pt = "dashboardPastSessions", ce = "dashboardSessions", At = "featuredSessions", Ot = "integrationsConnections", Mt = "integrationsDirectURL", Rt = "integrationsGetAction", Dt = "integrationsProviders", Qe = "meetingMemoryMessages", vt = "meetingMemorySuggestions", Le = "meetingMemoryThread", ke = "meetingMemoryThreads", Tt = "paymentDetails", $t = "recording", It = "recordingStatus", Ue = "session", Yt = "sessionAccessRule", Ae = "sessionAccessRules", xe = "sessionAiFeed", ht = "sessionEndSummary", Ft = "sessionEpisodes", Nt = "sessionPendingRulesStats", We = "sessionPresence", De = "sessionRequestAccess", Ht = "sessionViewers", Kt = "streamToken", Ve = "listeningModeSpeakers", bt = "subscriptionPlans", Pt = "subscriptionReceipts", je = "user", ze = "integrationsGetByOwnerSessionRecurrence", wt = "userMeetingTypes", qt = "salesforceProvider", Bt = "salesforceGetBindingById", Gt = "salesforceGetAccountById", Qt = "salesforceGetOpportunityById", Lt = "salesforceListBindableRecordsAsOptions", kt = "salesforceGetContacts", Ut = "salesforceGetLeadById", xt = "hubspotProvider", Wt = "hubspotGetBindingById", Je = "hubspotGetCompanyById", Vt = "hubspotGetDealById", jt = "hubspotListBindableRecordsAsOptions", zt = "hubspotGetContacts", Jt = "hubspotGetContactById", Ee = (e, t) => ["private", ...e, t], se = (e, t = "") => [
  xe,
  e,
  t
], de = (e, t = "") => [
  De,
  e,
  t,
  fe.IN_REVIEW
], Ze = (e, t, n) => [De, e, t, n || ""], Xe = (e, t, n) => [Ue, e, t || "", n || ""], et = (e, t = "") => [
  We,
  e,
  t
], tt = (e) => [je, e], Se = (e) => [
  ke,
  e
], Zt = (e) => [
  Le,
  e
], Q = (e) => [
  Qe,
  e
], Xt = (e, t) => [Ve, e, t], st = {
  add: ({
    client: e,
    data: t,
    sessionId: n,
    recurrenceId: s
  }) => {
    t && $.request.addToArray({
      client: e,
      key: se(n, s),
      data: [t]
    });
  },
  update: ({
    client: e,
    data: t,
    sessionId: n,
    recurrenceId: s
  }) => {
    t && $.request.update({
      client: e,
      key: se(n, s),
      dataOrFn: (r) => r ? (r.messages = r.messages.map(
        (o) => o.id === t.id ? t : o
      ), r) : { messages: [t], totalSummaryCount: 1 }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    recurrenceId: n,
    id: s
  }) => {
    $.request.removeFromArray({
      client: e,
      key: se(t, n),
      id: s,
      keyId: "id"
    });
  }
}, es = {
  ...st
}, nt = {
  addMessage: ({ client: e, data: t, id: n }) => {
    t && $.apiRequest.update(
      {
        client: e,
        key: Q(n),
        dataOrFn: (s) => ({
          hasMore: !1,
          ...s,
          messages: [...(s == null ? void 0 : s.messages) || [], t],
          total: ((s == null ? void 0 : s.total) || 0) + 1
        })
      }
    );
  },
  updateMessage: ({ client: e, data: t, id: n }) => {
    t && $.apiRequest.update(
      {
        client: e,
        key: Q(n),
        dataOrFn: (s) => s != null && s.messages ? {
          ...s,
          messages: s.messages.map(
            (r) => r.id === t.id ? { ...r, ...t } : r
          )
        } : s
      }
    );
  },
  updateLastAiMessage: ({
    client: e,
    data: t,
    id: n
  }) => {
    t && $.apiRequest.update(
      {
        client: e,
        key: Q(n),
        dataOrFn: (s) => {
          var y;
          const r = s && {
            ...s,
            messages: s.messages ? [...s.messages] : void 0
          };
          if (!(r != null && r.messages)) return s;
          const o = r.messages.findLastIndex(
            (m) => (!t.id || m.id === t.id) && m.role === "ai"
          );
          if (o === -1) return s;
          const i = r.messages[o], E = { ...i, ...t, id: t.id || i.id };
          return t.content && (E.content = `${i.content || ""}${t.content}`), t.todo && (E.todo = `${i.todo || ""}${t.todo}`), t.progress && (E.progress = `${i.progress || ""}${t.progress}`), r.messages[o] = E, !((y = E.content) != null && y.length) && t.isComplete && r.messages.splice(o, 1), r;
        }
      }
    );
  },
  removeMessage: ({
    client: e,
    id: t,
    messageId: n
  }) => {
    $.apiRequest.update(
      {
        client: e,
        key: Q(t),
        dataOrFn: (s) => {
          var i;
          if (!(s != null && s.messages)) return s;
          const r = s.messages.length, o = (i = s.messages) == null ? void 0 : i.filter(
            (E) => E.id !== n
          );
          return {
            ...s,
            messages: o,
            total: Math.max(
              (s.total || 0) - (r !== o.length ? 1 : 0),
              0
            )
          };
        }
      }
    );
  },
  removeLastMessage: ({ client: e, id: t }) => {
    $.apiRequest.update(
      {
        client: e,
        key: Q(t),
        dataOrFn: (n) => n != null && n.messages ? {
          ...n,
          messages: n.messages.slice(0, -1),
          total: Math.max((n.total || 0) - 1, 0)
        } : n
      }
    );
  },
  removeThread: ({
    client: e,
    id: t,
    userId: n
  }) => {
    $.apiRequest.update({
      client: e,
      key: Se(n),
      dataOrFn: (s) => {
        var i;
        if (!(s != null && s.threads)) return s;
        const r = s.threads.length, o = (i = s.threads) == null ? void 0 : i.filter((E) => E.id !== t);
        return {
          ...s,
          threads: o,
          total: Math.max(
            (s.total || 0) - (r !== o.length ? 1 : 0),
            0
          )
        };
      }
    });
  }
}, ts = nt, rt = {
  add: ({ client: e, data: t, userId: n }) => {
    t && $.request.addToPages({
      client: e,
      key: Ee([ce], n),
      updateFn: (s) => ({
        ...s,
        count: ((s == null ? void 0 : s.count) || 0) + 1,
        sessions: [t, ...(s == null ? void 0 : s.sessions) || []]
      })
    });
  },
  update: ({ client: e, data: t, userId: n }) => {
    t && $.request.updateInPages({
      client: e,
      key: Ee([ce], n),
      stopOnChange: !0,
      updateFn: (s) => {
        if (!(s != null && s.sessions)) return s;
        const r = s.sessions.findIndex(
          (i) => i.sessionID === t.sessionID
        );
        if (r === -1) return s;
        const o = {
          ...s,
          sessions: [...s.sessions]
        };
        return o.sessions[r] = t, o;
      }
    });
  },
  remove: ({
    client: e,
    userId: t,
    id: n
  }) => {
    let s;
    return $.request.updateInPages({
      client: e,
      key: Ee([ce], t),
      stopOnChange: !0,
      updateFn: (r) => {
        if (!(r != null && r.sessions)) return r;
        const o = r.sessions.findIndex((E) => E.sessionID === n);
        if (o === -1) return r;
        const i = { ...r, sessions: [...r.sessions] };
        return i.count = Math.max(0, (i.count || 1) - 1), s = i.sessions.splice(o, 1)[0], i;
      }
    }), s;
  }
}, ot = {
  add: ({ client: e, data: t = [] }) => {
    if (!t.length) return;
    const { sessionID: n, sessionRecurrenceID: s } = t[0];
    $.request.update({
      client: e,
      key: [
        Ae,
        n,
        s,
        fe.GRANTED
      ],
      dataOrFn: (r = {}) => {
        var A, K;
        const o = { ...r }, i = (Y, F) => (F.type === "domain" ? Y[0].push(F) : Y[1].push(F), Y), [E, y] = ((K = (A = o == null ? void 0 : o.data) == null ? void 0 : A.viewerAccessRules) == null ? void 0 : K.reduce(i, [[], []])) || [], [m, C] = t.reduce(i, [[], []]);
        return {
          data: {
            viewerAccessRules: [
              ...m,
              ...E || [],
              ...C,
              ...y || []
            ]
          }
        };
      }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: n,
    id: s
  }) => {
    $.request.update({
      client: e,
      key: [
        Ae,
        t,
        n,
        fe.GRANTED
      ],
      dataOrFn: (r) => {
        var i;
        const o = ((i = r == null ? void 0 : r.data.viewerAccessRules) == null ? void 0 : i.filter((E) => E.id !== s)) || [];
        return {
          ...r,
          data: {
            ...r == null ? void 0 : r.data,
            viewerAccessRules: o
          }
        };
      }
    });
  },
  // requests
  addRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: n,
    data: s
  }) => {
    var E;
    const r = de(t, n), o = e.getQueryData(r) || j(void 0), i = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((E = o.data) == null ? void 0 : E.accessRules) || [], ...s]
      }
    };
    e.setQueryData(r, i);
  },
  removeRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: n,
    id: s
  }) => {
    var C, A;
    const r = de(t, n), o = e.getQueryData(r);
    if (!((C = o == null ? void 0 : o.data) != null && C.accessRules)) return;
    const i = o.data.accessRules, E = i == null ? void 0 : i.findIndex((K) => K.id === s);
    if (E === -1) return;
    const y = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((A = o.data) == null ? void 0 : A.accessRules) || []]
      }
    }, m = y.data.accessRules.splice(E, 1)[0];
    return e.setQueryData(r, y), m;
  }
}, ss = {
  session: rt,
  viewerAccess: ot
};
let ne;
const ns = (e) => {
  ne = e.logger;
}, it = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, rs = (e) => it(e.data), os = (e, t = 0) => {
  var n, s, r;
  return (r = (s = (n = e.data) == null ? void 0 : n.pages[t]) == null ? void 0 : s.data) == null ? void 0 : r.data;
}, J = (e, t, n = void 0) => {
  try {
    return Fe(e, t).newDocument;
  } catch (s) {
    return ne == null || ne.logWith(3, s, e, t), he.report(s), n;
  }
}, is = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1
}, ye = (e) => (t) => ({
  gcTime: t,
  staleTime: t,
  retry: 2,
  refetchOnMount: !0,
  refetchOnReconnect: e,
  refetchOnWindowFocus: e
}), l = {
  "7d": 6048e5,
  "3d": 2592e5,
  "1d": 864e5,
  "6h": 216e5,
  "1h": 36e5,
  "30m": 18e5,
  "15m": 9e5,
  "10m": 6e5,
  "5m": 3e5,
  "3m": 18e4,
  "1m": 6e4,
  "30s": 3e4,
  "15s": 15e3,
  "5s": 5e3,
  "3s": 3e3,
  0: 0
}, N = [""], q = () => {
}, v = ye(!0), us = {
  "7d": v(l["7d"]),
  "3d": v(l["3d"]),
  "1d": v(l["1d"]),
  "6h": v(l["6h"]),
  "1h": v(l["1h"]),
  "30m": v(l["30m"]),
  "15m": v(l["15m"]),
  "10m": v(l["10m"]),
  "5m": v(l["5m"]),
  "3m": v(l["3m"]),
  "1m": v(l["1m"]),
  "30s": v(l["30s"]),
  "15s": v(l["15s"]),
  "3s": v(l["3s"]),
  0: v(0)
}, T = ye(!1), ie = {
  "7d": T(l["7d"]),
  "3d": T(l["3d"]),
  "1d": T(l["1d"]),
  "6h": T(l["6h"]),
  "1h": T(l["1h"]),
  "30m": T(l["30m"]),
  "15m": T(l["15m"]),
  "10m": T(l["10m"]),
  "5m": T(l["5m"]),
  "3m": T(l["3m"]),
  "1m": T(l["1m"]),
  "30s": T(l["30s"]),
  "15s": T(l["15s"]),
  "3s": T(l["3s"]),
  0: T(0)
}, B = (e) => ({
  ...ye(!1)(e),
  refetchOnReconnect: !0
}), as = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: n,
  onFetched: s,
  onPatch: r,
  options: o,
  params: i
}) => {
  const E = !!e, y = E ? se(e, t) : N;
  return {
    ...B(l["3s"]),
    ...o,
    queryKey: y,
    queryFn: E ? ({ signal: m }) => Oe.getFeed(e, t, {
      ...i,
      signal: m,
      params: {
        limit: 200,
        sortOrder: "asc",
        ...i == null ? void 0 : i.params
      },
      onFetched: s,
      onPatch: (C) => {
        r && r(C), n.setQueryData(
          y,
          (A = j(
            {
              messages: [],
              totalSummaryCount: 0
            }
          )) => J(L(A), C) ?? A
        );
      }
    }) : q
  };
}, cs = ({
  sessionId: e,
  recurrenceId: t,
  options: n,
  params: s
}) => ({
  ...n,
  mutationFn: (r) => Oe.event(e, t, {
    ...s,
    data: r
  })
}), ut = (e, t) => [
  ze,
  e,
  t
], Es = ({
  sessionId: e,
  recurrenceId: t,
  queryClient: n,
  options: s,
  params: r
}) => {
  const o = !!e && !!t, i = o ? ut(e, t) : N;
  return {
    queryKey: i,
    enabled: o,
    ...B(l[0]),
    ...s,
    queryFn: o ? () => _e.getBindingsByOwnerSessionRecurrencePerProvider(
      e,
      t,
      {
        onFetched: (E) => {
          $.request.update({
            client: n,
            key: i,
            dataOrFn: E
          });
        },
        onPatch: (E) => {
          n.setQueryData(
            i,
            (y = {}) => J(L(y), E) ?? y
          );
        },
        ...r
      }
    ) : q
  };
}, fs = ({
  params: e,
  ...t
}) => ({
  ...t,
  mutationFn: ({
    sessionId: n,
    sessionRecurrenceId: s,
    record: { id: r, type: o }
  }) => _e.hubspot.createBinding({
    ...e,
    data: {
      sessionId: n,
      sessionRecurrenceId: s,
      record: {
        id: r,
        type: o
      }
    }
  })
}), at = (e) => [
  Je,
  e
], ds = ({
  id: e,
  params: t,
  ...n
}) => {
  const s = !!e;
  return {
    ...ie["1m"],
    ...n,
    enabled: s,
    queryKey: s ? at(e) : N,
    queryFn: s ? () => _e.hubspot.getCompanyById(e) : q
  };
}, _s = ({
  userId: e,
  options: t,
  params: n
}) => {
  const s = !!e, r = s ? Se(e) : N;
  return {
    ...ie["3m"],
    ...t,
    queryKey: r,
    queryFn: s ? () => re.threads(n) : q
  };
}, Ss = ({
  id: e,
  options: t,
  params: n
}) => {
  const s = !!e, r = s ? Se(e) : N;
  return {
    ...ie["3m"],
    ...t,
    queryKey: r,
    enabled: s,
    queryFn: s ? () => re.thread(e, n) : q
  };
}, ys = ({
  id: e,
  options: t,
  params: n
}) => {
  const s = !!e, r = s ? Q(e) : N;
  return {
    ...ie["3m"],
    queryKey: r,
    enabled: s,
    ...t,
    queryFn: s ? () => re.threadMessages(e, n) : q
  };
}, ls = ({
  id: e,
  options: t,
  params: n
}) => ({
  ...t,
  mutationFn: (s) => re.deleteThread(e, {
    ...n,
    data: s
  })
}), ms = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: n,
  onPatch: s,
  options: r,
  params: o
}) => {
  const i = !!e, E = i ? de(e, t) : N;
  return {
    ...B(l["3s"]),
    ...r,
    queryKey: E,
    enabled: i,
    queryFn: ({ signal: y }) => oe.inReview({
      ...o,
      signal: y,
      params: {
        ...o == null ? void 0 : o.params,
        sessionID: e || "",
        sessionRecurrenceID: t
      },
      onPatch: (m) => {
        s && s(m), n.setQueryData(
          E,
          (C = j(
            void 0
          )) => J(L(C), m) ?? C
        );
      }
    })
  };
}, gs = ({
  userId: e,
  sessionId: t,
  recurrenceId: n = "",
  queryClient: s,
  onPatch: r,
  options: o,
  params: i
}) => {
  const E = !!e && !!t, y = E ? Ze(e, t, n) : N;
  return {
    ...B(l["3s"]),
    ...o,
    queryKey: y,
    enabled: E,
    queryFn: E ? ({ signal: m }) => oe.userSessionRequest({
      ...i,
      signal: m,
      params: {
        ...i == null ? void 0 : i.params,
        sessionID: t,
        ...n ? { sessionRecurrenceID: n } : {}
      },
      onPatch: (C) => {
        r && r(C), s.setQueryData(
          y,
          (A = j(
            void 0
          )) => J(L(A), L(C)) ?? A
        );
      }
    }) : q
  };
}, Cs = ({
  options: e,
  params: t
} = {}) => ({
  ...e,
  mutationFn: (n) => oe.requestAccess({
    ...t,
    data: n
  })
}), ps = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (n) => oe.update({
    ...t,
    data: n
  })
}), As = ({
  sessionId: e,
  recurrenceId: t = "",
  userId: n,
  onFetched: s,
  onPatch: r,
  onError: o,
  options: i,
  params: E
}) => {
  const y = e ? Xe(e, t, n) : N;
  return {
    ...B(l[0]),
    // force no caching because of problems with redux sync
    initialData: void 0,
    retry: (m, C) => (C == null ? void 0 : C.code) !== 401,
    enabled: !!e,
    ...i,
    queryKey: y,
    queryFn: e ? ({ signal: m }) => z.getById(e, {
      ...E,
      signal: m,
      onFetched: s,
      onPatch: r,
      onError: o
    }) : void 0
  };
}, Os = ({
  sessionId: e,
  recurrenceId: t = "",
  options: n,
  params: s
}) => ({
  ...n,
  mutationFn: (r) => z.update(e, t, {
    ...s,
    data: r
  })
}), Ms = ({
  sessionId: e
}) => ({
  mutationFn: () => z.end(e)
}), Rs = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: n,
  onPatch: s,
  options: r,
  params: o
}) => {
  const i = e ? et(e, t) : N;
  return {
    ...B(l["3s"]),
    enabled: !!e,
    ...r,
    queryKey: i,
    queryFn: e ? ({ signal: E }) => z.presence(e, {
      ...o,
      signal: E,
      onPatch: (y) => {
        y && (s && s(y), n.setQueryData(
          i,
          (m = j(
            void 0
          )) => J(L(m), y) ?? m
        ));
      }
    }) : void 0
  };
}, Ds = ({
  sessionId: e,
  params: t,
  options: n
}) => ({
  ...n,
  mutationFn: (s) => z.updateParticipantMetadata(e, {
    ...t,
    data: s
  })
}), vs = ({
  userId: e,
  accessToken: t,
  params: n,
  ...s
}) => ({
  ...s,
  mutationFn: () => {
    if (!e || !t) throw new Error("Unauthorized");
    return Me.activateTrial(e, {
      ...n,
      data: { token: t }
    });
  }
}), Ts = ({
  accessToken: e,
  params: t,
  ...n
}) => ({
  ...n,
  mutationFn: () => Ye.hostOptIn({
    data: { accessToken: e },
    ...t
  })
}), $s = ({
  id: e,
  options: t,
  params: n
}) => {
  const s = tt(e);
  return {
    ...B(l["3m"]),
    ...t,
    queryKey: s,
    queryFn: ({ signal: r }) => Me.getById(e, { ...n, signal: r })
  };
}, Is = (e) => {
  const t = Ne(e), { onSuccess: n, onError: s } = e, { isError: r, isSuccess: o, error: i, data: E } = t;
  return k(() => {
    o && n && n(E);
  }, [E, o, n]), k(() => {
    r && s && s(i);
  }, [i, r, s]), t;
}, Ys = (e) => {
  const t = He(e), { onSuccess: n, onError: s } = e, { isError: r, isSuccess: o, error: i, data: E } = t;
  return k(() => {
    o && n && n(E);
  }, [E, o, n]), k(() => {
    r && s && s(i);
  }, [i, r, s]), t;
}, ct = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1,
  refetchInterval: !1,
  useErrorBoundary: !1,
  enabled: !1
}, hs = (e, t) => {
  const n = Ke(), s = {
    queryKey: e,
    ...ct,
    ...t
  }, r = be(s);
  r.current = s;
  const [o, i] = pe(
    () => new Ce(n, s)
  ), [E, y] = pe(
    o == null ? void 0 : o.getOptimisticResult(s)
  );
  return k(() => {
    if (!(e != null && e.length)) return;
    const m = new Ce(
      n,
      r.current
    );
    i(m);
    const C = m.getOptimisticResult(r.current);
    y(C);
  }, [n, e]), k(() => {
    const m = o.subscribe((C) => {
      y(C);
    });
    return () => {
      m();
    };
  }, [o]), E;
};
export {
  lt as CACHE_KEY_CALENDAR_EVENT,
  mt as CACHE_KEY_CMS_PAGE,
  gt as CACHE_KEY_CMS_PAGES,
  Ct as CACHE_KEY_CURRENT_USER,
  pt as CACHE_KEY_DASHBOARD_PAST_SESSIONS,
  ce as CACHE_KEY_DASHBOARD_SESSIONS,
  At as CACHE_KEY_FEATURED_SESSIONS,
  Wt as CACHE_KEY_HUBSPOT_GET_BINDING_BY_ID,
  Je as CACHE_KEY_HUBSPOT_GET_COMPANY_BY_ID,
  zt as CACHE_KEY_HUBSPOT_GET_CONTACTS,
  Jt as CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID,
  Vt as CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID,
  jt as CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  xt as CACHE_KEY_HUBSPOT_PROVIDER,
  Ot as CACHE_KEY_INTEGRATIONS_CONNECTIONS,
  Mt as CACHE_KEY_INTEGRATIONS_DIRECT_URL,
  Rt as CACHE_KEY_INTEGRATIONS_GET_ACTION,
  ze as CACHE_KEY_INTEGRATIONS_GET_BY_OWNER_SESSION_RECURRENCE,
  Dt as CACHE_KEY_INTEGRATIONS_PROVIDERS,
  Ve as CACHE_KEY_LISTENING_MODE_SPEAKERS,
  Qe as CACHE_KEY_MEETING_MEMORY_MESSAGES,
  vt as CACHE_KEY_MEETING_MEMORY_SUGGESTIONS,
  Le as CACHE_KEY_MEETING_MEMORY_THREAD,
  ke as CACHE_KEY_MEETING_MEMORY_THREADS,
  Tt as CACHE_KEY_PAYMENT_DETAILS,
  $t as CACHE_KEY_RECORDING,
  It as CACHE_KEY_RECORDING_STATUS,
  Gt as CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID,
  Bt as CACHE_KEY_SALESFORCE_GET_BINDING_BY_ID,
  kt as CACHE_KEY_SALESFORCE_GET_CONTACTS,
  Ut as CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID,
  Qt as CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID,
  Lt as CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  qt as CACHE_KEY_SALESFORCE_PROVIDER,
  Ue as CACHE_KEY_SESSION,
  Yt as CACHE_KEY_SESSION_ACCESS_RULE,
  Ae as CACHE_KEY_SESSION_ACCESS_RULES,
  xe as CACHE_KEY_SESSION_AI_FEED,
  ht as CACHE_KEY_SESSION_END_SUMMARY,
  Ft as CACHE_KEY_SESSION_EPISODES,
  Nt as CACHE_KEY_SESSION_PENDING_RULES_STATS,
  We as CACHE_KEY_SESSION_PRESENCE,
  De as CACHE_KEY_SESSION_REQUEST_ACCESS,
  Ht as CACHE_KEY_SESSION_VIEWERS,
  Kt as CACHE_KEY_STREAM_TOKEN,
  bt as CACHE_KEY_SUBSCRIPTION_PLANS,
  Pt as CACHE_KEY_SUBSCRIPTION_RECEIPTS,
  je as CACHE_KEY_USER,
  wt as CACHE_KEY_USER_MEETING_TYPES,
  vs as activateTrialMutation,
  us as activeOptions,
  es as aiFeedCacheService,
  cs as aiFeedEventMutation,
  B as baseBraidOptions,
  Ee as buildPrivateQueryKey,
  $ as cacheService,
  fs as createCRMBindingMutation,
  is as disabledFetchOptions,
  N as emptyCacheKey,
  q as emptyFn,
  Ms as endSessionMutation,
  v as getActiveOptions,
  se as getAiFeedQueryKey,
  Es as getCRMProviderBindingsForSessionQuery,
  ut as getCRMProviderBindingsForSessionQueryKey,
  T as getInactiveOptions,
  os as getInfinityRequestData,
  Xt as getListeningModeSpeakersQueryKey,
  Q as getMeetingMemoryThreadMessagesQueryKey,
  Zt as getMeetingMemoryThreadQueryKey,
  Se as getMeetingMemoryThreadsQueryKey,
  rs as getQueryRequestData,
  it as getRequestData,
  as as getSessionAiFeedQuery,
  et as getSessionPresenceKey,
  As as getSessionQuery,
  Xe as getSessionQueryKey,
  tt as getUserQueryKey,
  Ts as hostOptInMutation,
  at as hubspotGetCompanyQueryKey,
  ds as hubstopGetCompanyQuery,
  ie as inactiveOptions,
  ns as initConfig,
  J as jsonPatch,
  ne as logger,
  ts as meetingMemoryCacheService,
  ls as meetingMemoryDeleteThreadMutation,
  ys as meetingMemoryThreadMessagesQuery,
  Ss as meetingMemoryThreadQuery,
  _s as meetingMemoryThreadsQuery,
  l as milliseconds,
  ct as queryDataDefaultOptions,
  Cs as requestAccessMutation,
  ss as sessionCacheService,
  ms as sessionPendingAccessQuery,
  de as sessionPendingAccessQueryKey,
  Rs as sessionPresenceQuery,
  Ds as updateParticipantMetadataMutation,
  ps as updateSessionAccessMutation,
  Os as updateSessionMutation,
  Is as useInfiniteQuery,
  Ys as useQuery,
  hs as useQueryData,
  $s as userQuery,
  gs as userSessionRequestAccessQuery,
  Ze as userSessionRequestAccessQueryKey
};
