import { SessionCalendarType as zt, SessionStatus as Ne, TeamMemberRole as xe, ERROR_SESSION_ACCESS_UNAUTHORIZED_LOCKED as Bt, ERROR_SESSION_ACCESS_UNAUTHORIZED_UNLOCKED as Ft, ERROR_SESSION_UNAUTHENTICATED as Lt, ERROR_SESSION_ACCESS_INACTIVE as Kt, SessionAccessRuleStatus as Te, STORAGE_LAST_USED_INTEGRATION as qe, ERROR_SESSION_ACCESS_ALREADY_APPROVED as jt, LOGGER_MEETING_MEMORY as qt } from "@waitroom/models";
import { isValidUrl as Ut, toBoolean as Ht, parseJson as Ze, decodeHtmlEntities as Ae, buildKey as Ue } from "@waitroom/utils";
import { useMutation as k, useQueryClient as B, useQuery as V } from "@tanstack/react-query";
import { selectAuthState as Je, authService as Xe, setSubscriptionPlan as He, updateAuthState as et, selectCurrentUserId as ne, selectAuthUserId as fe, selectAuthStatus as Gt, selectIsAuthenticated as Qt, selectIsCurrentUserGuest as Yt, authReducer as Vt } from "@waitroom/auth";
import { activateTrialMutation as Wt, getRequestData as Oe, getCRMProviderBindingsForSessionQuery as xt, getCRMProviderBindingsForSessionQueryKey as Zt, useQueryData as ie, endSessionMutation as Jt, getSessionQueryKey as tt, getSessionQuery as Xt, jsonPatch as es, emptyCacheKey as ce, userSessionRequestAccessQuery as ts, userSessionRequestAccessQueryKey as ss, CACHE_KEY_SESSION as ns, hostOptInMutation as os, createCRMBindingMutation as as, useQuery as H, hubstopGetCompanyQuery as rs, CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID as is, inactiveOptions as W, CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS as cs, CACHE_KEY_HUBSPOT_GET_CONTACTS as us, CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID as ds, activeOptions as ls, CACHE_KEY_INTEGRATIONS_GET_ACTION as ms, CACHE_KEY_INTEGRATIONS_CONNECTIONS as gs, updateParticipantMetadataMutation as ps, sessionCacheService as fs, meetingMemoryCacheService as J, buildPrivateQueryKey as st, CACHE_KEY_MEETING_MEMORY_SUGGESTIONS as Ss, meetingMemoryThreadMessagesQuery as ys, getMeetingMemoryThreadMessagesQueryKey as hs, getMeetingMemoryThreadsQueryKey as vs, getQueryRequestData as bs, CACHE_KEY_SALESFORCE_PROVIDER as Ee, CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID as $s, CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID as As, CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS as Es, CACHE_KEY_SALESFORCE_GET_CONTACTS as ws, CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID as Cs, getSessionAiFeedQuery as _s, getAiFeedQueryKey as Is, sessionPendingAccessQuery as Ts, sessionPendingAccessQueryKey as Os, CACHE_KEY_SESSION_PENDING_RULES_STATS as nt, sessionPresenceQuery as ks, getSessionPresenceKey as Ds, aiFeedCacheService as Ms, updateSessionMutation as Ps } from "@waitroom/react-query";
import { useDispatch as ke, useSelector as _ } from "react-redux";
import { braidService as Se, userApiService as Rs, integrationsApiService as P, sessionAccessApiService as ot, meetingMemoryApiService as De, getApiResponseData as Ns, sessionRecordingsApiService as Ge, aiApiService as zs } from "@waitroom/common-api";
import { useEffect as x, useMemo as G, useRef as Re, useCallback as re, useState as be } from "react";
import { deepClone as Qe } from "fast-json-patch";
import { createAction as Q } from "@waitroom/react-utils";
import { produce as at } from "immer";
import { createSelector as R } from "reselect";
import { nanoid as Bs } from "nanoid";
let U;
const dc = (e) => {
  U = { ...U, ...e };
}, Fs = (e, t) => {
  var s;
  return Number(((s = e.data) == null ? void 0 : s.code) || e.code) === t;
}, lc = (e, t) => {
  var s;
  return t.includes(Number(((s = e.data) == null ? void 0 : s.code) || e.code));
}, rt = (e, t) => t.find((s) => s.name === e), it = (e, t) => t.find((s) => s.name === e), ct = (e, t) => t.find(({ provider: s }) => s.name === e), ut = (e, t) => {
  for (let s = 0; s < t.length; s++) {
    const n = t[s], { provider: o } = ct(e.providerName, n.connectedProviders) || {};
    if (!o)
      continue;
    const a = it(
      e.integrationName,
      o.integrations
    );
    return a && rt(e.actionName, a.actions) ? o : void 0;
  }
}, mc = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  findAction: rt,
  findIntegration: it,
  findLastUsedIntegrationProvider: ut,
  findProvider: ct
}, Symbol.toStringTag, { value: "Module" })), gc = (e) => !!e.calendarID && e.calendarType === zt.Google, dt = (e, t) => {
  if (e.length === 1 || !t) return e;
  let s = e[0];
  const n = e.filter((o) => o.id !== t ? !0 : (s = o, !1));
  return [s, ...n];
}, Ls = {
  facebook: "https://www.facebook.com/",
  instagram: "https://www.instagram.com/",
  twitter: "https://twitter.com/",
  linkedin: "https://www.linkedin.com/in/",
  tiktok: "https://www.tiktok.com/@",
  youtube: "https://www.youtube.com/c/",
  website: "//"
}, Ks = (e, t) => js(Ls[e], t), js = (e, t) => !e || !t || Ut(t) ? t || e : `${e}${t.trim().replace(/^@/, "")}`, pc = (e) => !e || !e.length ? e : e.map((t) => ({
  ...t,
  url: Ks(t.platform, t.url)
})), qs = async (e, t, s, n, o) => {
  const a = Oe(t);
  if (!a) throw new Error("Something went wrong");
  const { authToken: u, refreshToken: m, userSubscriptionPlan: g } = a;
  if (u) {
    const h = {
      accessToken: u,
      refreshToken: m
    }, f = await Xe.setup(h);
    if (!f.isAuthenticated) throw new Error("Unauthorized");
    g && e(He(g)), f && e(et(f));
  } else
    g && e(He(g));
  o && o(t, s, n);
}, fc = (e) => {
  const t = ke(), { userId: s, accessToken: n } = _(Je);
  return k(
    Wt({
      userId: s,
      accessToken: n,
      ...e,
      onSuccess: (o, a, u) => qs(t, o, a, u, e.onSuccess)
    })
  );
};
var Us = /* @__PURE__ */ ((e) => (e.Salesforce = "salesforce", e.Hubspot = "hubspot", e))(Us || {});
const Sc = (e) => {
  const t = B(), s = `crmBindings-${e.sessionId}-${e.recurrenceId}`;
  V(
    xt({
      ...e,
      queryClient: t,
      params: {
        ...e.params,
        key: s
      }
    })
  ), x(() => () => {
    Se.close(s);
  }, [s]);
}, Hs = (e, t) => {
  const s = G(
    () => Zt(e, t),
    [e, t]
  );
  return ie(s);
}, yc = (e, t, s) => {
  const { data: n } = Hs(
    e,
    t
  ), o = n == null ? void 0 : n.data;
  return o == null ? void 0 : o[s];
}, hc = (e) => k(Jt(e)), I = /* @__PURE__ */ (() => {
  const e = (n, ...o) => {
    t.console && console[n](...o);
  };
  let t = { console: !0 };
  const s = (n) => {
    throw n;
  };
  return {
    init: (n) => {
      t = { ...t, ...n };
    },
    c: e,
    console: e,
    show: (n, ...o) => console[n](...o),
    report: (n, o = "error") => {
      t.log && t.log(n), e(o, n);
    },
    raise: s,
    rescue: (n, o) => n instanceof o ? n : s(n)
  };
})(), vc = {
  override: void 0,
  has: function(t) {
    const s = U.featureFlags;
    return this.override !== void 0 ? this.override : !!s && (s[t] === !0 || s[t] === "true");
  }
}, lt = "SET_MODAL", bc = Q(lt), Gs = {
  type: void 0,
  props: {}
}, Qs = (e = Gs, t) => at(e, () => {
  switch (t.type) {
    case lt:
      return t.payload || {};
    default:
      return;
  }
}), $c = (e) => e.modal, mt = "app/NAVIGATE_TO", Ac = Q(mt), Ys = {}, Vs = (e = Ys, { type: t, payload: s }) => {
  switch (t) {
    case mt:
      return typeof s == "string" ? {
        path: s
      } : s || {};
    default:
      return e;
  }
}, Ec = (e) => e.navigation, Ws = "session/SETUP_CURRENT_SESSION", gt = "session/SET_CURRENT_SESSION", pt = "session/SET_ACTIVE_SESSION", ft = "session/SET_BOTH_SESSIONS", St = "session/SET_SESSION", yt = "session/CLEAR_SESSION", ht = "session/UPDATE_CURRENT_SESSION", vt = "session/SET_SESSION_MODAL", bt = "session/CLOSE_SESSION_MODAL", $t = "session/GRANT_CONSENT_TO_BROADCAST", At = "session/REVOKE_CONSENT_TO_BROADCAST", wc = Q(Ws), xs = Q(gt), Zs = Q(ft), Cc = Q(pt), Js = Q(St), _c = Q(yt), Xs = Q(ht), Ic = (e) => ({
  type: vt,
  payload: e
}), Tc = (e) => ({
  type: bt,
  payload: e
}), Oc = Q($t), kc = Q(At), en = (e, t) => {
  if (t)
    return e && t.find((s) => e === s.id) || t[0];
}, tn = (e) => e ? e.primaryHost || en(e.primaryHostUserID, e.hosts) : void 0, Dc = (e) => e === Ne.IN_SESSION, Et = (e, t) => e.findLast((s) => s.state === t), sn = (e) => {
  var t;
  return (t = Et(e, Ne.IN_SESSION)) == null ? void 0 : t.updatedAt;
}, nn = (e) => {
  var t;
  return (t = Et(e, Ne.ENDED)) == null ? void 0 : t.updatedAt;
}, Mc = (e, t) => (e == null ? void 0 : e.primaryHostUserID) === (t == null ? void 0 : t.id) && (t == null ? void 0 : t.id) !== void 0;
var on = /* @__PURE__ */ ((e) => (e[e.HOST = 1] = "HOST", e[e.TOPIC = 2] = "TOPIC", e[e.HOW_IT_WORKS = 3] = "HOW_IT_WORKS", e[e.CAM_MIC_PERMISSIONS = 4] = "CAM_MIC_PERMISSIONS", e[e.TEST_DEVICES = 5] = "TEST_DEVICES", e[e.INTERRUPTED = 6] = "INTERRUPTED", e[e.VIEWER_ACCESS = 7] = "VIEWER_ACCESS", e[e.DATA_VISIBILITY = 8] = "DATA_VISIBILITY", e))(on || {}), an = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function wt(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var rn = function(t) {
  return cn(t) && !un(t);
};
function cn(e) {
  return !!e && typeof e == "object";
}
function un(e) {
  var t = Object.prototype.toString.call(e);
  return t === "[object RegExp]" || t === "[object Date]" || mn(e);
}
var dn = typeof Symbol == "function" && Symbol.for, ln = dn ? Symbol.for("react.element") : 60103;
function mn(e) {
  return e.$$typeof === ln;
}
function gn(e) {
  return Array.isArray(e) ? [] : {};
}
function $e(e, t) {
  return t.clone !== !1 && t.isMergeableObject(e) ? pe(gn(e), e, t) : e;
}
function pn(e, t, s) {
  return e.concat(t).map(function(n) {
    return $e(n, s);
  });
}
function fn(e, t) {
  if (!t.customMerge)
    return pe;
  var s = t.customMerge(e);
  return typeof s == "function" ? s : pe;
}
function Sn(e) {
  return Object.getOwnPropertySymbols ? Object.getOwnPropertySymbols(e).filter(function(t) {
    return Object.propertyIsEnumerable.call(e, t);
  }) : [];
}
function Ye(e) {
  return Object.keys(e).concat(Sn(e));
}
function Ct(e, t) {
  try {
    return t in e;
  } catch {
    return !1;
  }
}
function yn(e, t) {
  return Ct(e, t) && !(Object.hasOwnProperty.call(e, t) && Object.propertyIsEnumerable.call(e, t));
}
function hn(e, t, s) {
  var n = {};
  return s.isMergeableObject(e) && Ye(e).forEach(function(o) {
    n[o] = $e(e[o], s);
  }), Ye(t).forEach(function(o) {
    yn(e, o) || (Ct(e, o) && s.isMergeableObject(t[o]) ? n[o] = fn(o, s)(e[o], t[o], s) : n[o] = $e(t[o], s));
  }), n;
}
function pe(e, t, s) {
  s = s || {}, s.arrayMerge = s.arrayMerge || pn, s.isMergeableObject = s.isMergeableObject || rn, s.cloneUnlessOtherwiseSpecified = $e;
  var n = Array.isArray(t), o = Array.isArray(e), a = n === o;
  return a ? n ? s.arrayMerge(e, t, s) : hn(e, t, s) : $e(t, s);
}
pe.all = function(t, s) {
  if (!Array.isArray(t))
    throw new Error("first argument should be an array");
  return t.reduce(function(n, o) {
    return pe(n, o, s);
  }, {});
};
var vn = pe, bn = vn;
const $n = /* @__PURE__ */ wt(bn), An = (e, t = "") => !e || !e.firstName && !e.fullName && !e.userFirstName && !e.userLastName ? t : e.fullName ? e.fullName : `${e.firstName || e.userFirstName} ${e.lastName || e.userLastName || ""}`, Pc = (e, t) => `${e}${t ? ` ${t}` : ""}`, Rc = (e, t = "") => {
  var o, a;
  if (!e || !e.firstName && !e.fullName && !e.userFirstName && !e.userLastName)
    return t;
  const s = e.firstName || e.userFirstName || "", n = ((o = e.lastName) == null ? void 0 : o.charAt(0)) || ((a = e.userLastName) == null ? void 0 : a.charAt(0)) || "";
  return `${s} ${n}`;
}, En = (e) => e && {
  ...e,
  fullName: An(e)
}, _t = (e) => e && e.map((t) => En(t)), Nc = (e, t) => !!e && !!t && e.id === t.id, zc = (e) => ({
  id: e.id,
  firstName: "Anonymous",
  fullName: "Anonymous"
}), se = {
  current: {},
  active: {
    consentToBroadcast: !1
  },
  allMap: {},
  summaryMap: {},
  modal: {}
}, _e = (e) => {
  const t = dt(
    _t(e.hosts),
    e.primaryHostUserID
  );
  return {
    ...e,
    primaryHost: tn(e),
    hosts: t
  };
}, Bc = (e = se, t) => at(e, (s) => {
  switch (t.type) {
    case St:
      t.payload && (s.allMap[t.payload.sessionID] = _e(t.payload));
      break;
    case yt:
      t.payload && (delete s.allMap[t.payload], s.active.id === t.payload && (s.active = se.active), s.current.id === t.payload && (s.current = se.current));
      break;
    case gt:
      t.payload ? typeof t.payload == "string" ? s.current.id = t.payload : (s.current.id = t.payload.sessionID, s.allMap[t.payload.sessionID] = _e(t.payload)) : s.current = se.current;
      break;
    case pt:
      t.payload ? typeof t.payload == "string" ? s.active.id = t.payload : (s.active.id = t.payload.sessionID, s.allMap[t.payload.sessionID] = _e(t.payload)) : s.active = se.active;
      break;
    case ft:
      t.payload ? typeof t.payload == "string" ? (s.current.id = t.payload, s.active.id = t.payload) : (s.current.id = t.payload.sessionID, s.active.id = t.payload.sessionID, s.allMap[t.payload.sessionID] = _e(t.payload)) : (s.current = se.current, s.active = se.active);
      break;
    case ht:
      const { sessionID: n, ...o } = t.payload || {};
      t.payload.sessionID && (s.allMap[n] = $n(
        s.allMap[n],
        o,
        {
          // TODO: issue with deepMerge - https://github.com/TehShrike/deepmerge/issues/264
          arrayMerge: (a, u) => a.length > 0 ? a : u
        }
      ), o.hosts && (s.allMap[n].hosts = dt(
        _t(o.hosts),
        o.primaryHostUserID || s.allMap[n].primaryHostUserID
      )));
      break;
    case vt:
      s.modal = t.payload || {};
      break;
    case bt:
      s.modal.type === t.payload && (s.modal = {});
      break;
    case $t: {
      s.active.consentToBroadcast = !0;
      break;
    }
    case At: {
      s.active.consentToBroadcast = !1;
      break;
    }
  }
}), ue = (e) => e.sessions || se, It = (e) => ue(e).allMap, Fc = (e, t) => ue(e).allMap[t], Lc = (e, t) => !!ue(e).allMap[t], Kc = (e) => ue(e).modal, jc = [
  "free",
  "premium",
  "pro",
  "enterprise"
], qc = {
  free: ["meetings", "timeLimit", "integrations", "stream"],
  premium: [
    "meetingMemory",
    "aiFeed",
    "recording",
    "integrations",
    "stream",
    "aiFeedLocal",
    "meetingSummary",
    "offTheRecord",
    "support"
  ],
  pro: void 0,
  enterprise: void 0
};
var Tt = { exports: {} };
(function(e, t) {
  (function(s, n) {
    e.exports = n();
  })(an, function() {
    var s = 1e3, n = 6e4, o = 36e5, a = "millisecond", u = "second", m = "minute", g = "hour", h = "day", f = "week", d = "month", b = "quarter", v = "year", E = "date", N = "Invalid Date", O = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, L = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, Z = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(p) {
      var c = ["th", "st", "nd", "rd"], r = p % 100;
      return "[" + p + (c[(r - 20) % 10] || c[r] || c[0]) + "]";
    } }, X = function(p, c, r) {
      var l = String(p);
      return !l || l.length >= c ? p : "" + Array(c + 1 - l.length).join(r) + p;
    }, Pe = { s: X, z: function(p) {
      var c = -p.utcOffset(), r = Math.abs(c), l = Math.floor(r / 60), i = r % 60;
      return (c <= 0 ? "+" : "-") + X(l, 2, "0") + ":" + X(i, 2, "0");
    }, m: function p(c, r) {
      if (c.date() < r.date()) return -p(r, c);
      var l = 12 * (r.year() - c.year()) + (r.month() - c.month()), i = c.clone().add(l, d), S = r - i < 0, y = c.clone().add(l + (S ? -1 : 1), d);
      return +(-(l + (r - i) / (S ? i - y : y - i)) || 0);
    }, a: function(p) {
      return p < 0 ? Math.ceil(p) || 0 : Math.floor(p);
    }, p: function(p) {
      return { M: d, y: v, w: f, d: h, D: E, h: g, m, s: u, ms: a, Q: b }[p] || String(p || "").toLowerCase().replace(/s$/, "");
    }, u: function(p) {
      return p === void 0;
    } }, de = "en", Y = {};
    Y[de] = Z;
    var ye = "$isDayjsObject", K = function(p) {
      return p instanceof we || !(!p || !p[ye]);
    }, le = function p(c, r, l) {
      var i;
      if (!c) return de;
      if (typeof c == "string") {
        var S = c.toLowerCase();
        Y[S] && (i = S), r && (Y[S] = r, i = S);
        var y = c.split("-");
        if (!i && y.length > 1) return p(y[0]);
      } else {
        var A = c.name;
        Y[A] = c, i = A;
      }
      return !l && i && (de = i), i || !l && de;
    }, C = function(p, c) {
      if (K(p)) return p.clone();
      var r = typeof c == "object" ? c : {};
      return r.date = p, r.args = arguments, new we(r);
    }, $ = Pe;
    $.l = le, $.i = K, $.w = function(p, c) {
      return C(p, { locale: c.$L, utc: c.$u, x: c.$x, $offset: c.$offset });
    };
    var we = function() {
      function p(r) {
        this.$L = le(r.locale, null, !0), this.parse(r), this.$x = this.$x || r.x || {}, this[ye] = !0;
      }
      var c = p.prototype;
      return c.parse = function(r) {
        this.$d = function(l) {
          var i = l.date, S = l.utc;
          if (i === null) return /* @__PURE__ */ new Date(NaN);
          if ($.u(i)) return /* @__PURE__ */ new Date();
          if (i instanceof Date) return new Date(i);
          if (typeof i == "string" && !/Z$/i.test(i)) {
            var y = i.match(O);
            if (y) {
              var A = y[2] - 1 || 0, w = (y[7] || "0").substring(0, 3);
              return S ? new Date(Date.UTC(y[1], A, y[3] || 1, y[4] || 0, y[5] || 0, y[6] || 0, w)) : new Date(y[1], A, y[3] || 1, y[4] || 0, y[5] || 0, y[6] || 0, w);
            }
          }
          return new Date(i);
        }(r), this.init();
      }, c.init = function() {
        var r = this.$d;
        this.$y = r.getFullYear(), this.$M = r.getMonth(), this.$D = r.getDate(), this.$W = r.getDay(), this.$H = r.getHours(), this.$m = r.getMinutes(), this.$s = r.getSeconds(), this.$ms = r.getMilliseconds();
      }, c.$utils = function() {
        return $;
      }, c.isValid = function() {
        return this.$d.toString() !== N;
      }, c.isSame = function(r, l) {
        var i = C(r);
        return this.startOf(l) <= i && i <= this.endOf(l);
      }, c.isAfter = function(r, l) {
        return C(r) < this.startOf(l);
      }, c.isBefore = function(r, l) {
        return this.endOf(l) < C(r);
      }, c.$g = function(r, l, i) {
        return $.u(r) ? this[l] : this.set(i, r);
      }, c.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, c.valueOf = function() {
        return this.$d.getTime();
      }, c.startOf = function(r, l) {
        var i = this, S = !!$.u(l) || l, y = $.p(r), A = function(ae, z) {
          var ee = $.w(i.$u ? Date.UTC(i.$y, z, ae) : new Date(i.$y, z, ae), i);
          return S ? ee : ee.endOf(h);
        }, w = function(ae, z) {
          return $.w(i.toDate()[ae].apply(i.toDate("s"), (S ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(z)), i);
        }, T = this.$W, D = this.$M, j = this.$D, me = "set" + (this.$u ? "UTC" : "");
        switch (y) {
          case v:
            return S ? A(1, 0) : A(31, 11);
          case d:
            return S ? A(1, D) : A(0, D + 1);
          case f:
            var oe = this.$locale().weekStart || 0, he = (T < oe ? T + 7 : T) - oe;
            return A(S ? j - he : j + (6 - he), D);
          case h:
          case E:
            return w(me + "Hours", 0);
          case g:
            return w(me + "Minutes", 1);
          case m:
            return w(me + "Seconds", 2);
          case u:
            return w(me + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, c.endOf = function(r) {
        return this.startOf(r, !1);
      }, c.$set = function(r, l) {
        var i, S = $.p(r), y = "set" + (this.$u ? "UTC" : ""), A = (i = {}, i[h] = y + "Date", i[E] = y + "Date", i[d] = y + "Month", i[v] = y + "FullYear", i[g] = y + "Hours", i[m] = y + "Minutes", i[u] = y + "Seconds", i[a] = y + "Milliseconds", i)[S], w = S === h ? this.$D + (l - this.$W) : l;
        if (S === d || S === v) {
          var T = this.clone().set(E, 1);
          T.$d[A](w), T.init(), this.$d = T.set(E, Math.min(this.$D, T.daysInMonth())).$d;
        } else A && this.$d[A](w);
        return this.init(), this;
      }, c.set = function(r, l) {
        return this.clone().$set(r, l);
      }, c.get = function(r) {
        return this[$.p(r)]();
      }, c.add = function(r, l) {
        var i, S = this;
        r = Number(r);
        var y = $.p(l), A = function(D) {
          var j = C(S);
          return $.w(j.date(j.date() + Math.round(D * r)), S);
        };
        if (y === d) return this.set(d, this.$M + r);
        if (y === v) return this.set(v, this.$y + r);
        if (y === h) return A(1);
        if (y === f) return A(7);
        var w = (i = {}, i[m] = n, i[g] = o, i[u] = s, i)[y] || 1, T = this.$d.getTime() + r * w;
        return $.w(T, this);
      }, c.subtract = function(r, l) {
        return this.add(-1 * r, l);
      }, c.format = function(r) {
        var l = this, i = this.$locale();
        if (!this.isValid()) return i.invalidDate || N;
        var S = r || "YYYY-MM-DDTHH:mm:ssZ", y = $.z(this), A = this.$H, w = this.$m, T = this.$M, D = i.weekdays, j = i.months, me = i.meridiem, oe = function(z, ee, ve, Ce) {
          return z && (z[ee] || z(l, S)) || ve[ee].slice(0, Ce);
        }, he = function(z) {
          return $.s(A % 12 || 12, z, "0");
        }, ae = me || function(z, ee, ve) {
          var Ce = z < 12 ? "AM" : "PM";
          return ve ? Ce.toLowerCase() : Ce;
        };
        return S.replace(L, function(z, ee) {
          return ee || function(ve) {
            switch (ve) {
              case "YY":
                return String(l.$y).slice(-2);
              case "YYYY":
                return $.s(l.$y, 4, "0");
              case "M":
                return T + 1;
              case "MM":
                return $.s(T + 1, 2, "0");
              case "MMM":
                return oe(i.monthsShort, T, j, 3);
              case "MMMM":
                return oe(j, T);
              case "D":
                return l.$D;
              case "DD":
                return $.s(l.$D, 2, "0");
              case "d":
                return String(l.$W);
              case "dd":
                return oe(i.weekdaysMin, l.$W, D, 2);
              case "ddd":
                return oe(i.weekdaysShort, l.$W, D, 3);
              case "dddd":
                return D[l.$W];
              case "H":
                return String(A);
              case "HH":
                return $.s(A, 2, "0");
              case "h":
                return he(1);
              case "hh":
                return he(2);
              case "a":
                return ae(A, w, !0);
              case "A":
                return ae(A, w, !1);
              case "m":
                return String(w);
              case "mm":
                return $.s(w, 2, "0");
              case "s":
                return String(l.$s);
              case "ss":
                return $.s(l.$s, 2, "0");
              case "SSS":
                return $.s(l.$ms, 3, "0");
              case "Z":
                return y;
            }
            return null;
          }(z) || y.replace(":", "");
        });
      }, c.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, c.diff = function(r, l, i) {
        var S, y = this, A = $.p(l), w = C(r), T = (w.utcOffset() - this.utcOffset()) * n, D = this - w, j = function() {
          return $.m(y, w);
        };
        switch (A) {
          case v:
            S = j() / 12;
            break;
          case d:
            S = j();
            break;
          case b:
            S = j() / 3;
            break;
          case f:
            S = (D - T) / 6048e5;
            break;
          case h:
            S = (D - T) / 864e5;
            break;
          case g:
            S = D / o;
            break;
          case m:
            S = D / n;
            break;
          case u:
            S = D / s;
            break;
          default:
            S = D;
        }
        return i ? S : $.a(S);
      }, c.daysInMonth = function() {
        return this.endOf(d).$D;
      }, c.$locale = function() {
        return Y[this.$L];
      }, c.locale = function(r, l) {
        if (!r) return this.$L;
        var i = this.clone(), S = le(r, l, !0);
        return S && (i.$L = S), i;
      }, c.clone = function() {
        return $.w(this.$d, this);
      }, c.toDate = function() {
        return new Date(this.valueOf());
      }, c.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, c.toISOString = function() {
        return this.$d.toISOString();
      }, c.toString = function() {
        return this.$d.toUTCString();
      }, p;
    }(), je = we.prototype;
    return C.prototype = je, [["$ms", a], ["$s", u], ["$m", m], ["$H", g], ["$W", h], ["$M", d], ["$y", v], ["$D", E]].forEach(function(p) {
      je[p[1]] = function(c) {
        return this.$g(c, p[0], p[1]);
      };
    }), C.extend = function(p, c) {
      return p.$i || (p(c, we, C), p.$i = !0), C;
    }, C.locale = le, C.isDayjs = K, C.unix = function(p) {
      return C(1e3 * p);
    }, C.en = Y[de], C.Ls = Y, C.p = {}, C;
  });
})(Tt);
var wn = Tt.exports;
const Ve = /* @__PURE__ */ wt(wn), Uc = 100, Hc = [
  "premium",
  "pro",
  "enterprise"
], Cn = (e) => {
  var t;
  return !!e && ((t = e.scheduledChange) == null ? void 0 : t.action) === "cancel";
}, Gc = (e, t, s) => {
  var o;
  const n = (o = e == null ? void 0 : e.planConfig) == null ? void 0 : o.paddleProductName;
  return !!n && (Array.isArray(t) ? t.includes(n) : n === t) && (!s || Ot(e));
}, Qc = (e) => !e || e === "free", _n = (e) => {
  var t;
  return !e || ((t = e.planConfig) == null ? void 0 : t.paddleProductName) === "free";
}, Ot = (e) => !!e && e.status === "trialing", In = (e) => Ot(e) && !(e != null && e.nextBilledAt) && !Cn(e), Yc = (e) => _n(e) || In(e), ze = (e, t) => {
  var s, n;
  return e ? ((s = e.planConfigOverrides) == null ? void 0 : s[t]) || ((n = e.planConfig) == null ? void 0 : n[t]) : void 0;
}, Tn = (e, t) => {
  var s;
  return !!((s = ze(e, t)) != null && s.enabled);
}, Vc = (e, t) => {
  var s;
  return !((s = ze(e, t)) != null && s.enabled);
}, On = (e) => e && e.status === "trialing" && e.trialEndsAt ? Math.max(
  Math.ceil(Ve.unix(e.trialEndsAt).diff(Ve(), "hour") / 24),
  0
) : 0, Wc = (e) => !(e != null && e.id) || On(e) > 0, kn = (e, t, s) => {
  var a, u, m, g;
  if (!((a = e == null ? void 0 : e.prices) != null && a.length)) return;
  let n, o;
  for (let h = 0; h < e.prices.length; h++) {
    const f = e.prices[h];
    if (((u = f.billingCycle) == null ? void 0 : u.interval) === t && f.status !== "archived" && (s === void 0 || !!((m = f.trialPeriod) != null && m.frequency) === s) && (o = f, Ht((g = f.customData) == null ? void 0 : g.default))) {
      n = f;
      break;
    }
  }
  return n || o;
}, xc = (e, t, s) => {
  var n;
  if (e)
    for (let o = 0; o < e.length; o++) {
      if (((n = e[o].config) == null ? void 0 : n.paddleProductName) !== t) continue;
      const a = kn(e[o], s);
      if (a) return a;
    }
}, Zc = (e) => {
  var t;
  return e ? ["error", "action_required"].includes((t = e.lastPayment) == null ? void 0 : t.status) : !1;
}, Jc = (e, t) => e == null ? void 0 : e.find((s) => s.id === t), Xc = (e, t) => e == null ? void 0 : e.find((s) => s.config.paddleProductName === t), Be = (e) => ue(e).active.id, F = R(
  Be,
  It,
  (e, t) => e ? t[e] : void 0
), eu = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.sessionRecurrenceID;
}, tu = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.sessionTitle;
}, su = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.about;
}, nu = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.cover;
}, ou = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.startTimestamp;
}, au = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.endTimestamp;
}, Dn = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.subscriptionPlan;
}, ru = (e) => {
  var t, s;
  return (s = ze((t = F(e)) == null ? void 0 : t.subscriptionPlan, "stream")) == null ? void 0 : s.quality;
}, iu = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.sessionState;
}, cu = (e) => {
  const t = F(e);
  return t ? t.branchURL || t.publicURL : void 0;
}, Me = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.hosts;
}, Mn = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.sessionSettings;
}, kt = R(
  [F],
  (e) => e == null ? void 0 : e.primaryHostUserID
), uu = R(
  [kt, ne],
  (e, t) => !!e && e === t
), du = R(
  [kt, Me],
  (e, t) => t == null ? void 0 : t.find((s) => s.id === e)
), lu = R(
  [Me, ne],
  (e = [], t) => !!t && e.some((s) => s.id === t)
), mu = (e) => R(
  [Me],
  (t = []) => !!e && t.some((s) => s.id === e)
), Pn = (e) => ue(e).active.consentToBroadcast, gu = R(
  [Me],
  (e) => (e == null ? void 0 : e.length) || 0
), Dt = (e) => {
  var t;
  return (t = F(e)) == null ? void 0 : t.stateUpdatedAt;
}, pu = R(
  [Dt],
  (e) => e ? sn(e) : void 0
), fu = R(
  [Dt],
  (e) => e ? nn(e) : void 0
), Rn = (e) => ue(e).current.id, Su = (e) => {
  var t;
  return (t = q(e)) == null ? void 0 : t.sessionRecurrenceID;
}, q = R(
  Rn,
  It,
  (e, t) => e ? t[e] : void 0
), yu = (e) => {
  var t;
  return (t = q(e)) == null ? void 0 : t.sessionTitle;
}, hu = (e) => {
  var t;
  return (t = q(e)) == null ? void 0 : t.about;
}, vu = (e) => {
  var t;
  return (t = q(e)) == null ? void 0 : t.cover;
}, bu = (e) => {
  const t = q(e);
  return t ? t.branchURL || t.publicURL : void 0;
}, $u = (e) => {
  var t;
  return (t = q(e)) == null ? void 0 : t.subscriptionPlan;
}, Au = (e) => {
  var t;
  return (t = q(e)) == null ? void 0 : t.sessionSettings;
}, Eu = (e) => {
  var t;
  return (t = q(e)) == null ? void 0 : t.startTimestamp;
}, wu = (e) => {
  var t;
  return (t = q(e)) == null ? void 0 : t.endTimestamp;
}, Cu = (e) => {
  var t;
  return (t = q(e)) == null ? void 0 : t.sessionState;
}, Fe = (e) => {
  var t;
  return (t = q(e)) == null ? void 0 : t.hosts;
}, _u = R(
  [Fe, ne],
  (e = [], t) => !!t && e.some((s) => s.id === t)
), Mt = R(
  [q],
  (e) => e == null ? void 0 : e.primaryHostUserID
), Iu = R(
  [Mt, ne],
  (e, t) => !!e && e === t
), Tu = R(
  [Mt, Fe],
  (e, t) => t == null ? void 0 : t.find((s) => s.id === e)
), Ou = R(
  [Fe],
  (e) => (e == null ? void 0 : e.length) || 0
), ku = "sessions", Du = (e) => e.role === xe.Member, Mu = (e) => e.role === xe.Owner, Nn = ({
  options: e
}) => ({
  ...e,
  mutationFn: Rs.requestDelete
}), zn = "get-session", Bn = (e) => {
  const t = B(), s = _(fe), n = _(Gt) === "loading", o = ke(), a = `${zn}-${e.sessionId}-${e.recurrenceId}-${s}`, u = tt(
    e.sessionId || "",
    e.recurrenceId,
    s
  ), { onPatch: m, onPatched: g, onFetched: h } = e;
  return x(() => () => {
    Se.close(a);
  }, [a]), V(
    Xt({
      ...e,
      // disable until auth is loading
      sessionId: n ? void 0 : e.sessionId,
      userId: s,
      onFetched: (f) => {
        h && h(f), o && (f != null && f.data) && o(xs(f.data));
      },
      onPatch: (f) => {
        m && m(f), t.setQueryData(
          u,
          (d) => {
            try {
              if (!d) return;
              const b = Qe(d), v = es(b, Qe(f)) ?? d;
              return g && g(d, v), o && (v != null && v.data) && o(Js(v.data)), v;
            } catch (b) {
              return I.report(b), d;
            }
          }
        );
      },
      onError: async (f, d) => {
        var b, v, E;
        if (typeof d == "object" && (d != null && d.error)) {
          const N = (v = (b = d == null ? void 0 : d.error) == null ? void 0 : b.details) == null ? void 0 : v.errCode;
          if (N === Bt || N === Ft || N === Lt || N === Kt)
            throw (E = U.logger) == null || E.logWith(3, "Reset sessions state", { code: N }), o == null || o(Zs(void 0)), d;
        }
      },
      params: {
        key: a
      }
    })
  );
}, Pu = ({
  sessionId: e,
  recurrenceId: t
}) => {
  const s = _(fe), n = G(
    () => e && s ? tt(e, t, s) : ce,
    [t, e, s]
  );
  return ie(
    n
  );
}, Fn = "get-session-request-access", Ln = (e) => {
  const t = _(fe), s = B(), n = `${Fn}-${e.sessionId}-${e.recurrenceId}-${t}`;
  return x(() => () => {
    Se.close(n);
  }, [n]), V(
    ts({
      ...e,
      userId: t,
      queryClient: s,
      params: {
        key: n
      }
    })
  );
}, Ru = ({
  sessionId: e,
  recurrenceId: t
}) => {
  const s = _(fe), n = G(
    () => s && e ? ss(s, e, t) : ce,
    [t, e, s]
  );
  return ie(n);
}, Kn = (e) => {
  var a, u;
  const t = B(), { sessionId: s } = e, n = Ln(e), o = ((u = (a = n.data) == null ? void 0 : a.data) == null ? void 0 : u.restrictionStatus) === Te.GRANTED;
  return x(() => {
    var m;
    !o || !s || ((m = U.logger) == null || m.log("Refetching session on granted access"), t.refetchQueries({
      queryKey: [ns, s],
      exact: !1
    }));
  }, [o, t, s]), [o, n];
}, Nu = (e) => {
  const { sessionId: t } = e, s = _(Qt), n = Bn(e), o = n.isError && // errorCode === ERROR_SESSION_ACCESS_UNAUTHORIZED_UNLOCKED &&
  s, [, a] = Kn({
    sessionId: o ? t : void 0
  }), u = Re(!0);
  return (n.isSuccess || n.isError) && (u.current = !1), n.isFirstLoad = u.current && n.isLoading, [n, a];
}, zu = ({
  onSuccess: e
}) => {
  const { accessToken: t } = _(Je), s = ke();
  return k(
    os({
      accessToken: t,
      onSuccess: async (n) => {
        const { authToken: o, refreshToken: a } = Oe(n) || {};
        if (!o) return;
        const u = await Xe.setup({
          accessToken: o,
          refreshToken: a
        });
        u.isAuthenticated && (s(et(u)), e && e(n));
      },
      onError: (n) => {
        I.report(n);
      }
    })
  );
}, jn = (e) => k(
  as({
    onError: (t) => I.report(t),
    ...e
  })
), qn = (e, t) => k({
  mutationFn: () => P.hubspot.deleteBinding(e.id),
  onError: (s) => I.report(s),
  ...t
}), Un = (e, t) => H({
  ...rs({ id: e, ...t }),
  onError: I.report
}), Hn = (e, t) => H({
  queryKey: [is, e],
  queryFn: () => P.hubspot.getDealById(e),
  onError: I.report,
  ...W["1m"],
  ...t
}), Gn = (e, t, s, n) => H({
  queryKey: [
    cs,
    e,
    t,
    s
  ],
  queryFn: async () => await P.hubspot.listBindableRecordsAsOptions({
    params: {
      search: e,
      sessionId: t,
      sessionRecurrenceId: s
    }
  }),
  onError: I.report,
  ...n
}), Qn = (e, t, s) => H({
  queryKey: [us, e, t],
  queryFn: async () => await P.hubspot.getContacts(
    e,
    t
  ),
  onError: I.report,
  ...W["15s"],
  ...s
}), Yn = (e, t) => H({
  queryKey: [ds, e],
  queryFn: async () => await P.hubspot.getContactById(e),
  onError: I.report,
  ...W["1m"],
  ...t
}), Bu = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  useCreateBinding: jn,
  useDeleteBinding: qn,
  useGetCompanyById: Un,
  useGetContactById: Yn,
  useGetContacts: Qn,
  useGetDealById: Hn,
  useListBindableRecordsAsOptions: Gn
}, Symbol.toStringTag, { value: "Module" })), Le = (e) => [
  gs,
  e
], Vn = ({
  userId: e,
  enabled: t
}) => V({
  queryKey: t ? Le(e) : ce,
  queryFn: () => P.getConnections({ throwError: !0 }),
  enabled: t,
  ...ls["5m"],
  staleTime: 2500
  // use 2.5s because it can refetch in 3sec interval
}), Fu = (e) => {
  const t = G(() => Le(e), [e]);
  return ie(t);
}, Wn = (e) => {
  const t = B(), s = re(() => new Promise((a) => {
    setTimeout(async () => {
      await t.invalidateQueries({
        queryKey: Le(e)
      }), a(!0);
    }, 3e3);
  }), [t, e]), n = re(() => s(), [s]), o = re(() => s(), [s]);
  return {
    onConnect: n,
    onDisconnect: o
  };
}, Lu = ({
  isEnabled: e = !0
}) => {
  var Y, ye;
  const t = _(ne), s = !!t && e, {
    data: n,
    isSuccess: o,
    error: a,
    isLoading: u,
    refetch: m
  } = Vn({ userId: t, enabled: s }), g = Oe(n), h = U.storage.getParsed(
    qe
  ), f = (K) => U.storage.set(qe, K);
  G(() => {
    !g || !h || ut(
      h,
      g
    ) || f(void 0);
  }, [g, h]);
  const [d, b] = be(), [v, E] = be(), {
    data: N,
    isSuccess: O,
    isFetching: L,
    refetch: Z
  } = V({
    queryKey: [
      ms,
      v,
      d == null ? void 0 : d.providerName,
      d == null ? void 0 : d.actionName
    ],
    queryFn: ({ queryKey: K }) => {
      const [, le, C = "", $ = ""] = K;
      return P.getAction(
        String(C),
        String($),
        {
          params: { aiFeedItemId: le }
        }
      );
    },
    enabled: !1
  }), X = (K) => {
    b(K), f(K), E(K.aiSessionFeedMessageId);
  };
  x(() => {
    d && Z();
  }, [d, Z]);
  const { onConnect: Pe } = Wn(t);
  return {
    providerCategories: g,
    errorProviderCategories: a,
    isFetchingProviderCategories: u,
    isSuccessProviderCategories: o,
    selectedIntegration: d,
    lastUsedIntegration: h,
    onClickProvider: X,
    onClickConnect: () => Pe(),
    blockKitSchema: !!N && O && ((ye = (Y = N.data) == null ? void 0 : Y.data) == null ? void 0 : ye.schema) || void 0,
    isFetchingGetAction: L,
    selectedSessionFeedMessageId: v,
    refetchProviderCategories: m
  };
}, xn = (e) => k(
  ps({
    ...e,
    options: {
      retry: 10,
      retryDelay: (t) => Math.min(t > 1 ? 2 ** t * 1e3 : 1e3, 30 * 1e3)
    }
  })
), Ku = ({
  isConnected: e,
  isLocal: t,
  hasJoined: s,
  params: n
}) => {
  const o = _(Be) || "", a = _(Pn), { mutate: u } = xn({
    sessionId: o,
    params: n
  });
  return x(() => {
    t && e && a && !s && u({ isLoaded: !0 });
  }, [
    a,
    s,
    e,
    u,
    t
  ]), null;
}, We = fs.viewerAccess, ju = ({
  request: e,
  options: t
}) => {
  const s = B(), n = _(ne), { id: o, sessionID: a, sessionRecurrenceID: u } = e, m = k({
    mutationFn: (d) => ot.update({
      data: d
    }),
    ...t,
    onSuccess: (d, b, v) => {
      var E;
      (E = t == null ? void 0 : t.onSuccess) == null || E.call(t, d, b, v), d && b.restrictionStatus === Te.GRANTED && We.add({
        client: s,
        data: [e]
      });
    },
    onError: (d, b, v) => {
      var E;
      n && Fs(d, jt) && We.removeRequest({
        client: s,
        sessionId: a,
        sessionRecurrenceId: u,
        id: o
      }), (E = t == null ? void 0 : t.onError) == null || E.call(t, d, b, v);
    }
  }), { mutate: g } = m;
  return { mutation: m, approveClick: () => g({
    restrictionStatus: Te.GRANTED,
    id: `${o}`
  }), ignoreClick: () => g({
    restrictionStatus: Te.DENIED,
    id: `${o}`
  }) };
};
let Zn = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict", ge = (e = 21) => {
  let t = "", s = e;
  for (; s--; )
    t += Zn[Math.random() * 64 | 0];
  return t;
};
const Pt = (e, t) => {
  if (e) {
    for (let s = e.length - 1; s >= 0; s--)
      if (e[s].role === t) return e[s];
  }
}, qu = (e) => Pt(e, "user"), Uu = (e) => Pt(e, "ai"), Hu = (e) => e != null && e.length ? e[e.length - 1] : void 0, Rt = ({
  id: e,
  event: t,
  client: s,
  throwOnError: n
}) => {
  const { data: o, type: a } = t;
  switch (a) {
    case "stream_started":
      J.addMessage({
        id: e,
        client: s,
        data: {
          id: o.messageId,
          role: "ai",
          content: "",
          todo: "",
          progress: "",
          isStreaming: !0,
          isComplete: !1,
          feedback: 0
        }
      });
      break;
    case "text_generation_delta":
      J.updateLastAiMessage({
        id: e,
        client: s,
        data: { id: o.messageId, content: o.content }
      });
      break;
    case "tool_use_detected":
      J.updateLastAiMessage({
        id: e,
        client: s,
        data: {
          id: o.messageId,
          todo: o.content
        }
      });
      break;
    case "tool_use_submitted":
      J.updateLastAiMessage({
        id: e,
        client: s,
        data: {
          id: o.messageId,
          progress: o.content,
          sources: o.sources
        }
      });
      break;
    case "sources_updated":
      J.updateLastAiMessage({
        id: e,
        client: s,
        data: {
          id: o.messageId,
          sources: o.sources
        }
      });
      break;
    case "stream_failed":
    case "stream_ended":
      if (J.updateLastAiMessage({
        id: e,
        client: s,
        data: {
          id: o.messageId,
          isStreaming: !1,
          isComplete: !0,
          todo: ""
        }
      }), a === "stream_failed" && n) throw t;
      break;
  }
}, Gu = (e) => {
  const t = _(fe);
  return G(
    () => st(e, t || ""),
    [e, t]
  );
}, Jn = (e) => {
  const t = _(fe);
  return G(() => {
    const s = st(e, t || "");
    return [t ? { queryKey: s } : { queryKey: s, enabled: !1 }, t];
  }, [e, t]);
}, Ke = (e) => {
  I.report(e);
}, Nt = (e, t, s) => k({
  mutationFn: (n) => De.ai(e, { data: n }, s),
  onError: Ke,
  ...t
}), Xn = (e) => k({
  mutationFn: ({ messageId: t, feedback: s }) => De.feedback({
    data: { messageId: t, feedback: s }
  }),
  onError: Ke,
  ...e
}), Qu = (e) => k({
  mutationFn: ({ threadId: t }) => De.stop({
    data: { threadId: t }
  }),
  onError: Ke,
  ...e
}), Yu = (e, t) => {
  const [s] = Jn([
    Ss,
    e
  ]);
  return V({
    queryFn: () => De.suggestions(e),
    ...W["30m"],
    ...t,
    ...s
  });
}, Vu = ({
  threadId: e,
  onError: t
}) => {
  var N;
  const s = _(ne), n = B(), o = Re(), [a, u] = be(e), m = Re(ge()), g = !!e, h = V(
    g ? ys({
      id: e || m.current
    }) : (
      // fallback to empty result for new threads without threadId
      {
        queryKey: hs(
          e || m.current
        ),
        queryFn: () => ({
          code: 200,
          success: !0,
          data: void 0,
          total: 0
        }),
        ...W["1h"]
      }
    )
  );
  x(() => {
    e || (m.current = ge()), u(e);
  }, [e]);
  const f = Nt(
    (O) => {
      var X;
      (X = U.logger) == null || X.service(qt, 5, "Received event", O);
      const L = Ze(O.data);
      if (!(L != null && L.data)) return;
      const Z = L.data.threadId;
      Z && a !== Z && u(L.data.threadId), Rt({
        id: e || m.current,
        event: L,
        client: n,
        throwOnError: !0
      });
    },
    {
      onError: (O) => {
        I.report(O), t == null || t(O.message);
      },
      onSuccess: () => {
        s && setTimeout(() => {
          n.invalidateQueries({
            queryKey: vs(s)
          });
        }, 100);
      }
    }
  ), { mutate: d } = f, b = re(() => {
    u(void 0);
    const O = ge();
    return m.current = O, O;
  }, []), v = re(
    async (O, L) => {
      if (!O) return;
      const Z = L ? b() : e || m.current;
      J.addMessage({
        id: Z,
        client: n,
        data: {
          id: ge(),
          role: "user",
          content: O,
          isComplete: !0,
          isStreaming: !1,
          sources: void 0,
          feedback: 0
        }
      }), o.current = O, d({
        query: O,
        threadId: L ? void 0 : a,
        requestId: ge()
      });
    },
    [b, e, n, d, a]
  ), E = re(() => {
    o.current && d({
      query: o.current,
      threadId: a,
      requestId: ge(),
      retry: !0
    });
  }, [d, a]);
  return {
    isQueryEnabled: g,
    cacheId: e || m.current,
    threadId: a,
    messagesQuery: h,
    messages: (N = bs(h)) == null ? void 0 : N.messages,
    onNewThread: b,
    submitQuery: v,
    askMutation: f,
    onRetry: E
  };
}, Wu = (e, t, s) => {
  const n = B(), { mutate: o, isPending: a } = Nt((m) => {
    try {
      const g = Ze(m.data);
      if (!(g != null && g.data)) throw new Error("Invalid event data");
      Rt({
        id: e,
        event: g,
        client: n,
        throwOnError: !0
      });
    } catch (g) {
      I.report(g);
    }
  });
  return { refreshLastResponse: re(() => {
    s && (J.removeMessage({
      id: e,
      client: n,
      messageId: s.id
    }), o({
      messageId: s.id,
      refresh: !0,
      threadId: t,
      requestId: Bs()
    }));
  }, [s, e, n, o, t]), isPending: a };
}, xu = (e, t) => {
  const s = B(), { mutate: n, isPending: o } = Xn({
    onSuccess: (h) => {
      const { message: f } = Ns(h) || {};
      f && J.updateMessage({
        id: e,
        client: s,
        data: f
      });
    }
  }), [a, u] = be();
  return {
    thumbsUp: () => {
      u(1), n({ messageId: t.id, feedback: 1 });
    },
    thumbsDown: () => {
      u(2), n({ messageId: t.id, feedback: 2 });
    },
    isPending: o ? a : 0
  };
}, eo = (e) => {
  const t = _(Dn);
  return [Tn(t, e), t];
}, to = () => {
  const e = _(Yt), { summaAI: t, enableRecording: s } = _(Mn) || {}, [n] = eo("offTheRecord");
  return {
    isEnabled: !!(t || s) && !e && n,
    aiEnabled: t,
    recordingEnabled: s
  };
}, Zu = ({
  onError: e,
  onSuccess: t
}) => {
  const s = _(Be), n = to(), { mutate: o, isPending: a } = k({
    mutationFn: (m) => m ? Ge.enableOffTheRecord({
      data: { sessionID: s }
    }) : Ge.disableOffTheRecord({
      data: { sessionID: s }
    }),
    onSuccess: (m, g) => {
      t && t(g);
    },
    onError: (m, g) => {
      var h;
      (h = U.reportError) == null || h.call(U, m), e && e(g);
    }
  });
  return {
    ...n,
    onToggle: (m) => {
      o(!!m);
    },
    isInProgress: a
  };
}, Ju = (e) => k(Nn(e)), so = (e) => k({
  mutationFn: ({
    sessionId: t,
    sessionRecurrenceId: s,
    record: { id: n, type: o }
  }) => P.salesforce.createBinding({
    data: {
      sessionId: t,
      sessionRecurrenceId: s,
      record: {
        id: n,
        type: o
      }
    }
  }),
  onError: (t) => {
    I.report(t);
  },
  ...e
}), no = (e, t) => k({
  mutationFn: () => P.salesforce.deleteBinding(e.id),
  onError: (s) => {
    I.report(s);
  },
  ...t
}), oo = (e, t) => H({
  queryKey: [
    Ee,
    $s,
    e
  ],
  queryFn: async () => {
    var n, o;
    const s = await P.salesforce.getAccountById(e);
    return (o = (n = s.data) == null ? void 0 : n.data) != null && o.Name && (s.data.data.Name = Ae(s.data.data.Name)), s;
  },
  onError: (s) => {
    I.report(s);
  },
  ...W["1m"],
  ...t
}), ao = (e, t) => H({
  queryKey: [
    Ee,
    As,
    e
  ],
  queryFn: async () => {
    var n, o;
    const s = await P.salesforce.getOpportunityById(e);
    return (o = (n = s.data) == null ? void 0 : n.data) != null && o.Name && (s.data.data.Name = Ae(
      s.data.data.Name
    )), s;
  },
  onError: (s) => {
    I.report(s);
  },
  ...W["1m"],
  ...t
}), ro = (e, t, s, n) => H({
  queryKey: [
    Ee,
    Es,
    e,
    t,
    s
  ],
  queryFn: async () => {
    var a;
    const o = await P.salesforce.listBindableRecordsAsOptions({
      params: {
        search: e,
        sessionId: t,
        sessionRecurrenceId: s
      }
    });
    return (a = o.data) != null && a.data && (o.data.data = o.data.data.map((u) => (u.name && (u.name = Ae(u.name)), u))), o;
  },
  onError: (o) => {
    I.report(o);
  },
  ...n
}), io = (e) => k({
  mutationFn: ({
    firstName: t,
    lastName: s,
    email: n
  }) => P.salesforce.createContact({
    firstName: t,
    lastName: s,
    email: n
  }),
  onError: (t) => {
    I.report(t);
  },
  ...e
}), co = (e, t, s) => H({
  queryKey: [
    Ee,
    ws,
    e,
    t
  ],
  queryFn: async () => {
    var o;
    const n = await P.salesforce.getContacts(
      e,
      t
    );
    return (o = n.data) != null && o.data && (n.data.data = n.data.data.map((a) => (a.Name && (a.Name = Ae(a.Name)), a))), n;
  },
  onError: (n) => {
    I.report(n);
  },
  ...W["15s"],
  ...s
}), uo = (e, t) => H({
  queryKey: [
    Ee,
    Cs,
    e
  ],
  queryFn: async () => {
    var n, o;
    const s = await P.salesforce.getLeadById(e);
    return (o = (n = s.data) == null ? void 0 : n.data) != null && o.Name && (s.data.data.Name = Ae(s.data.data.Name)), s;
  },
  onError: (s) => {
    I.report(s);
  },
  ...W["1m"],
  ...t
}), Xu = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  useCreateBinding: so,
  useCreateContact: io,
  useDeleteBinding: no,
  useGetAccountById: oo,
  useGetContacts: co,
  useGetLeadById: uo,
  useGetOpportunityById: ao,
  useListBindableRecordsAsOptions: ro
}, Symbol.toStringTag, { value: "Module" })), lo = "get-session-ai-feed", ed = (e) => {
  const t = B(), s = `${lo}-${e.sessionId}-${e.recurrenceId}`;
  return x(() => () => {
    Se.close(s);
  }, [s]), V(
    _s({
      ...e,
      queryClient: t,
      params: {
        key: s
      }
    })
  );
}, td = ({
  sessionId: e,
  recurrenceId: t
}) => {
  const s = G(
    () => e ? Is(e, t) : ce,
    [t, e]
  );
  return ie(
    s
  );
}, mo = "get-session-pending-access", sd = (e) => {
  const t = B(), s = `${mo}-${e.sessionId}-${e.recurrenceId}`;
  return x(() => () => {
    Se.close(s);
  }, [s]), V(
    Ts({
      ...e,
      queryClient: t,
      params: {
        key: s
      }
    })
  );
}, nd = ({
  sessionId: e,
  recurrenceId: t
}) => {
  const s = G(
    () => e ? Os(e, t) : ce,
    [t, e]
  );
  return ie(s);
}, Ie = [nt], od = ({
  sessions: e,
  enabled: t = !0
}) => {
  const s = _(ne), n = B(), [o, a] = be(
    n.getQueryData(Ie) || {}
  ), u = G(() => {
    if (!e || !s) return;
    const h = n.getQueryData(Ie), f = e.reduce(
      (d, b) => (b.accessStatus === "locked" && (!h || !h[Ue([b.sessionID, b.sessionRecurrenceID])]) && b.primaryHostUserID === s && d.push({
        id: b.sessionID,
        recurrenceID: b.sessionRecurrenceID
      }), d),
      []
    );
    return f.length > 0 ? f : void 0;
  }, [n, s, e]), m = t !== !1 && !!u;
  return { query: H({
    queryKey: m ? [nt, u] : ce,
    queryFn: u ? () => ot.getInReviewViewerAccessStats({
      data: {
        sessions: u
      }
    }) : () => null,
    enabled: m,
    onSuccess: (h) => {
      const { data: f } = (h == null ? void 0 : h.data) || {};
      !f || !Array.isArray(f) || a((d) => {
        const b = f.reduce((v, E) => (E && (v[Ue([E.id, E.recurrenceID])] = E.count || 0), v), d);
        return n.setQueryData(Ie, b), n.setQueryDefaults(Ie, W["5m"]), b;
      });
    }
  }), rules: o };
}, go = "get-session-presence", ad = (e) => {
  const t = B(), s = `${go}-${e.sessionId}-${e.recurrenceId}`;
  return x(() => () => {
    Se.close(s);
  }, [s]), V(
    ks({
      ...e,
      queryClient: t,
      params: {
        key: s
      }
    })
  );
}, rd = ({
  sessionId: e,
  recurrenceId: t
}) => {
  const s = G(
    () => e ? Ds(e, t) : ce,
    [t, e]
  );
  return ie(
    s
  );
}, id = ({
  id: e,
  sessionId: t,
  recurrenceId: s
}, n) => {
  const o = B();
  return k({
    mutationFn: (a) => zs.updateFeed(e, {
      data: {
        ...a,
        sessionID: t,
        sessionRecurrenceID: s
      }
    }),
    ...n,
    onSuccess: (...a) => {
      var m, g;
      const u = (g = (m = a[0]) == null ? void 0 : m.data) == null ? void 0 : g.data;
      u && (Ms.update({
        client: o,
        data: u,
        sessionId: t,
        recurrenceId: s
      }), n != null && n.onSuccess && n.onSuccess(...a));
    }
  });
}, cd = (e) => {
  var n;
  const t = ke(), s = (n = e.options) == null ? void 0 : n.onSuccess;
  return k(
    Ps({
      ...e,
      options: {
        ...e.options,
        onSuccess: (o, a, u) => {
          var g;
          s && s(o, a, u);
          const m = (g = Oe(o)) == null ? void 0 : g.session;
          t && m && t(Xs(m));
        }
      }
    })
  );
}, ud = {
  auth: Vt,
  modal: Qs,
  navigation: Vs
}, po = "Agree", fo = "Celebrate", So = "Completed", yo = "Disagree", ho = "Funny", vo = "Hi", bo = "Love", $o = "Wow", Ao = {
  agree: po,
  celebrate: fo,
  completed: So,
  disagree: yo,
  funny: ho,
  hi: vo,
  love: bo,
  wow: $o
}, Eo = "Connect $t(_company) to other tools you use", wo = "Connect another account", Co = "Disconnect account", _o = "Connect more integrations", Io = "Streamline your work by connecting $t(_company) to other tools you use", To = "Active connections", Oo = "Integrated platform", ko = "Category", Do = "Compatibility", Mo = "Revoke access", Po = {
  ticketing: "Ticketing",
  ticketingDesc: "Effectively handle and track tasks that come out of your meetings.",
  messaging: "Messaging",
  messagingDesc: "Boosts team communication by sending real-time updates straight from your meetings.",
  scheduling: "Scheduling",
  schedulingDesc: "Easily schedule and create $t(_company) meeting links in with one click, synced with your calendar.",
  crm: "CRM",
  crmDesc: "Save time by keeping your CRM updated automatically."
}, Ro = "Insight", No = "Action item", zo = {
  "logged-ticket-private": "{{name}} logged this",
  view: "View",
  "view-ticket": "View ticket",
  meeting: "Meeting",
  "shared-private": "{{name}} shared this",
  "logged-ticket-public": "{{name}} logged this",
  "shared-public": "{{name}} logged this",
  "crated-meeting-public": "{{name}} has shared a meeting",
  "crated-meeting-private": "{{name}} created a meeting"
}, Bo = {
  label: "Google Calendar add-on",
  desc: "Schedule and invite others to Rumi meetings with one click, straight from your calendar"
}, Fo = {
  label: "Google Chrome extension",
  desc: "Quickly access & start Rumi meetings and easily add meeting links to Google Calendar"
}, Lo = {
  "unableToSendTo{integration}": "Unable to send to {{integration}}. Something unexpectedly went wrong. Please try again."
}, Ko = "Please reconnect your {{provider}}", jo = {
  connect_companyToOtherToolsYouUse: Eo,
  connectAnotherAccount: wo,
  disconnectAccount: Co,
  connectMoreIntegrations: _o,
  streamlineYourWorkByConnectingIntegrations: Io,
  activeConnections: To,
  integratedPlatform: Oo,
  category: ko,
  compatibility: Do,
  revokeAccess: Mo,
  "revokeAccessDescription{connection}": "Are you sure you want to revoke access for {{provider}} ({{connection}})",
  categories: Po,
  "catch-up": "Catch-up",
  insight: Ro,
  actionItem: No,
  link: zo,
  gCal: Bo,
  gChrome: Fo,
  errors: Lo,
  reconnectProvider: Ko
}, qo = {
  guestSkippedSelf: "{{name}} skipped themselves",
  hostSkippedGuest: "{{name}} was skipped",
  failedToSendMessage: "Failed to send message"
}, Uo = {
  session: qo
}, Ho = "Action timed out. Please check your internet connection and try again.", Go = {
  400: "This email address is not registered on $t(_company). Please make sure it is correct or sign up below.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)",
  2009: "Please check your email address or sign up"
}, Qo = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, Yo = {
  2033: "Successfully created session.",
  2064: "Successfully updated session."
}, Vo = {
  200: "Successfully created your first session.",
  201: "$t(responses.dashboardSession.200)"
}, Wo = {
  2084: "Session access request was already in approved state",
  2088: "You've already requested access to this session.",
  email: {
    200: "People added"
  },
  domain: {
    200: "Group added"
  }
}, xo = {
  200: "Great! We'll keep you up to date."
}, Zo = {
  400: "The verification code is incorrect. Please double check.",
  404: "$t(responses.default)",
  405: "The verification code is incorrect. Please double check.",
  500: "$t(responses.default)"
}, Jo = {
  500: "Sorry, something went wrong. Please try again.",
  avatar: {
    400: "Error saving avatar. Please try again."
  }
}, Xo = {
  default: "Sorry, something went wrong. Please try again.",
  timeout: Ho,
  login: Go,
  register: Qo,
  session: Yo,
  dashboardSession: Vo,
  sessionAccess: Wo,
  subscribe: xo,
  verify: Zo,
  user: Jo
}, ea = "Select meeting type", ta = "General", sa = "1-on-1", na = "All Hands", oa = "Interview", aa = "Planning Session", ra = "Catchup / Chat", ia = "Knowledge Sharing", ca = "Team Sync", ua = "Client Meeting", da = "Workshop", la = "Board Meeting", ma = "Technical Review", ga = "Crisis Management", pa = {
  selectMeetingType: ea,
  general_meeting: ta,
  one_on_one: sa,
  all_hands: na,
  interview: oa,
  planning_session: aa,
  catchup_coffee: ra,
  knowledge_sharing: ia,
  team_sync: ca,
  client_meeting: ua,
  workshop: da,
  board_meeting: la,
  technical_review: ma,
  crisis_management: ga
}, fa = `Here are the translations into Italian:

Agree - D'accordo`, Sa = "Celebrate - Festeggia", ya = "Completed - Completato", ha = "Disagree - Non d'accordo", va = "Funny - Divertente", ba = "Hi - Ciao", $a = "Love - Amore", Aa = 'Wow - Wow (Note: "Wow" is often used in Italian as an exclamation of surprise or amazement, much like in English)', Ea = {
  agree: fa,
  celebrate: Sa,
  completed: ya,
  disagree: ha,
  funny: va,
  hi: ba,
  love: $a,
  wow: Aa
}, wa = {
  "logged-ticket-private": "{{name}} ha registrato questo",
  view: "Visualizza",
  "view-ticket": "Visualizza ticket",
  meeting: "Incontro",
  "shared-private": "{{name}} ha condiviso questo",
  "logged-ticket-public": "{{name}} ha registrato questo",
  "shared-public": "{{name}} ha registrato questo",
  "crated-meeting-public": "{{name}} ha condiviso un incontro",
  "crated-meeting-private": "{{name}} ha creato un incontro"
}, Ca = {
  ticketing: "Emissione di biglietti",
  messaging: "Messaggistica"
}, _a = {
  link: wa,
  category: Ca
}, Ia = {
  guestSkippedSelf: "{{name}} si è saltato/a",
  hostSkippedGuest: "{{name}} è stato/a saltato/a",
  messageCode: {
    0: "⭐️ <span/> Stai facendo <em>benissimo!</em>",
    1: "👍 <span/> Ti <em>sentiamo</em> e vediamo",
    2: "☎️ <span/> Non possiamo <em>sentirti</em>",
    3: "🔴 <span/> Non possiamo <em>vederti</em>",
    4: "🔇 <span/> Per favore, <em>silenzia</em> il tuo microfono",
    5: "📢 <span/> Regola il tuo <em>audio</em>",
    6: "💡 <span/> Regola la tua <em>illuminazione</em>",
    7: "🔄 <span/> <em>Aggiorna</em> il tuo browser",
    8: "💪 <span/> Per favore, <em>rientra</em> nella coda",
    9: "⚠️ <span/>  Testa <em>camera e audio</em>",
    10: "⭐️ <span/> Sei stato/a <em>fantastico/a!</em>",
    11: "🎧 <span/> Prova a sistemare il tuo <em>audio</em>",
    12: "💡 <span/> Prova a sistemare la tua <em>illuminazione</em>",
    13: "🎯 <span/> <em>Invita persone</em> a unirsi",
    14: "⭐️ <span/> Sembri <em>fantastico/a!</em>",
    15: "👀 <span/> Controlla <em>il tuo telefono</em>",
    16: "🔴 <span/> Il tuo <em>video</em> ha problemi",
    17: "🎬 <span/> Sei il prossimo/a",
    18: "⏸ <span/> Ho <em>mess in pausa</em> la coda",
    19: "▶️ <span/> Devi <em>cliccare per riprendere</em>",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "Impossibile inviare il messaggio",
  botsAddedToQueue: "Bot aggiunti alla coda"
}, Ta = {
  session: Ia
}, Oa = "Azione scaduta. Si prega di controllare la propria connessione internet e riprovare.", ka = {
  400: "Questo indirizzo email non è registrato su $t(_company). Si prega di assicurarsi che sia corretto o di registrarsi qui sotto.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, Da = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, Ma = {
  2033: "Sessione creata con successo.",
  2064: "Sessione aggiornata con successo."
}, Pa = {
  200: "Creata con successo la tua prima sessione.",
  201: "$t(responses.dashboardSession.200)"
}, Ra = {
  2084: "La richiesta di accesso alla sessione era già in stato approvato",
  2088: "Hai già richiesto l'accesso a questa sessione.",
  email: {
    200: "Persone aggiunte"
  },
  domain: {
    200: "Gruppo aggiunto"
  }
}, Na = {
  200: "Ottimo! Ti terremo aggiornato."
}, za = {
  404: "$t(responses.default)",
  405: "Il codice di verifica non è corretto. Per favore, ricontrolla.",
  500: "$t(responses.default)"
}, Ba = {
  500: "Mi dispiace, si è verificato un errore. Per favore, riprova.",
  avatar: {
    400: "Errore nel salvataggio dell'avatar. Per favore, riprova."
  }
}, Fa = {
  default: "Mi dispiace, si è verificato un errore. Per favore, riprova.",
  timeout: Oa,
  login: ka,
  register: Da,
  session: Ma,
  dashboardSession: Pa,
  sessionAccess: Ra,
  subscribe: Na,
  verify: za,
  user: Ba
}, La = "Einverstanden", Ka = "Feiern", ja = "Abgeschlossen", qa = "Nicht einverstanden", Ua = "Lustig", Ha = "Hallo", Ga = "Liebe", Qa = "Wow", Ya = {
  agree: La,
  celebrate: Ka,
  completed: ja,
  disagree: qa,
  funny: Ua,
  hi: Ha,
  love: Ga,
  wow: Qa
}, Va = {
  "logged-ticket-private": "{{name}} hat dies protokolliert",
  view: "Ansehen",
  "view-ticket": "Ticket anzeigen",
  meeting: "Besprechung",
  "shared-private": "{{name}} hat dies geteilt",
  "logged-ticket-public": "{{name}} hat dies protokolliert",
  "shared-public": "{{name}} hat dies protokolliert",
  "crated-meeting-public": "{{name}} hat eine Besprechung geteilt",
  "crated-meeting-private": "{{name}} hat eine Besprechung erstellt"
}, Wa = {
  ticketing: "Ticketing",
  messaging: "Nachrichtenversand"
}, xa = {
  link: Va,
  category: Wa
}, Za = {
  guestSkippedSelf: "{{name}} hat sich selbst ausgelassen",
  hostSkippedGuest: "{{name}} wurde übersprungen",
  messageCode: {
    0: "⭐️ <span/> Du machst das <em>großartig!</em>",
    1: "👍 <span/> Wir <em>hören</em> & sehen dich",
    2: "☎️ <span/> Können dich nicht <em>hören</em>",
    3: "🔴 <span/> Können dich nicht <em>sehen</em>",
    4: "🔇 <span/> Bitte schalte dein Mikrofon <em>stumm</em>",
    5: "📢 <span/> Behebe dein <em>Audio</em>-Problem",
    6: "💡 <span/> Korrigiere deine <em>Beleuchtung</em>",
    7: "🔄 <span/> Aktualisiere deinen Browser <em>neu</em>",
    8: "💪 <span/> Bitte trete der Warteschlange <em>erneut bei</em>",
    9: "⚠️ <span/> Teste <em>Kamera & Ton</em>",
    10: "⭐️ <span/> Du warst <em>großartig!</em>",
    11: "🎧 <span/> Versuche, dein <em>Audio</em>-Problem zu beheben",
    12: "💡 <span/> Versuche, deine <em>Beleuchtung</em> zu verbessern",
    13: "🎯 <span/> Bitte <em>leute</em>, der Sitzung beizutreten",
    14: "⭐️ <span/> Sieht <em>gut aus!</em>",
    15: "👀 <span/> Überprüfe <em>dein Handy</em>",
    16: "🔴 <span/> Dein <em>Video</em> funktioniert nicht richtig",
    17: "🎬 <span/> Du bist als Nächster dran",
    18: "⏸ <span/> Ich habe die Warteschlange <em>angehalten</em>",
    19: "▶️ <span/> Du musst <em>weiter</em> klicken",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "Nachricht konnte nicht gesendet werden",
  botsAddedToQueue: "Bots wurden zur Warteschlange hinzugefügt"
}, Ja = {
  session: Za
}, Xa = "Aktion abgelaufen. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.", er = {
  400: "Diese E-Mail-Adresse ist auf $t(_company) nicht registriert. Bitte stellen Sie sicher, dass sie korrekt ist, oder melden Sie sich unten an.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, tr = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, sr = {
  2033: "Sitzung erfolgreich erstellt.",
  2064: "Sitzung erfolgreich aktualisiert."
}, nr = {
  200: "Ihre erste Sitzung wurde erfolgreich erstellt.",
  201: "$t(responses.dashboardSession.200)"
}, or = {
  2084: "Die Anfrage auf Zugang zur Sitzung war bereits im genehmigten Zustand",
  2088: "Sie haben bereits Zugang zu dieser Sitzung beantragt.",
  email: {
    200: "Personen hinzugefügt"
  },
  domain: {
    200: "Gruppe hinzugefügt"
  }
}, ar = {
  200: "Großartig! Wir halten Sie auf dem Laufenden."
}, rr = {
  404: "$t(responses.default)",
  405: "Der Verifizierungscode ist falsch. Bitte überprüfen Sie noch einmal.",
  500: "$t(responses.default)"
}, ir = {
  500: "Entschuldigung, etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.",
  avatar: {
    400: "Fehler beim Speichern des Avatars. Bitte versuchen Sie es erneut."
  }
}, cr = {
  default: "Entschuldigung, etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.",
  timeout: Xa,
  login: er,
  register: tr,
  session: sr,
  dashboardSession: nr,
  sessionAccess: or,
  subscribe: ar,
  verify: rr,
  user: ir
}, ur = "A continuación, la traducción solicitada de los textos al español:", dr = "Acuerdo", lr = "Celebrar", mr = "Completado", gr = "Desacuerdo", pr = "Divertido", fr = "Hola", Sr = "Amor", yr = {
  agree: ur,
  celebrate: dr,
  completed: lr,
  disagree: mr,
  funny: gr,
  hi: pr,
  love: fr,
  wow: Sr
}, hr = {
  "logged-ticket-private": "{{name}} registró esto",
  view: "Ver",
  "view-ticket": "Ver ticket",
  meeting: "Reunión",
  "shared-private": "{{name}} compartió esto",
  "logged-ticket-public": "{{name}} registró esto",
  "shared-public": "{{name}} registró esto",
  "crated-meeting-public": "{{name}} ha compartido una reunión",
  "crated-meeting-private": "{{name}} creó una reunión"
}, vr = {
  ticketing: "Gestión de tickets",
  messaging: "Mensajería"
}, br = {
  link: hr,
  category: vr
}, $r = {
  guestSkippedSelf: "{{name}} se ha saltado a sí mismo",
  hostSkippedGuest: "{{name}} fue saltado",
  messageCode: {
    0: "⭐️ <span/> Estás haciendo un <em>gran trabajo</em>!",
    1: "👍 <span/> Te <em>escuchamos</em> y te vemos",
    2: "☎️ <span/> No podemos <em>oirte</em>",
    3: "🔴 <span/> No podemos <em>verte</em>",
    4: "🔇 <span/> Por favor, <em>silencia</em> tu micrófono",
    5: "📢 <span/> Arregla tu <em>audio</em>",
    6: "💡 <span/> Arregla tu <em>iluminación</em>",
    7: "🔄 <span/> <em>Actualiza</em> tu navegador",
    8: "💪 <span/> Por favor, <em>vuelve a</em> la cola",
    9: "⚠️ <span/>  Prueba la <em>cámara y el sonido</em>",
    10: "⭐️ <span/> ¡Estuviste <em>genial</em>!",
    11: "🎧 <span/> Intenta arreglar tu <em>audio</em>",
    12: "💡 <span/> Intenta arreglar tu <em>iluminación</em>",
    13: "🎯 <span/> <em>Pide a la gente</em> que se una",
    14: "⭐️ <span/> Te ves <em>genial</em>",
    15: "👀 <span/> Revisa <em>tu teléfono</em>",
    16: "🔴 <span/> <em>Tu vídeo</em> tiene un problema",
    17: "🎬 <span/> Tú sigues",
    18: "⏸ <span/> <em>He pausado</em> la cola",
    19: "▶️ <span/> Necesitas <em>hacer clic en reanudar</em>",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "No se pudo enviar el mensaje",
  botsAddedToQueue: "Se agregaron bots a la cola"
}, Ar = {
  session: $r
}, Er = "Acción agotada por tiempo. Por favor, verifica tu conexión a internet e intenta de nuevo.", wr = {
  400: "Esta dirección de correo electrónico no está registrada en $t(_company). Por favor, asegúrate de que es correcta o regístrate a continuación.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, Cr = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, _r = {
  2033: "Sesión creada exitosamente.",
  2064: "Sesión actualizada exitosamente."
}, Ir = {
  200: "Has creado tu primera sesión exitosamente.",
  201: "$t(responses.dashboardSession.200)"
}, Tr = {
  2084: "La solicitud de acceso a la sesión ya estaba en estado aprobado",
  2088: "Ya has solicitado acceso a esta sesión.",
  email: {
    200: "Personas añadidas"
  },
  domain: {
    200: "Grupo añadido"
  }
}, Or = {
  200: "¡Genial! Te mantendremos informado."
}, kr = {
  404: "$t(responses.default)",
  405: "El código de verificación es incorrecto. Por favor, comprueba de nuevo.",
  500: "$t(responses.default)"
}, Dr = {
  500: "Lo sentimos, algo salió mal. Por favor, intenta de nuevo.",
  avatar: {
    400: "Error al guardar el avatar. Por favor, intenta de nuevo."
  }
}, Mr = {
  default: "Lo sentimos, algo salió mal. Por favor, intenta de nuevo.",
  timeout: Er,
  login: wr,
  register: Cr,
  session: _r,
  dashboardSession: Ir,
  sessionAccess: Tr,
  subscribe: Or,
  verify: kr,
  user: Dr
}, Pr = "Zgadzam się", Rr = "Świętować", Nr = "Ukończony", zr = "Nie zgadzam się", Br = "Śmieszny", Fr = "Cześć", Lr = "Kochać", Kr = "Wow", jr = {
  agree: Pr,
  celebrate: Rr,
  completed: Nr,
  disagree: zr,
  funny: Br,
  hi: Fr,
  love: Lr,
  wow: Kr
}, qr = {
  "logged-ticket-private": "{{name}} zalogował to",
  view: "Widok",
  "view-ticket": "Zobacz zgłoszenie",
  meeting: "Spotkanie",
  "shared-private": "{{name}} udostępnił to",
  "logged-ticket-public": "{{name}} zalogował to",
  "shared-public": "{{name}} zalogował to",
  "crated-meeting-public": "{{name}} udostępnił spotkanie",
  "crated-meeting-private": "{{name}} utworzył spotkanie"
}, Ur = {
  ticketing: "System zgłoszeń",
  messaging: "Wiadomości"
}, Hr = {
  link: qr,
  category: Ur
}, Gr = {
  guestSkippedSelf: "{{name}} pominął sam siebie",
  hostSkippedGuest: "{{name}} został pominięty",
  messageCode: {
    0: "⭐️ <span/> Robisz to <em>świetnie!</em>",
    1: "👍 <span/> <em>Słyszymy</em> Cię i widzimy",
    2: "☎️ <span/> Nie <em>słyszymy</em> Cię",
    3: "🔴 <span/> Nie <em>widzimy</em> Ciebie",
    4: "🔇 <span/> Proszę, <em>wycisz</em> się",
    5: "📢 <span/> Napraw swoje <em>dźwięki</em>",
    6: "💡 <span/> Napraw swoje <em>oświetlenie</em>",
    7: "🔄 <span/> Odśwież swoją <em>przeglądarkę</em>",
    8: "💪 <span/> Proszę, <em>dołącz</em> ponownie do kolejki",
    9: "⚠️ <span/> Przetestuj <em>kamerę i dźwięk</em>",
    10: "⭐️ <span/> Byłeś <em>świetny!</em>",
    11: "🎧 <span/> Spróbuj naprawić swój <em>dźwięk</em>",
    12: "💡 <span/> Spróbuj naprawić swoje <em>oświetlenie</em>",
    13: "🎯 <span/> <em>Zaproś ludzi</em> do dołączenia",
    14: "⭐️ <span/> Wyglądasz <em>świetnie!</em>",
    15: "👀 <span/> Sprawdź <em>swoją komórkę</em>",
    16: "🔴 <span/> <em>Twoje wideo</em> ma usterki",
    17: "🎬 <span/> Jesteś następny",
    18: "⏸ <span/> <em>Zatrzymałem</em> kolejkę",
    19: "▶️ <span/> Musisz <em>kliknąć wznowienie</em>",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "Nie udało się wysłać wiadomości",
  botsAddedToQueue: "Boty zostały dodane do kolejki"
}, Qr = {
  session: Gr
}, Yr = "Czas działania minął. Proszę sprawdzić swoje połączenie internetowe i spróbować ponownie.", Vr = {
  400: "Ten adres e-mail nie jest zarejestrowany w $t(_company). Upewnij się, że jest poprawny lub zarejestruj się poniżej.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, Wr = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, xr = {
  2033: "Pomyślnie utworzono sesję.",
  2064: "Pomyślnie zaktualizowano sesję."
}, Zr = {
  200: "Pomyślnie utworzono Twoją pierwszą sesję.",
  201: "$t(responses.dashboardSession.200)"
}, Jr = {
  2084: "Prośba o dostęp do sesji została już zatwierdzona",
  2088: "Już wysłałeś prośbę o dostęp do tej sesji.",
  email: {
    200: "Osoby dodane"
  },
  domain: {
    200: "Grupa dodana"
  }
}, Xr = {
  200: "Świetnie! Będziemy Cię na bieżąco informować."
}, ei = {
  404: "$t(responses.default)",
  405: "Kod weryfikacyjny jest nieprawidłowy. Proszę sprawdzić ponownie.",
  500: "$t(responses.default)"
}, ti = {
  500: "Przepraszamy, coś poszło nie tak. Proszę spróbować ponownie.",
  avatar: {
    400: "Błąd zapisu awatara. Proszę spróbować ponownie."
  }
}, si = {
  default: "Przepraszamy, coś poszło nie tak. Proszę spróbować ponownie.",
  timeout: Yr,
  login: Vr,
  register: Wr,
  session: xr,
  dashboardSession: Zr,
  sessionAccess: Jr,
  subscribe: Xr,
  verify: ei,
  user: ti
}, ni = "동의", oi = "축하하다", ai = "완료됨", ri = "동의하지 않음", ii = "웃긴", ci = "안녕", ui = "사랑", di = "와우", li = {
  agree: ni,
  celebrate: oi,
  completed: ai,
  disagree: ri,
  funny: ii,
  hi: ci,
  love: ui,
  wow: di
}, mi = {
  "logged-ticket-private": "{{name}}님이 이것을 기록했습니다",
  view: "보기",
  "view-ticket": "티켓 보기",
  meeting: "미팅",
  "shared-private": "{{name}}님이 이것을 공유했습니다",
  "logged-ticket-public": "{{name}}님이 이것을 기록했습니다",
  "shared-public": "{{name}}님이 이것을 기록했습니다",
  "crated-meeting-public": "{{name}}님이 미팅을 공유했습니다",
  "crated-meeting-private": "{{name}}님이 미팅을 만들었습니다"
}, gi = {
  ticketing: "티켓 발급",
  messaging: "메시징"
}, pi = {
  link: mi,
  category: gi
}, fi = {
  guestSkippedSelf: "{{name}} 님이 자신을 건너뛰었습니다",
  hostSkippedGuest: "{{name}} 님이 건너뛰어졌습니다",
  messageCode: {
    0: "⭐️ <span/> 잘하고 있어요!<em>대단해!</em>",
    1: "👍 <span/> 여러분의 목소리를 <em>듣고</em> 보고 있습니다",
    2: "☎️ <span/> 당신의 목소리를 <em>들을 수</em> 없습니다",
    3: "🔴 <span/> 당신을 <em>볼 수</em> 없습니다",
    4: "🔇 <span/> 마이크를 <em>음소거</em>해 주세요",
    5: "📢 <span/> 오디오를 <em>고치세요</em>",
    6: "💡 <span/> 조명을 <em>고치세요</em>",
    7: "🔄 <span/> 브라우저를 <em>새로고침</em>하세요",
    8: "💪 <span/> 다시 대기열에 <em>들어가</em> 주세요",
    9: "⚠️ <span/>  카메라와 소리를 <em>테스트하세요</em>",
    10: "⭐️ <span/> 정말 <em>잘했어요!</em>",
    11: "🎧 <span/> 오디오를 고쳐 <em>보세요</em>",
    12: "💡 <span/> 조명을 고쳐 <em>보세요</em>",
    13: "🎯 <span/> 사람들에게 참여할 것을 <em>요청하세요</em>",
    14: "⭐️ <span/> 멋져 <em>보여요!</em>",
    15: "👀 <span/> 휴대폰을 <em>확인해보세요</em>",
    16: "🔴 <span/> <em>비디오</em>에 문제가 있습니다",
    17: "🎬 <span/> 곧 당신 차례입니다",
    18: "⏸ <span/> 대기열을 <em>일시 중지했습니다</em>",
    19: "▶️ <span/> 다시 시작하려면 <em>클릭하세요</em>",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "메시지 전송 실패",
  botsAddedToQueue: "대기열에 봇이 추가되었습니다"
}, Si = {
  session: fi
}, yi = "작업 시간이 초과되었습니다. 인터넷 연결을 확인하고 다시 시도해 주세요.", hi = {
  400: "이 이메일 주소는 $t(_company)에 등록되어 있지 않습니다. 올바른지 확인하거나 아래에서 가입해 주세요.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, vi = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, bi = {
  2033: "세션을 성공적으로 생성했습니다.",
  2064: "세션을 성공적으로 업데이트했습니다."
}, $i = {
  200: "첫 세션을 성공적으로 생성했습니다.",
  201: "$t(responses.dashboardSession.200)"
}, Ai = {
  2084: "세션 접근 요청이 이미 승인된 상태입니다",
  2088: "이미 이 세션에 접근을 요청했습니다.",
  email: {
    200: "사람들이 추가되었습니다"
  },
  domain: {
    200: "그룹이 추가되었습니다"
  }
}, Ei = {
  200: "좋습니다! 최신 정보를 알려드리겠습니다."
}, wi = {
  404: "$t(responses.default)",
  405: "인증 코드가 정확하지 않습니다. 다시 확인해 주세요.",
  500: "$t(responses.default)"
}, Ci = {
  500: "죄송합니다, 문제가 발생했습니다. 다시 시도해 주세요.",
  avatar: {
    400: "아바타 저장에 오류가 발생했습니다. 다시 시도해 주세요."
  }
}, _i = {
  default: "죄송합니다, 문제가 발생했습니다. 다시 시도해 주세요.",
  timeout: yi,
  login: hi,
  register: vi,
  session: bi,
  dashboardSession: $i,
  sessionAccess: Ai,
  subscribe: Ei,
  verify: wi,
  user: Ci
}, Ii = "Concordo", Ti = "Celebre", Oi = "Concluído", ki = "Discordo", Di = "Engraçado", Mi = "Oi", Pi = "Amor", Ri = "Uau", Ni = {
  agree: Ii,
  celebrate: Ti,
  completed: Oi,
  disagree: ki,
  funny: Di,
  hi: Mi,
  love: Pi,
  wow: Ri
}, zi = {
  "logged-ticket-private": "{{name}} registrou isto",
  view: "Visualizar",
  "view-ticket": "Ver bilhete/ingresso",
  meeting: "Reunião",
  "shared-private": "{{name}} compartilhou isto",
  "logged-ticket-public": "{{name}} registrou isto",
  "shared-public": "{{name}} registrou isto",
  "crated-meeting-public": "{{name}} compartilhou uma reunião",
  "crated-meeting-private": "{{name}} criou uma reunião"
}, Bi = {
  ticketing: "Emissão de bilhetes/ingressos",
  messaging: "Mensagens"
}, Fi = {
  link: zi,
  category: Bi
}, Li = {
  guestSkippedSelf: "{{name}} pulou a sua vez",
  hostSkippedGuest: "{{name}} foi pulado",
  messageCode: {
    0: "⭐️ <span/> Você está indo <em>ótimo!</em>",
    1: "👍 <span/> Nós <em>ouvimos</em> e vemos você",
    2: "☎️ <span/> Não conseguimos <em>ouvir</em> você",
    3: "🔴 <span/> Não conseguimos <em>ver</em> você",
    4: "🔇 <span/> Por favor, <em>silencie-se</em>",
    5: "📢 <span/> Arrume o seu <em>áudio</em>",
    6: "💡 <span/> Ajuste a sua <em>iluminação</em>",
    7: "🔄 <span/> <em>Atualize</em> o seu navegador",
    8: "💪 <span/> Por favor, <em>volte</em> para a fila",
    9: "⚠️ <span/> Teste <em>câmera e som</em>",
    10: "⭐️ <span/> Você foi <em>ótimo!</em>",
    11: "🎧 <span/> Tente arrumar o seu <em>áudio</em>",
    12: "💡 <span/> Tente arrumar a sua <em>iluminação</em>",
    13: "🎯 <span/> <em>Peça para as pessoas</em> entrarem",
    14: "⭐️ <span/> Está <em>ótimo!</em>",
    15: "👀 <span/> Verifique <em>o seu celular</em>",
    16: "🔴 <span/> O seu <em>vídeo</em> está com defeito",
    17: "🎬 <span/> Você é o próximo",
    18: "⏸ <span/> <em>Eu pausei</em> a fila",
    19: "▶️ <span/> Você precisa <em>clicar em continuar</em>",
    100: "👍",
    101: "👎",
    102: "❤️",
    103: "👏️"
  },
  failedToSendMessage: "Falha ao enviar mensagem",
  botsAddedToQueue: "Bots foram adicionados à fila"
}, Ki = {
  session: Li
}, ji = "Ação esgotou o tempo. Por favor, verifique sua conexão com a internet e tente novamente.", qi = {
  400: "Este endereço de email não está registrado no $t(_company). Por favor, verifique se está correto ou inscreva-se abaixo.",
  404: "$t(responses.login.400)",
  500: "$t(responses.default)"
}, Ui = {
  400: "$t(responses.default)",
  500: "$t(responses.default)"
}, Hi = {
  2033: "Sessão criada com sucesso.",
  2064: "Sessão atualizada com sucesso."
}, Gi = {
  200: "Sua primeira sessão foi criada com sucesso.",
  201: "$t(responses.dashboardSession.200)"
}, Qi = {
  2084: "Solicitação de acesso à sessão já estava em estado aprovado",
  2088: "Você já solicitou acesso a esta sessão.",
  email: {
    200: "Pessoas adicionadas"
  },
  domain: {
    200: "Grupo adicionado"
  }
}, Yi = {
  200: "Ótimo! Vamos mantê-lo(a) atualizado(a)."
}, Vi = {
  404: "$t(responses.default)",
  405: "O código de verificação está incorreto. Por favor, verifique novamente.",
  500: "$t(responses.default)"
}, Wi = {
  500: "Desculpe, algo deu errado. Por favor, tente novamente.",
  avatar: {
    400: "Erro ao salvar avatar. Por favor, tente novamente."
  }
}, xi = {
  default: "Desculpe, algo deu errado. Por favor, tente novamente.",
  timeout: ji,
  login: qi,
  register: Ui,
  session: Hi,
  dashboardSession: Gi,
  sessionAccess: Qi,
  subscribe: Yi,
  verify: Vi,
  user: Wi
}, dd = {
  en: {
    responses: Xo,
    notifications: Uo,
    emojiReactions: Ao,
    integrations: jo,
    userMeetingTypes: pa
  },
  it: {
    responses: Fa,
    notifications: Ta,
    emojiReactions: Ea,
    integrations: _a
  },
  de: {
    responses: cr,
    notifications: Ja,
    emojiReactions: Ya,
    integrations: xa
  },
  es: {
    responses: Mr,
    notifications: Ar,
    emojiReactions: yr,
    integrations: br
  },
  pl: {
    responses: si,
    notifications: Qr,
    emojiReactions: jr,
    integrations: Hr
  },
  ko: {
    responses: _i,
    notifications: Si,
    emojiReactions: li,
    integrations: pi
  },
  pt: {
    responses: xi,
    notifications: Ki,
    emojiReactions: Ni,
    integrations: Fi
  }
}, te = {
  "✅": "emojiReactions.completed",
  "❤️": "emojiReactions.love",
  "👋": "emojiReactions.hi",
  "👍": "emojiReactions.agree",
  "👎": "emojiReactions.disagree",
  "👏": "emojiReactions.celebrate",
  "😆": "emojiReactions.funny",
  "😮": "emojiReactions.wow"
}, M = [
  "👍",
  "😆",
  "❤️",
  "👋",
  "👎",
  "👏",
  "😮",
  "✅"
], ld = [
  {
    label: te[M[0]],
    symbol: M[0]
  },
  {
    label: te[M[1]],
    symbol: M[1]
  },
  {
    label: te[M[2]],
    symbol: M[2]
  },
  {
    label: te[M[3]],
    symbol: M[3]
  },
  {
    label: te[M[4]],
    symbol: M[4]
  },
  {
    label: te[M[5]],
    symbol: M[5]
  },
  {
    label: te[M[6]],
    symbol: M[6]
  },
  {
    label: te[M[7]],
    symbol: M[7]
  }
];
export {
  yt as CLEAR_SESSION,
  bt as CLOSE_SESSION_MODAL,
  Us as CrmProvider,
  $t as GRANT_CONSENT_TO_BROADCAST,
  mt as NAVIGATE_TO,
  At as REVOKE_CONSENT_TO_BROADCAST,
  Ws as SETUP_CURRENT_SESSION,
  pt as SET_ACTIVE_SESSION,
  ft as SET_BOTH_SESSIONS,
  gt as SET_CURRENT_SESSION,
  lt as SET_MODAL,
  St as SET_SESSION,
  vt as SET_SESSION_MODAL,
  on as SessionModalType,
  ku as SessionStateKey,
  ht as UPDATE_SESSION,
  En as addFullName,
  _t as addFullNames,
  zc as anonymize,
  Nc as areEqual,
  Ks as buildSocialProfileLink,
  pc as buildSocialUrls,
  _c as clearSession,
  Tc as closeSessionModal,
  dd as commonTranslations,
  U as config,
  mu as createIsActiveSessionHost,
  ld as emojiReactionsOptions,
  vc as featureFlagsService,
  Xc as findPlan,
  Jc as findPlanById,
  xc as findPrice,
  An as fullName,
  Pc as fullName2,
  ze as getFeature,
  Rc as getFirstNameAndInitial,
  Hu as getLastMessage,
  Pt as getLastMessageByRole,
  qu as getLastUserMessage,
  kn as getPrice,
  en as getPrimaryHost,
  js as getProfileUrl,
  nn as getSessionEndedAt,
  tn as getSessionPrimaryHost,
  sn as getSessionStartedAt,
  Et as getSessionStateChangeAt,
  On as getTrialDaysLeft,
  Oc as grantConsentToBroadcast,
  Rt as handleMessageEvent,
  gc as hasGoogleCalendarIntegration,
  Bu as hubspot,
  dc as initConfig,
  mc as integrations,
  lc as isAnyRequestErrorCode,
  Vc as isFeatureDisabled,
  Tn as isFeatureEnabled,
  Yc as isFreeOrFreeTrialPlan,
  _n as isFreePlan,
  In as isFreeTrialPlan,
  Qc as isFreeType,
  Zc as isPaymentFailing,
  Cn as isPlanCanceling,
  Gc as isPlanType,
  Fs as isRequestErrorCode,
  Dc as isSessionActive,
  Mc as isSessionPrimaryHost,
  Du as isTeamMember,
  Mu as isTeamOwner,
  Wc as isTrialAvailable,
  Ot as isTrialPlan,
  Gs as modalInitialState,
  Qs as modalReducer,
  Ac as navigateTo,
  Ys as navigationInitialState,
  Vs as navigationReducer,
  qc as planFeatures,
  jc as planOrder,
  Hc as premiumSubscriptionPlans,
  ud as reducers,
  Nn as requestDeletionMutation,
  kc as revokeConsentToBroadcast,
  Xu as salesforce,
  F as selectActiveSession,
  su as selectActiveSessionAbout,
  nu as selectActiveSessionCover,
  au as selectActiveSessionEndTimestamps,
  fu as selectActiveSessionEndedAtState,
  Me as selectActiveSessionHosts,
  gu as selectActiveSessionHostsCount,
  Be as selectActiveSessionId,
  du as selectActiveSessionOrganizer,
  kt as selectActiveSessionOrganizerId,
  eu as selectActiveSessionRecurrenceId,
  Mn as selectActiveSessionSettings,
  cu as selectActiveSessionShareUrl,
  ou as selectActiveSessionStartTimestamps,
  pu as selectActiveSessionStartedAtState,
  Dt as selectActiveSessionStateUpdates,
  iu as selectActiveSessionStatus,
  Dn as selectActiveSessionSubscriptionPlan,
  ru as selectActiveSessionSubscriptionPlanStreamQuality,
  tu as selectActiveSessionTitle,
  It as selectAllSessions,
  Pn as selectConsentToBroadcast,
  q as selectCurrentSession,
  hu as selectCurrentSessionAbout,
  vu as selectCurrentSessionCover,
  wu as selectCurrentSessionEndTimestamps,
  Fe as selectCurrentSessionHosts,
  Ou as selectCurrentSessionHostsCount,
  Rn as selectCurrentSessionId,
  Tu as selectCurrentSessionOrganizer,
  Mt as selectCurrentSessionOrganizerId,
  Su as selectCurrentSessionRecurrenceId,
  Au as selectCurrentSessionSettings,
  bu as selectCurrentSessionShareUrl,
  Eu as selectCurrentSessionStartTimestamps,
  Cu as selectCurrentSessionStatus,
  $u as selectCurrentSessionSubscriptionPlan,
  yu as selectCurrentSessionTitle,
  lu as selectIsActiveSessionHost,
  uu as selectIsActiveSessionOrganizer,
  _u as selectIsCurrentSessionHost,
  Iu as selectIsCurrentSessionOrganizer,
  $c as selectModal,
  Ec as selectNavigation,
  Fc as selectSessionById,
  Lc as selectSessionExists,
  Kc as selectSessionModal,
  ue as selectSessionState,
  se as sessionInitialState,
  Bc as sessionsReducer,
  Cc as setActiveSession,
  Zs as setBothSessions,
  xs as setCurrentSession,
  bc as setModal,
  Js as setSession,
  Ic as setSessionModal,
  wc as setupCurrentSession,
  _e as setupSession,
  Ls as socialProfileUrls,
  dt as sortHosts,
  Xs as updateSession,
  Uc as usdDivisor,
  fc as useActivateTrial,
  Nt as useAi,
  hc as useEndSession,
  xu as useFeedback,
  Xn as useFeedbackMutation,
  Sc as useGetBindingsByOwnerSessionRecurrence,
  Hs as useGetBindingsByOwnerSessionRecurrenceData,
  yc as useGetBindingsData,
  Vn as useGetConnections,
  Fu as useGetConnectionsData,
  Bn as useGetSession,
  Pu as useGetSessionData,
  Nu as useGetSessionWithAccess,
  zu as useHostOptIn,
  Lu as useIntegrations,
  Uu as useLastAiMessage,
  Ku as useLocalParticipantHasJoined,
  ju as useManageSessionPendingAccess,
  Vu as useMeetingMemory,
  Zu as useOffTheRecord,
  to as useOffTheRecordEnabled,
  Jn as usePrivateQueryConfig,
  Gu as usePrivateQueryKey,
  Wu as useRefreshLastResponse,
  Kn as useRequestAccessStatus,
  Ju as useRequestDeletion,
  ed as useSessionAIFeed,
  td as useSessionAIFeedData,
  sd as useSessionPendingAccess,
  nd as useSessionPendingAccessData,
  od as useSessionPendingAccessStats,
  ad as useSessionPresence,
  rd as useSessionPresenceData,
  Qu as useStopGeneration,
  Yu as useSuggestions,
  id as useUpdateAiFeed,
  Wn as useUpdateGetConnectionsData,
  xn as useUpdateParticipantMetadata,
  cd as useUpdateSession,
  Ln as useUserSessionRequestAccess,
  Ru as useUserSessionRequestAccessData
};
