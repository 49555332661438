const t = /* @__PURE__ */ (() => {
  const s = (o, ...e) => {
    n.console && console[o](...e);
  };
  let n = { console: !0 };
  const c = (o) => {
    throw o;
  };
  return {
    init: (o) => {
      n = { ...n, ...o };
    },
    c: s,
    console: s,
    show: (o, ...e) => console[o](...e),
    report: (o, e = "error") => {
      n.log && n.log(o), s(e, o);
    },
    raise: c,
    rescue: (o, e) => o instanceof e ? o : c(o)
  };
})();
export {
  t as errorService
};
