export type ButtonCallback = (() => void) | undefined;

const refreshNotification = (() => {
  let initialized = false;
  let callback: ButtonCallback = undefined;
  const elems: Partial<
    Record<'refresh' | 'close' | 'element' | 'textElement', HTMLElement | null>
  > = {};

  const init = () => {
    if (initialized) return;
    elems.element = document.getElementById('refresh-notification');
    initialized = !!elems.element;
    if (elems.element) {
      elems.textElement = elems.element.querySelector('span');
      elems.refresh = elems.element.querySelector<HTMLElement>('.refresh');
      elems.close = elems.element.querySelector<HTMLElement>('.close');
      if (elems.refresh) {
        elems.refresh.addEventListener('click', () => {
          if (callback) callback();
          setTimeout(() => window.location.reload(), 500);
        });
      }
      if (elems.close) {
        elems.close.addEventListener('click', () => {
          elems.element?.remove();
        });
      }
    }
  };

  return {
    initialized,
    setText: (text: string) => {
      init();
      if (!elems.textElement) return;
      elems.textElement.innerHTML = text;
    },
    show: (onClick?: ButtonCallback) => {
      init();
      if (!elems.element) return;
      callback = onClick;
      elems.element.style.display = 'block';
    },
  };
})();

export default refreshNotification;
