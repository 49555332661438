import { errorService as C } from "@waitroom/error-service";
import { LOGGER_SERVICE_CHAT as S } from "@waitroom/models";
import { useState as p, useCallback as w, useEffect as v } from "react";
import { StreamChat as E } from "stream-chat";
let g;
const b = (t) => {
  g = t.logger;
}, M = ({
  key: t,
  channelId: e,
  userId: s,
  token: c,
  allowAnonymous: u = !1
}) => {
  const [r, f] = p({ loading: !1 }), i = (a) => f((n) => ({ ...n, ...a })), d = u ? "public_session" : "private_session", l = w(
    async (a, n) => {
      if (!n) return i({ channel: void 0 });
      i({ loading: !0, error: void 0 });
      try {
        const m = n.channel(d, a);
        i({ channel: m, loading: !1 });
      } catch (m) {
        i({ loading: !1, error: m });
      }
    },
    [d]
  ), y = w(
    async (a, n) => {
      if (!n) return;
      const m = n.channel(d, a);
      i({ channel: m });
    },
    [d]
  );
  return v(() => {
    var n;
    if (!e || !r.user) return;
    g == null || g.service(
      S,
      3,
      "Connection to chat channel",
      e
    );
    const a = ((n = r.channel) == null ? void 0 : n.cid) !== e;
    r.channel ? a && l(e, r.client) : y(e, r.client);
  }, [
    r.client,
    r.user,
    e,
    y,
    r.channel,
    l
  ]), v(() => {
    if (!u && (!s || !c)) return;
    g == null || g.service(S, 3, "Initializing chat client", {
      userId: s,
      allowAnonymous: u
    }), i({ loading: !0, error: void 0 });
    const a = new E(t);
    let n = !1;
    const m = (s ? a.connectUser(
      {
        id: s
      },
      !c || typeof c == "string" ? c : async () => {
        try {
          const o = await c();
          if (!o) throw new Error("Failed to fetch token");
          return o;
        } catch (o) {
          return i({ loading: !1, error: o }), "";
        }
      }
    ) : a.connectAnonymousUser()).then((o) => {
      n || i({
        client: a,
        user: o == null ? void 0 : o.me,
        loading: !1,
        error: void 0
      });
    }).catch((o) => {
      C.report(o), i({ loading: !1, error: o });
    });
    return () => {
      n = !0, f({ client: void 0, user: void 0 }), m.then(() => {
        a.disconnectUser();
      });
    };
  }, [u, t, c, s]), r;
}, G = ({
  channel: t,
  onChange: e,
  onNewMessage: s
}) => {
  const [c, u] = p(!0), [r, f] = p([]);
  return v(() => {
    if (!t) return;
    (async () => {
      u(!0), await t.watch(), t.state.messages.length > 0 && (e && e(), f(t.state.messages)), u(!1);
    })();
  }, [t, e]), v(() => {
    if (!t) return;
    const i = (l) => {
      l.message && (s && s(l.message), e && e(), f(t.state.messages));
    }, d = (l) => {
      l.message && (e && e(), f(t.state.messages));
    };
    return t.on("message.new", i), t.on("message.updated", d), () => {
      t.off("message.new", i), t.off("message.updated", d);
    };
  }, [t, e, s]), { messages: r, loading: c };
}, L = ({ channel: t }) => {
  const [e, s] = p(), c = w(
    async (u, r) => {
      try {
        s({ loading: !0 });
        const f = await (t == null ? void 0 : t.sendMessage(u, r));
        if (!f) throw new Error("Failed to send message");
        return s({ loading: !1 }), f.message;
      } catch {
        s({ error: "Unable to send, please retry", loading: !1 });
        return;
      }
    },
    [t]
  );
  return { ...e, onSend: c };
};
export {
  b as initConfig,
  g as logger,
  M as useChatClient,
  G as useChatMessages,
  L as useChatMethods
};
