import { commonTranslations } from '@waitroom/common';
import { ResourceLanguage } from 'i18next';

// EN
import enAuth from './en/auth.json';
import enError from './en/error.json';
import enForm from './en/form.json';
import enHost from './en/host.json';
import enPricing from './en/pricing.json';
import en from './en/translation.json';
// IT
import itAuth from './it/auth.json';
import itError from './it/error.json';
import itForm from './it/form.json';
import itPricing from './it/pricing.json';
import it from './it/translation.json';
// DE
import deAuth from './de/auth.json';
import deError from './de/error.json';
import deForm from './de/form.json';
import dePricing from './de/pricing.json';
import de from './de/translation.json';
// ES
import esAuth from './es/auth.json';
import esError from './es/error.json';
import esForm from './es/form.json';
import esPricing from './es/pricing.json';
import es from './es/translation.json';
// PL
import plAuth from './pl/auth.json';
import plError from './pl/error.json';
import plForm from './pl/form.json';
import plPricing from './pl/pricing.json';
import pl from './pl/translation.json';
// KO
import koAuth from './ko/auth.json';
import koError from './ko/error.json';
import koForm from './ko/form.json';
import koPricing from './ko/pricing.json';
import ko from './ko/translation.json';
// PT
import ptAuth from './pt/auth.json';
import ptError from './pt/error.json';
import ptForm from './pt/form.json';
import ptPricing from './pt/pricing.json';
import pt from './pt/translation.json';

export const supportedLanguages = [
  'en',
  'it',
  'de',
  'es',
  'pl',
  'ko',
  'pt',
] as const;
export type SupportedLanguages = (typeof supportedLanguages)[number];
export type Languages = Record<SupportedLanguages, ResourceLanguage> &
  Record<string, ResourceLanguage>;

export const resources: Languages = {
  en: {
    translation: {
      ...en,
      pricing: enPricing,
      auth: enAuth,
      form: enForm,
      error: enError,
      host: enHost,
      ...commonTranslations.en,
    },
  },
  it: {
    translation: {
      ...it,
      pricing: itPricing,
      auth: itAuth,
      form: itForm,
      error: itError,
      ...commonTranslations.it,
    },
  },
  de: {
    translation: {
      ...de,
      pricing: dePricing,
      auth: deAuth,
      form: deForm,
      error: deError,
      ...commonTranslations.de,
    },
  },
  es: {
    translation: {
      ...es,
      pricing: esPricing,
      auth: esAuth,
      form: esForm,
      error: esError,
      ...commonTranslations.es,
    },
  },
  pl: {
    translation: {
      ...pl,
      pricing: plPricing,
      auth: plAuth,
      form: plForm,
      error: plError,
      ...commonTranslations.pl,
    },
  },
  ko: {
    translation: {
      ...ko,
      pricing: koPricing,
      auth: koAuth,
      form: koForm,
      error: koError,
      ...commonTranslations.ko,
    },
  },
  pt: {
    translation: {
      ...pt,
      pricing: ptPricing,
      auth: ptAuth,
      form: ptForm,
      error: ptError,
      ...commonTranslations.pt,
    },
  },
};
