import { createAction as f } from "@waitroom/react-utils";
import { useSelector as U, useDispatch as w } from "react-redux";
import it from "deepmerge";
import { produce as rt } from "immer";
import { authApiService as T, braidService as G, authBaseEndpoint as F, httpService as at, userApiService as N } from "@waitroom/common-api";
import { STORAGE_AUTH_TOKENS as g, LOGGER_SERVICE_AUTH as _ } from "@waitroom/models";
import { tryCatchAsync as x, withLock as z, retry as ot, isFunction as ut } from "@waitroom/utils";
import { jwtDecode as ht } from "jwt-decode";
import { useMemo as dt } from "react";
import { useMutation as y } from "@tanstack/react-query";
import { bearerValue as P } from "@waitroom/http-client";
import { createListenerMiddleware as ft } from "@reduxjs/toolkit";
import { getRequestData as L } from "@waitroom/react-query";
const V = "refresh-token", B = "auth/INIT_AUTH", j = "auth/SETUP_AUTH", p = "auth/LOGOUT", q = "auth/SET_CURRENT_USER", W = "auth/UPDATE_CURRENT_USER", Bt = "auth/SET_CURRENT_USER_SUBSCRIPTION_PLAN", K = "auth/SET_AUTH_STATE", J = "auth/UPDATE_AUTH_STATE", Q = "auth/SET_SUBSCRIPTION_PLAN", X = "auth/REFRESH_SUBSCRIPTION_PLAN", Y = "auth/AUTHENTICATED", Tt = "auth/UN_AUTHENTICATED", Z = "auth/GET_CURRENT_USER", jt = f(B), $ = f(j), v = f(K), l = f(J), St = f(Z), pt = f(q), qt = f(W), lt = f(p), O = f(Y), I = f(Tt), tt = f(Q), Wt = f(X);
let a;
const Kt = (e) => {
  a = e.logger;
}, mt = (e) => e.reduce(
  (t, s) => ({
    ...t,
    [s]: !0
  }),
  {}
), R = {
  userId: void 0,
  isAuthenticated: !1,
  status: "initialized",
  isGuest: !1
}, S = {
  _refreshPromise: void 0,
  _cache: {},
  config: {},
  init: function(e) {
    this.config = e;
  },
  getAnonymous: () => R,
  getDecodedToken: function(e) {
    if (this._cache[e]) return this._cache[e];
    const t = ht(e);
    if (t)
      return this._cache[e] = t, t;
  },
  setup: async function(e, t = !0) {
    const s = e || this.getStorageTokens();
    if (!(s != null && s.accessToken)) return R;
    const n = this.decode(s, 600);
    n != null && n.accessToken && this.saveStorageTokens({
      accessToken: n.accessToken,
      refreshToken: n.refreshToken
    });
    const c = n || (t ? await this.refresh(s.refreshToken) : void 0);
    return c != null && c.accessToken ? c : this.logout();
  },
  getStorageTokens: function() {
    var e;
    return (e = this.config.storage) == null ? void 0 : e.getParsed(
      g
    );
  },
  saveStorageTokens: function(e) {
    var t;
    (t = this.config.storage) == null || t.set(g, e);
  },
  clearStorageTokens: function() {
    var e;
    (e = this.config.storage) == null || e.remove(g);
  },
  isTokenValid: function(e, t = 0) {
    const s = e ? this.getDecodedToken(e) : void 0;
    return !!s && !this.hasExpired(s.exp, t);
  },
  _refreshFn: async function(e) {
    var i;
    if (!e) return;
    const t = await x(
      () => T.refreshToken({
        data: {
          refreshToken: e
        }
      })
    )();
    if ((t == null ? void 0 : t.code) === 401) return;
    const { authToken: s, refreshToken: n } = ((i = t == null ? void 0 : t.data) == null ? void 0 : i.data) || {};
    if (!s) return;
    const c = { accessToken: s, refreshToken: n }, r = this.decode(c);
    if (r)
      return this.saveStorageTokens(c), r;
  },
  refresh: async function(e) {
    var c;
    if (a == null || a.service(
      _,
      1,
      "Refreshing auth token",
      e
    ), this._refreshPromise) return await this._refreshPromise;
    const t = (c = this.getStorageTokens()) == null ? void 0 : c.refreshToken, s = e || t;
    if (!s) return;
    this._refreshPromise = this._refreshFn(s);
    const n = await this._refreshPromise || await this._refreshFn(t);
    return a == null || a.service(
      _,
      1,
      "Refreshed auth token",
      n == null ? void 0 : n.refreshToken
    ), this._refreshPromise = void 0, n;
  },
  /** Decode token with expiration tolerance in seconds */
  decode: function(e, t) {
    const {
      userID: s,
      guestSurrogateID: n,
      guestEmail: c,
      guestFullName: r,
      exp: i,
      roleIDs: u = []
    } = this.getDecodedToken(e.accessToken) || {}, o = s || (n ? String(n) : void 0);
    if (!o || this.hasExpired(i, t)) return;
    const d = !s, m = {
      ...e,
      userId: o,
      isAuthenticated: !0,
      expires: i,
      agentId: o,
      isGuest: d,
      roles: mt(u),
      status: "initialized"
    };
    return d && (m.currentUser = {
      id: String(n),
      firstName: r || "",
      email: c || ""
    }), m;
  },
  logout: async function() {
    return await T.logout({ throwError: !1 }), this.clearStorageTokens(), R;
  },
  /** Check if token has expired with expiration tolerance in seconds */
  hasExpired: (e, t = 0) => !e || Date.now() + t * 1e3 > e * 1e3
}, et = S.getAnonymous(), st = {
  ...et,
  status: void 0
}, Jt = (e = st, t) => rt(e, (s) => {
  var n;
  switch (t.type) {
    case K:
      return t.payload || et;
    case J:
      return s = { ...s, ...t.payload }, s;
    case q:
      return s.currentUser = t.payload, s.userId = (n = t.payload) == null ? void 0 : n.id, s;
    case W:
      return t.payload && (s.currentUser = it(
        s.currentUser || {},
        t.payload
      ), t.payload.id && (s.userId = t.payload.id)), s;
    case Q:
      return !s.currentUser || !t.payload || (s.currentUser.subscriptionPlan = t.payload), s;
    default:
      return e;
  }
}), h = (e) => e && e.auth ? e.auth : st, _t = (e) => h(e).isAuthenticated, Et = (e) => h(e).isGuest, Ut = (e) => h(e).status, Qt = (e) => h(e).userId, A = (e) => h(e).currentUser, Xt = (e) => {
  var t;
  return (t = A(e)) == null ? void 0 : t.id;
}, Yt = (e) => {
  var t;
  return (t = A(e)) == null ? void 0 : t.email;
}, kt = (e) => h(e).roles, nt = (e) => {
  var t;
  return (t = A(e)) == null ? void 0 : t.notificationSettings;
}, Zt = (e) => {
  var t;
  return (t = A(e)) == null ? void 0 : t.subscriptionPlan;
}, $t = (e) => {
  var t;
  return (t = A(e)) == null ? void 0 : t.team;
}, te = (e) => {
  var t;
  return (t = nt(e)) == null ? void 0 : t.isPushNotificationEnabledGlobal;
}, ee = (e) => {
  var t;
  return (t = nt(e)) == null ? void 0 : t.isEmailNotificationEnabledGlobal;
}, se = (e) => {
  const { accessToken: t, refreshToken: s, expires: n } = h(e);
  return { accessToken: t, refreshToken: s, expires: n };
}, ne = (e) => h(e).accessToken, ce = (e) => h(e).agentId, ie = () => {
  const e = U(h);
  return {
    state: e,
    isLoading: !e.status || e.status === "loading"
  };
}, re = (e) => {
  const t = U(_t), s = U(Et), n = U(Ut), c = U(kt), [r, i] = dt(() => e ? t ? (typeof e == "string" ? [e] : e).reduce(
    (o, d) => {
      const m = !!c && !!c[d];
      return o[0][d] = m, o[1] = o[1] && m, o;
    },
    [{}, !0]
  ) : [{}, !1] : [{}, !0], [e, t, c]);
  return {
    isAuthenticated: t,
    isGuest: s,
    status: n,
    isLoading: !n || n === "loading",
    hasAccess: i,
    access: r,
    roles: c
  };
}, At = (e) => ({
  ...e,
  mutationFn: async (t) => {
    const s = {
      ...t,
      email: t.email.toLowerCase()
    };
    return t.clientId && t.redirectUrl ? T.requestOAuthOTP({
      data: {
        ...s,
        redirect_uri: t.redirectUrl,
        client_id: t.clientId,
        state: t.state,
        codeChallenge: t.codeChallenge
      }
    }) : T.requestOTP({ data: s });
  }
}), yt = (e) => ({
  ...e,
  mutationFn: async ({ email: t, otp: s, clientId: n, state: c, codeChallenge: r }) => n ? T.verifyOAuthOTP({
    data: {
      email: t.toLowerCase(),
      otp: s,
      client_id: n,
      state: c,
      codeChallenge: r
    }
  }) : T.verifyOTP({
    data: {
      email: t.toLowerCase(),
      otp: s
    }
  })
}), Pt = (e) => ({
  ...e,
  mutationFn: async ({
    token: t,
    code: s,
    type: n,
    userDetails: c,
    clientId: r,
    redirectUrl: i,
    state: u,
    codeChallenge: o
  }) => {
    if (!n || !s && !t) throw new Error("Invalid request");
    return r && i ? t ? T.verifyOAuthTokenProvider({
      data: {
        token: t,
        type: n,
        userDetails: c,
        client_id: r,
        redirect_uri: i,
        state: u,
        codeChallenge: o
      }
    }) : T.verifyOAuthCodeProvider({
      data: {
        code: s || "",
        type: n,
        client_id: r,
        redirect_uri: i,
        state: u,
        codeChallenge: o
      }
    }) : t ? T.verifyAuthTokenProvider({
      data: { token: t, type: n, userDetails: c }
    }) : T.verifyAuthCodeProvider({
      data: { code: s || "", type: n }
    });
  }
}), vt = (e) => ({
  ...e,
  mutationFn: async (t) => T.logout(t)
}), ae = (e) => {
  const t = w();
  return y(
    vt({
      ...e,
      onMutate: (...s) => {
        t(l({ status: "loading" })), e != null && e.onMutate && e.onMutate(...s);
      },
      onSuccess: (...s) => {
        t(l(S.getAnonymous())), t(I()), e != null && e.onSuccess && e.onSuccess(...s);
      }
    })
  );
}, oe = (e) => y(At(e)), ue = ({
  onRedirect: e,
  ...t
}) => {
  const s = w();
  return y(
    Pt({
      ...t,
      onMutate: (...n) => {
        s(l({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onError: (n, c, r) => {
        s(l({ status: "initialized" })), t != null && t.onError && t.onError(n, c, r);
      },
      onSuccess: (n, c, r) => {
        var u;
        if (s(l({ status: "initialized" })), !(n != null && n.success)) return;
        const i = (u = n.data) == null ? void 0 : u.data;
        if (i) {
          if (c.clientId && c.redirectUrl) {
            "redirect_uri" in i && i.redirect_uri && e(i.redirect_uri);
            return;
          }
          if ("authToken" in i && i.authToken) {
            const { authToken: o, refreshToken: d } = i;
            s(
              $({
                accessToken: o,
                refreshToken: d
              })
            );
          }
          t != null && t.onSuccess && t.onSuccess(n, c, r);
        }
      }
    })
  );
}, he = ({ onRedirect: e, ...t }) => {
  const s = w();
  return y(
    yt({
      ...t,
      onMutate: (...n) => {
        s(l({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onSuccess: (n, c, r) => {
        var u;
        if (s(l({ status: "initialized" })), !(n != null && n.success)) return;
        const i = (u = n.data) == null ? void 0 : u.data;
        if (i) {
          if (c.clientId) {
            "redirect_uri" in i && i.redirect_uri && e(i.redirect_uri);
            return;
          }
          if ("authToken" in i && i.authToken) {
            const { authToken: o, refreshToken: d } = i || {};
            if (!o) return;
            const m = c.redirectUrl;
            s(
              $({
                ...c,
                accessToken: o,
                refreshToken: d,
                onCompleted: m ? () => e(m) : void 0
              })
            );
          }
          t != null && t.onSuccess && t.onSuccess(n, c, r);
        }
      }
    })
  );
}, ct = () => {
  const e = S.getStorageTokens();
  return e != null && e.accessToken ? [S.isTokenValid(e.accessToken, 30), e] : [!1, void 0];
}, It = z(
  V,
  async (e) => {
    a == null || a.service(_, 3, "Refresh token interceptor lock");
    const [t, s] = ct();
    if (t || !(s != null && s.refreshToken))
      return s == null ? void 0 : s.accessToken;
    const n = await S.refresh(s == null ? void 0 : s.refreshToken);
    if (n != null && n.accessToken)
      return e == null || e(n), n.accessToken;
  }
), b = async (e, t) => {
  const [s, n] = ct();
  return s && e !== P(n.accessToken) ? n.accessToken : It(t);
}, de = ({ onAuthUpdated: e }) => async (t, s) => {
  var n;
  if (a == null || a.logWith(3, "Braid auth interceptor", s), !t._retry && typeof s == "object" && ((s == null ? void 0 : s.status) === 401 || (s == null ? void 0 : s.code) === 401)) {
    const c = await b(
      (n = t.headers) == null ? void 0 : n.Authorization,
      e
    );
    if (!c) throw s;
    return t._retry = !0, t.headers = {
      ...t.headers,
      Authorization: P(c)
    }, G.fetch(t);
  }
  throw s;
}, fe = ({ onRetry: e, onAuthUpdated: t }) => async (s) => {
  var c, r;
  e == null || e(s);
  const n = s == null ? void 0 : s.config;
  if (a == null || a.logWith(3, "Axios auth interceptor", s), ((c = s == null ? void 0 : s.response) == null ? void 0 : c.status) === 401 && !(n != null && n._retry) && !String(n.url).startsWith(F)) {
    n._retry = !0;
    const i = (r = n.headers.Authorization) == null ? void 0 : r.toString(), u = await b(i, t);
    return a == null || a.service(
      _,
      3,
      "Axios auth interceptor",
      u
    ), u ? (n.headers.Authorization = P(u), at.client(n)) : Promise.reject(s);
  }
  return Promise.reject(s);
}, Te = ({ onRetry: e, onAuthUpdated: t }) => async (s, n) => {
  var i, u;
  e == null || e(n);
  const c = s;
  if ((n && typeof n == "object" ? (n == null ? void 0 : n.code) || (n == null ? void 0 : n.status) : n) === 401 && !(c != null && c.retried) && !String(c.url).startsWith(F)) {
    c.retried = !0;
    const o = (u = (i = c.headers) == null ? void 0 : i.Authorization) == null ? void 0 : u.toString(), d = await b(o, t);
    return d ? (c.headers = {
      ...c.headers,
      Authorization: P(d)
    }, G.fetch(c)) : Promise.reject(n);
  }
  return Promise.reject(n);
}, D = (e) => !!e.isAuthenticated && !!e.userId && !e.isGuest, E = ft();
let k;
const gt = (e, t) => new Promise(async (s) => {
  k && clearTimeout(k);
  const { expires: n, refreshToken: c } = e;
  if (!n || !c) return;
  const r = ~~(6e4 + Math.random() * 24e4), i = Math.max(0, n * 1e3 - Date.now() - r);
  a == null || a.service(
    _,
    3,
    "Refresh auth token setup timeout",
    {
      time: i
    }
  ), k = setTimeout(
    z(V, async () => {
      a == null || a.service(_, 3, "Refresh auth token timeout");
      const o = await S.refresh(e.refreshToken) || await S.logout();
      return o && (t.dispatch(l(o)), t.dispatch(
        o.isAuthenticated ? O() : I()
      )), s(o);
    }),
    i
  );
}), M = async (e, t) => {
  const s = await gt(e, t);
  return s != null && s.isAuthenticated && M(s, t), s;
}, Rt = 6e5;
let C;
const Ct = (e) => new Promise(
  async (t) => {
    C = setTimeout(async () => {
      const s = await N.getSubscriptionPlan(e);
      return t(s);
    }, Rt);
  }
);
async function H(e) {
  C && clearTimeout(C);
  const t = h(e.getState());
  if (!D(t)) return;
  const s = await Ct(t.userId), n = L(s);
  n && s.success && e.dispatch(tt(n)), H(e);
}
E.startListening({
  type: B,
  effect: async (e, t) => {
    t.cancelActiveListeners();
    const s = await S.setup();
    s.refreshToken && t.fork(async () => {
      M(s, t);
    }), t.dispatch(v(s)), t.dispatch(
      s.isAuthenticated ? O() : I()
    );
  }
});
E.startListening({
  type: Z,
  effect: async (e, t) => {
    var r;
    t.cancelActiveListeners();
    const s = h(t.getState());
    if (!D(s)) return;
    const n = await x(
      () => ot(N.getCurrent, {
        retries: 2
      })
    )();
    if (n != null && n.code && n.code >= 400 && n.code <= 500) {
      t.dispatch(lt());
      return;
    }
    const c = (r = L(n)) == null ? void 0 : r.user;
    if (c) {
      const i = {
        id: c.userID,
        firstName: c.userFirstName,
        lastName: c.userLastName,
        ...c
      }, o = { ...h(t.getState()), currentUser: i };
      t.dispatch(v(o)), t.fork(() => H(t));
      return;
    }
  }
});
E.startListening({
  type: j,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  effect: async (e, t) => {
    t.cancelActiveListeners(), a == null || a.service(_, 1, "SETUP_AUTH");
    const { accessToken: s, refreshToken: n, onCompleted: c } = e.payload || {}, r = await S.setup({
      accessToken: s,
      refreshToken: n
    });
    r.refreshToken && t.fork(() => M(r, t)), r.isAuthenticated && t.fork(() => H(t)), t.dispatch(v(r)), t.dispatch(O()), t.dispatch(l({ status: "initialized" })), c && (ut(c) ? c() : t.dispatch(c));
  }
});
E.startListening({
  type: Y,
  effect: async (e, t) => {
    t.cancelActiveListeners(), t.dispatch(St());
  }
});
E.startListening({
  type: p,
  effect: async (e, t) => {
    t.cancelActiveListeners(), t.dispatch(l({ status: "loading" })), k && clearTimeout(k), h(t.getState()).isAuthenticated && await S.logout(), t.dispatch(v(S.getAnonymous())), t.dispatch(I());
  }
});
E.startListening({
  type: X,
  effect: async (e, t) => {
    t.cancelActiveListeners();
    const s = h(t.getState());
    if (!D(s)) return;
    const n = await N.getSubscriptionPlan(s.userId), c = L(n);
    c && n.success && t.dispatch(tt(c));
  }
});
export {
  Y as AUTHENTICATED,
  Z as GET_CURRENT_USER,
  B as INIT_AUTH,
  p as LOGOUT,
  X as REFRESH_SUBSCRIPTION_PLAN,
  j as SETUP_AUTH,
  K as SET_AUTH_STATE,
  q as SET_CURRENT_USER,
  Bt as SET_CURRENT_USER_SUBSCRIPTION_PLAN,
  Q as SET_SUBSCRIPTION_PLAN,
  Tt as UN_AUTHENTICATED,
  J as UPDATE_AUTH_STATE,
  W as UPDATE_CURRENT_USER,
  st as authInitialState,
  E as authListenerMiddleware,
  Jt as authReducer,
  et as authResetState,
  S as authService,
  O as authenticated,
  fe as axiosAuthInterceptor,
  Te as braidOnError,
  de as braidOnErrorInterceptor,
  b as getAccessToken,
  St as getCurrentUser,
  jt as initAuth,
  Kt as initConfig,
  a as logger,
  lt as logout,
  vt as logoutMutation,
  Wt as refreshSubscriptionPlan,
  V as refreshTokenLockName,
  At as requestOTPMutation,
  ce as selectAgentId,
  ne as selectAuthAccessToken,
  h as selectAuthState,
  Ut as selectAuthStatus,
  se as selectAuthToken,
  Qt as selectAuthUserId,
  A as selectCurrentUser,
  Yt as selectCurrentUserEmail,
  ee as selectCurrentUserEmailSetting,
  Xt as selectCurrentUserId,
  te as selectCurrentUserPushSetting,
  kt as selectCurrentUserRoles,
  nt as selectCurrentUserSettings,
  Zt as selectCurrentUserSubscriptionPlan,
  $t as selectCurrentUserTeam,
  _t as selectIsAuthenticated,
  Et as selectIsCurrentUserGuest,
  v as setAuthState,
  pt as setCurrentUser,
  tt as setSubscriptionPlan,
  $ as setupAuth,
  I as unauthenticated,
  l as updateAuthState,
  qt as updateCurrentUser,
  ie as useAuth,
  re as useHasAccess,
  ae as useLogout,
  oe as useRequestOTP,
  ue as useVerifyAuthProvider,
  he as useVerifyOTP,
  Pt as verifyAuthProviderMutation,
  yt as verifyOTPMutation
};
