import { ReactNode } from 'react';

export const logoIconAnimated = (): ReactNode => (
  <>
    <defs>
      <mask id="Mask-1" style={{ maskType: 'alpha' }}>
        <g id="mask_1" transform="translate(9,-75) translate(2,-70)">
          <g id="Shape-1" opacity="1">
            <path
              id="Path-1-6"
              fill="none"
              d="M-86.5,48.5C-86.5,48.5,-47.5,48.5,4.5,62.5C56.5,76.5,70,83.5,70,83.5"
              stroke="#43dd6e"
              strokeOpacity="1"
              strokeWidth="50"
              strokeMiterlimit="4"
              strokeLinecap="round"
              strokeLinejoin="miter"
              strokeDasharray="210 210"
              strokeDashoffset="210"
              style={{ animation: '2s linear infinite both Path-1-6_do' }}
            />
          </g>
        </g>
      </mask>
      <mask id="Mask-2" style={{ maskType: 'alpha' }}>
        <g
          id="mask_2"
          visibility="hidden"
          transform="translate(1,-1) translate(2,-70)"
          style={{ animation: '2s linear infinite forwards mask_2_v' }}
        >
          <g id="Shape-1-2" opacity="1">
            <path
              id="Path-1-8"
              fill="none"
              d="M-77.5,47.5C-77.5,47.5,-47.5,48.5,4.5,62.5C56.5,76.5,68.5,82,68.5,82"
              stroke="#43dd6e"
              strokeOpacity="1"
              strokeWidth="50"
              strokeMiterlimit="4"
              strokeLinecap="round"
              strokeLinejoin="miter"
              strokeDasharray="210 210"
              strokeDashoffset="210"
              style={{ animation: '2s linear infinite both Path-1-8_do' }}
            />
          </g>
        </g>
      </mask>
      <mask id="Mask-3" style={{ maskType: 'alpha' }}>
        <g
          id="mask_3"
          visibility="hidden"
          style={{ animation: '2s linear infinite forwards mask_3_v' }}
        >
          <g id="Shape-1-3" opacity="1">
            <path
              id="Path-1-10"
              fill="none"
              d="M-93,49.5C-93,49.5,-47.5,48.5,4.5,62.5C56.5,76.5,90.5,90,90.5,90"
              stroke="#43dd6e"
              strokeOpacity="1"
              strokeWidth="50"
              strokeMiterlimit="4"
              strokeLinecap="round"
              strokeLinejoin="miter"
              strokeDasharray="210 210"
              strokeDashoffset="210"
              style={{ animation: '2s linear infinite both Path-1-10_do' }}
            />
          </g>
        </g>
      </mask>
    </defs>
    <style>
      {`
        @keyframes BG_t { 0% { transform: translate(192.5px,192.5px) scale(0.95,0.95); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 50% { transform: translate(192.5px,192.5px) scale(1,1); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 100% { transform: translate(192.5px,192.5px) scale(0.95,0.95); } }
        @keyframes BG-2_t { 0% { transform: translate(192.5px,192.5px) scale(0.95,0.95); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 50% { transform: translate(192.5px,192.5px) scale(1,1); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 100% { transform: translate(192.5px,192.5px) scale(0.95,0.95); } }
        @keyframes BG-3_t { 0% { transform: translate(192.5px,192.5px) scale(0.95,0.95); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 50% { transform: translate(192.5px,192.5px) scale(1,1); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 100% { transform: translate(192.5px,192.5px) scale(0.95,0.95); } }
        @keyframes BG-4_t { 0% { transform: translate(192.5px,192.5px) scale(0.95,0.95); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 50% { transform: translate(192.5px,192.5px) scale(1,1); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 100% { transform: translate(192.5px,192.5px) scale(0.95,0.95); } }
        @keyframes BG-5_t { 0% { transform: translate(192.5px,192.5px) scale(0.95,0.95); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 50% { transform: translate(192.5px,192.5px) scale(1,1); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 100% { transform: translate(192.5px,192.5px) scale(0.95,0.95); } }
        @keyframes Path-1-6_do { 0% { stroke-dashoffset: 210px; animation-timing-function: cubic-bezier(0.734,0,0.667,1); } 16.65% { stroke-dashoffset: 0px; animation-timing-function: cubic-bezier(0.534,0,0.667,1); } 75% { stroke-dashoffset: 0px; animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 100% { stroke-dashoffset: -210px; } }
        @keyframes BG-6_t { 0% { transform: translate(192.5px,192.5px) scale(0.95,0.95); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 50% { transform: translate(192.5px,192.5px) scale(1,1); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 100% { transform: translate(192.5px,192.5px) scale(0.95,0.95); } }
        @keyframes L2_v { 0% { visibility: hidden; animation-timing-function: steps(1); } 16.65% { visibility: visible; animation-timing-function: steps(1); } 100% { visibility: hidden; animation-timing-function: steps(1); } }
        @keyframes mask_2_v { 0% { visibility: hidden; animation-timing-function: steps(1); } 16.65% { visibility: visible; animation-timing-function: steps(1); } 100% { visibility: hidden; animation-timing-function: steps(1); } }
        @keyframes Path-1-8_do { 0% { stroke-dashoffset: 210px; } 16.65% { stroke-dashoffset: 210px; animation-timing-function: cubic-bezier(0.68,0,0.667,1); } 33.35% { stroke-dashoffset: 0px; animation-timing-function: cubic-bezier(0.68,0,0.667,1); } 62.5% { stroke-dashoffset: 0px; animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 100% { stroke-dashoffset: -210px; } }
        @keyframes BG-7_t { 0% { transform: translate(192.5px,192.5px) scale(0.95,0.95); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 50% { transform: translate(192.5px,192.5px) scale(1,1); animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 100% { transform: translate(192.5px,192.5px) scale(0.95,0.95); } }
        @keyframes L3_v { 0% { visibility: hidden; animation-timing-function: steps(1); } 33.35% { visibility: visible; animation-timing-function: steps(1); } 100% { visibility: hidden; animation-timing-function: steps(1); } }
        @keyframes mask_3_v { 0% { visibility: hidden; animation-timing-function: steps(1); } 33.35% { visibility: visible; animation-timing-function: steps(1); } 100% { visibility: hidden; animation-timing-function: steps(1); } }
        @keyframes Path-1-10_do { 0% { stroke-dashoffset: 210px; } 33.35% { stroke-dashoffset: 210px; animation-timing-function: cubic-bezier(0.594,0,0.667,1); } 50% { stroke-dashoffset: 0px; animation-timing-function: cubic-bezier(0.333,0,0.667,1); } 100% { stroke-dashoffset: -210px; } }`}
    </style>
    <g id="BG" style={{ animation: '2s linear infinite both BG_t' }}>
      <g id="Group-1" opacity="1">
        <path
          id="Path-1"
          fill="#ffffff"
          d="M-190,-86C-190,-143.438,-143.438,-190,-86,-190C-86,-190,86,-190,86,-190C143.438,-190,190,-143.438,190,-86C190,-86,190,86,190,86C190,143.438,143.438,190,86,190C86,190,-86,190,-86,190C-143.438,190,-190,143.438,-190,86C-190,86,-190,-86,-190,-86Z"
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
    </g>
    <g id="BG-2" style={{ animation: '2s linear infinite both BG-2_t' }}>
      <g id="L5" transform="translate(2.516,-80.182)">
        <g id="Group-1-2" opacity="1">
          <path
            id="Path-1-2"
            fill="#f4f4f4"
            d="M-76.209,-6.006C-76.844,-1.529,-73.542,2.551,-69.042,2.991C-4.887,9.268,39.794,21.468,59.207,27.583C65.114,29.444,71.572,26.15,73.158,20.163C74.169,16.348,75.064,12.771,76.018,8.821C77.169,4.058,74.552,-0.768,69.884,-2.259C51.018,-8.282,0.855,-22.63,-63.89,-28.071C-67.689,-28.39,-71.302,-26.199,-72.509,-22.582C-74.492,-16.644,-75.398,-11.722,-76.209,-6.006Z"
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g id="BG-3" style={{ animation: '2s linear infinite both BG-3_t' }}>
      <g id="L6" transform="translate(-1.817,-7.79)">
        <g id="Group-1-3" opacity="1">
          <path
            id="Path-1-3"
            fill="#f4f4f4"
            d="M-69.968,-6.448C-70.604,-1.966,-67.293,2.115,-62.79,2.571C-6.394,8.28,34.887,21.239,52.977,27.781C58.589,29.811,65.038,27.145,66.809,21.446C68.227,16.884,69.142,12.828,69.912,8.353C70.663,3.992,68.201,-0.224,64.036,-1.719C47.132,-7.789,1.128,-22.676,-57.59,-28.416C-61.42,-28.791,-65.078,-26.593,-66.294,-22.942C-68.259,-17.039,-69.162,-12.136,-69.968,-6.448Z"
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g id="BG-4" style={{ animation: '2s linear infinite both BG-4_t' }}>
      <g id="L7" transform="translate(0.238,67.959)">
        <g id="Group-1-4" opacity="1">
          <path
            id="Path-1-4"
            fill="#f4f4f4"
            d="M-87.624,-10.14C-88.267,-5.621,-84.9,-1.516,-80.353,-1.111C-10.658,5.103,48.972,23.803,71.316,31.528C76.636,33.367,82.647,30.926,84.462,25.598C85.911,21.346,86.801,17.491,87.58,12.762C88.289,8.454,85.879,4.299,81.793,2.759C61.876,-4.75,0.542,-25.83,-75.339,-32.105C-79.129,-32.418,-82.73,-30.226,-83.935,-26.619C-85.908,-20.712,-86.817,-15.813,-87.624,-10.14Z"
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g id="BG-5" style={{ animation: '2s linear infinite both BG-5_t' }}>
      <g mask="url(#Mask-1)">
        <g id="L1" transform="translate(2.516,-80.182)">
          <g id="Group-1-5" opacity="1">
            <path
              id="Path-1-5"
              fill="#fc7246"
              d="M-76.209,-6.006C-76.844,-1.529,-73.542,2.551,-69.042,2.991C-4.887,9.268,39.794,21.468,59.207,27.583C65.114,29.444,71.572,26.15,73.158,20.163C74.169,16.348,75.064,12.771,76.018,8.821C77.169,4.058,74.552,-0.768,69.884,-2.259C51.018,-8.282,0.855,-22.63,-63.89,-28.071C-67.689,-28.39,-71.302,-26.199,-72.509,-22.582C-74.492,-16.644,-75.398,-11.722,-76.209,-6.006Z"
              fillOpacity="1"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="BG-6" style={{ animation: '2s linear infinite both BG-6_t' }}>
      <g mask="url(#Mask-2)">
        <g
          id="L2"
          visibility="hidden"
          transform="translate(-1.817,-7.79)"
          style={{ animation: '2s linear infinite forwards L2_v' }}
        >
          <g id="Group-1-6" opacity="1">
            <path
              id="Path-1-7"
              fill="#f0555e"
              d="M-69.968,-6.448C-70.604,-1.966,-67.293,2.115,-62.79,2.571C-6.394,8.28,34.887,21.239,52.977,27.781C58.589,29.811,65.038,27.145,66.809,21.446C68.227,16.884,69.142,12.828,69.912,8.353C70.663,3.992,68.201,-0.224,64.036,-1.719C47.132,-7.789,1.128,-22.676,-57.59,-28.416C-61.42,-28.791,-65.078,-26.593,-66.294,-22.942C-68.259,-17.039,-69.162,-12.136,-69.968,-6.448Z"
              fillOpacity="1"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="BG-7" style={{ animation: '2s linear infinite both BG-7_t' }}>
      <g mask="url(#Mask-3)">
        <g
          id="L3"
          visibility="hidden"
          transform="translate(0.238,67.959)"
          style={{ animation: '2s linear infinite forwards L3_v' }}
        >
          <g id="Group-1-7" opacity="1">
            <path
              id="Path-1-9"
              fill="#de3355"
              d="M-87.624,-10.14C-88.267,-5.621,-84.9,-1.516,-80.353,-1.111C-10.658,5.103,48.972,23.803,71.316,31.528C76.636,33.367,82.647,30.926,84.462,25.598C85.911,21.346,86.801,17.491,87.58,12.762C88.289,8.454,85.879,4.299,81.793,2.759C61.876,-4.75,0.542,-25.83,-75.339,-32.105C-79.129,-32.418,-82.73,-30.226,-83.935,-26.619C-85.908,-20.712,-86.817,-15.813,-87.624,-10.14Z"
              fillOpacity="1"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </>
);

export const logo = (withText = false, textColor = '#1E2431'): ReactNode => (
  <>
    {withText ? (
      <path
        d="M817.444 41.503c12.408 2.175 24.061-5.162 26.029-16.387 1.968-11.226-6.495-22.09-18.902-24.265-12.407-2.175-24.061 5.162-26.029 16.388-1.968 11.226 6.495 22.09 18.902 24.264ZM804 77.762c0-7.653 6.607-13.819 14.148-12.518 4.013.693 7.863 1.59 12.173 2.876 5.255 1.568 8.677 6.534 8.677 12.017L839 203.1c0 5.493-4.886 9.71-10.307 8.819-5.482-.9-10.194-1.821-15.405-3.136-5.553-1.401-9.288-6.476-9.288-12.203V77.762ZM557 118.005C557 88 582 58 620 58c18.857 0 34.512 7.387 45.5 18.496C676.488 65.387 692.143 58 711 58c38 0 63 30 63 60.005v81.245c0 7.828-6.89 13.999-14.595 12.616a125.01 125.01 0 0 1-11.518-2.603c-5.363-1.483-8.885-6.504-8.885-12.068v-79.19C739.002 104 726 93 711 93c-14.908 0-27.842 10.865-28.001 24.747l.001.258v81.245l-.003.285c-.093 7.708-6.871 13.787-14.475 12.394a126.416 126.416 0 0 1-6.333-1.325 122.619 122.619 0 0 1-5.302-1.341c-5.277-1.459-8.772-6.344-8.882-11.801a14.008 14.008 0 0 1-.005-.355v-79.102l.001-.258C647.842 103.865 634.908 93 620 93c-15 0-28.002 11-28.002 25.005v81.37c0 7.784-6.819 13.957-14.476 12.554a127.583 127.583 0 0 1-11.542-2.67c-5.401-1.506-8.98-6.545-8.98-12.152v-79.102ZM529 158.995C529 189 504 219 466 219s-63-30-63-60.005V77.75c0-7.828 6.89-14 14.595-12.616a125.24 125.24 0 0 1 11.518 2.603c5.363 1.483 8.885 6.504 8.885 12.068v79.19C437.998 173 451 184 466 184c15 0 28.002-11 28.002-25.005v-81.37c0-7.784 6.819-13.957 14.476-12.554 3.818.7 7.488 1.54 11.542 2.67 5.401 1.506 8.98 6.545 8.98 12.152v79.102ZM354 65c-37 0-63 29-63 60.005v78.654c0 5.582 3.546 10.614 8.934 12.068 5.48 1.48 10.301 2.392 15.771 3.252 5.419.851 10.293-3.397 10.293-8.883v-85.091C325.998 110.5 339.5 100 354 100h12a9 9 0 0 0 9-9V75c0-5.523-4.477-10-10-10h-11Z"
        fill={textColor}
      />
    ) : null}
    <path
      d="M16.258 25.922c-.721 5.088 3.03 9.725 8.144 10.225 72.903 7.133 123.677 20.996 145.737 27.945 6.713 2.115 14.051-1.629 15.854-8.432 1.149-4.335 2.166-8.4 3.25-12.889 1.307-5.412-1.666-10.896-6.97-12.59C160.834 23.336 103.83 7.031 30.256.849c-4.317-.363-8.422 2.128-9.794 6.237-2.253 6.749-3.283 12.341-4.204 18.836Z"
      fill="#FC7246"
    />
    <path
      d="M18.426 107.685c-.723 5.092 3.04 9.73 8.158 10.248 64.086 6.487 110.996 21.214 131.552 28.648 6.378 2.306 13.707-.723 15.719-7.199 1.611-5.184 2.651-9.793 3.526-14.879a10.263 10.263 0 0 0-6.677-11.445c-19.21-6.898-71.487-23.814-138.212-30.338-4.352-.425-8.51 2.072-9.89 6.221-2.233 6.708-3.26 12.28-4.176 18.744Z"
      fill="#F0555E"
    />
    <path
      d="M.698 189.567c-.73 5.135 3.095 9.8 8.262 10.261 79.2 7.061 146.961 28.311 172.352 37.089 6.045 2.09 12.876-.684 14.939-6.738 1.646-4.832 2.658-9.214 3.542-14.587a10.317 10.317 0 0 0-6.575-11.367c-22.634-8.533-92.331-32.487-178.56-39.618-4.307-.356-8.398 2.135-9.768 6.234-2.242 6.712-3.275 12.28-4.192 18.726Z"
      fill="#DE3355"
    />
  </>
);
